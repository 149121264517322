import React, { Component } from "react";
import { Row, Col, Typography, Card, Tag, Spin, Modal, Button, } from 'antd';
//import ReactEcharts from 'echarts-for-react';
//import { DownOutlined, UnorderedListOutlined } from '@ant-design/icons';


//css 
import '../../../../../styles/encuestas.css';

const { Paragraph } = Typography;
const axios = require("axios").default;


export default class AnalyticsImagesVoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            images: [],
            lineas: [],
            urlImage: '',
            ModalVisible: false,
        }
    }

    /**
    * @memberof AnalyticsImagesVoc
    *
    * @method componentDidMount
    * @description carga las imagenes de la encuesta
    **/
    componentDidMount() {
        this.getImagesBySurveyID(this.props.id);
    }



    componentDidUpdate() {
        if (this.props.update !== this.state.update){
            this.state.update = this.props.update;
            this.getImagesBySurveyID()
        }
    }

    /**
    * @memberof AnalyticsImagesVoc
    *
    * @method getImagesBySurveyID
    * @description cartga todas las imagenes de una encuesta con sus respectivos votos
    * las imagenes y los votos se traen de forma separada, aqui se unen
    **/
    getImagesBySurveyID(
        id = this.props.id,
        country = this.props.country,
        states = this.props.states
        ) {
        this.setState({ loading: true })
        axios.get('/analytic-images', {
            headers: { Authorization: sessionStorage.getItem("token") },
            tipo: this.state.view,
            params: {
                id: id,
                tipo: 'linea',
                country, 
                states
            }
        }).then(async (response) => {
            let mayores_valores = response.data.mayores_valores


            console.log('mayores_valores', mayores_valores);

            this.setState({
                lineas: response.data.data.map((linea, index) => {

                    linea.respuestas = linea.respuestas.map(imagen => {
                        let mayor_valor = mayores_valores[index]
                        switch (imagen.resultados) {
                            case mayor_valor.primero:
                                imagen.posicion = "primera"
                                break;

                            case mayor_valor.segundo:
                                imagen.posicion = "segunda"
                                break;

                            case mayor_valor.tercero:
                                imagen.posicion = "tercera"
                                break;
                            
                                default: break;

                        }
                        return imagen;
                    })
                    return linea


                }),
                loading: false,

            })

        })
            .catch((error) => {
                console.log(error)
            })
    }



    render() {
        return (
            <div>
                <Spin tip="Espere un momento por favor..." spinning={this.state.loading}>
                    {this.state.lineas.map((linea, index) => {
                        return (
                            <div style={{ padding: '1.5em', border: '1px solid #76236c', marginTop: '3em', position: 'relative', borderRadius: 10, backgroundColor: '#e2e2e2' }}>
                                <h1 style={{ orderRadius: 100, fontSize: 20, position: 'relative', right: -'10px', top: '3px' }}>{index + 1}. {linea.nombre}</h1>
                                <Row gutter={[15, 15]} className="mt-2" style={{ width: '100%' }}>
                                    {linea.respuestas?.map(imagen => {
                                        //  console.log('imagen imagen imagen ', imagen);

                                        console.log(' - - -  - - -  - - -  - - -  - - -  - - -  - - - ', imagen);

                                        let isNotNull = true;
                                        let clase = (imagen.image_disponible !== undefined && imagen.image_disponible) ? "ImagenDisponible" : "";
                                        let title = (imagen.image_disponible !== undefined && imagen.image_disponible) ? "Disponible" : "No disponible";

                                        let urlImage = axios.defaults.baseURL + '/upload/' + imagen.image

                                        if (imagen._id == null) isNotNull = false
                                        return (
                                            <Col className="survey-content" xs={24} sm={24} md={12}>
                                                <Card className={`card-image-analytics ${imagen.posicion ? imagen.posicion : ''}`}>
                                                    <h1
                                                        title={title}
                                                        className={"imagen-view " + clase}

                                                        onClick={(isNotNull == true)?( () => this.setState({ ModalVisible: true, urlImage: urlImage })  ): undefined}
                                                        style={{ backgroundImage: `url(${(isNotNull == true)?urlImage:"/images/ninguno.png"})` }}></h1>
                                                    <Paragraph className="mt-1 text-center"> Votos Totales:</Paragraph>
                                                    <div className="center">
                                                        <Tag className="tag-count">{imagen.resultados}</Tag>
                                                    </div>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>

                        )
                    })}

                    <Modal
                        visible={this.state.ModalVisible}
                        onCancel={() => this.setState({ ModalVisible: false })}
                        title={null}
                        footer={null}
                        className="modal-form preview-image"
                        closeIcon={<Button type="ghost" className="closable-button" onClick={() => this.setState({ ModalVisible: false })}>
                            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
                                    fill="#E1E4ED" />
                                <path
                                    d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
                                    fill="#E1E4ED" />
                            </svg>
                        </Button>}
                    >
                        <img
                            style={{
                                // marginTop: '2.5em',
                                width: '100%',
                                borderRadius: '5px'
                            }}
                            src={this.state.urlImage}
                            alt="imagen del producto"
                        />
                    </Modal>
                </Spin>
            </div>
        )
    }
}
