import React, { Component } from 'react';
import { Button, Col, Row, Typography, Empty, Tooltip,message } from 'antd';
import { Link } from "react-router-dom";
import ReactEcharts from 'echarts-for-react';

import RespuestaPorEncuestas from './Graphics/RespuestaPorEncuestas'
import Respuestas_CDN_Departamentos from './Graphics/Respuestas_CDN_Departamentos'

const { Title } = Typography;
const axios = require("axios").default;
const moment = require("moment")
export default class VozAnalytics extends Component {

    constructor(props) {
        super(props)
        this.state = {
            encuestas: [],
            estatus: this.props.estatus,
            //fechas
            start: null,
            end: null,
            reports:[],
            createPDF:false
        }
    }

    /**
    * @memberof AnalyticsEncuestas
    *
    * @method componentDidMount
    * @description Cambia el titulo del Navbar al entrar a este componente
    **/
    componentDidMount() {
        this.getVoc();
    }

    /*
    * @memberof ModelUserForm
    *
    * @method componentWillReceiveProps
    * @description cueando se ordenen de forma diferente las preguntas se vean los cambios
    */
    componentWillReceiveProps(nextProps) {
        this.setState({ estatus: nextProps.estatus, start: nextProps.start, end: nextProps.end }, () => {
            this.getVoc();
        })
    }

    /** 
     * @memberof AnalyticsEncuestas
     * @function getVoc
     *
     * @description trae la infomacion de la encuesta por medio del id que se pasa por la url
     *
     **/
    getVoc = async () => {
        axios.get('/analytics/voc', {
            headers: { Authorization: sessionStorage.getItem("token") },
            params: {
                id: 'lol',
                activo: this.state.estatus,
                start: this.state.start,
                end: this.state.end,
            }
        }).then(async (response) => {
            const data = response.data.data;
            console.log('encustas voc', data)
            this.setState({ encuestas: data })
        })
            .catch((error) => {
                console.log(error.response)
            })
    }

    /**
	 * @memberof AnalyticsEncuestas
	 * @function getReports
	 *
	 * @description trae las ultimas 5 encuestas finalizadas
	 *
	 * */
	getReports = () => {
		axios.get('/analytics/reportes', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				tipo: 'voc',
			}

		}).then(async (response) => {
			const data = response.data.data;
			console.log('analytics reports', data)
			this.setState({ reports: data })
		})
			.catch((error) => {
				console.log(error.response)
			})
    }
    
    /**
	* @memberof GenericasAnalytics
	*
	* @method GenericasAnalytics
	* @description  Metodo handlePrintReport, descarga el pdf del reporte
	*
	* @params record (ID)
	**/
	handlePrintReport = (encuesta_id) => {

		console.log('IMPRIMIENDO ')
		this.setState({ createPDF: true })
		message.loading('Generando documento, por favor espere....', () => { return this.state.createPDF })
		axios.post('/reporte/stream',
			{ id: encuesta_id },
			{
				headers: { Authorization: sessionStorage.getItem("token") }
			})
			.then(res => {
				this.DownloadReport(res.data.filename);
			}).catch(error => {
				message.error(error.data.message);
			}).finally(f => {
				this.setState({ createPDF: false })
			})

	}

	/**
	* @memberof GenericasAnalytics
	*
	* @method handlePrintReport
	* @description  Metodo DownloadReport, descarga el pdf del reporte
	* 
	**/

	DownloadReport = (filename) => {
		console.log('filename',filename);
		this.setState({ downloading: true });
		message.loading('Descargando documento', () => { return this.state.downloading });

		window.open(`${axios.defaults.baseURL}/docs/${filename}`, "_blank") 		 
	}

    grafica1 = () => ({
        title: {
            text: ''
        },
        tooltip: {},
        legend: {
            data: ['Dato 1']
        },
        xAxis: {
            data: this.state.encuestas.map(encuesta => {
                return encuesta.nombre
            })
        },
        yAxis: {},
        series: [{
            name: 'Respuestas',
            type: 'bar',
            data: this.state.encuestas.map(encuesta => {
                return encuesta.num_resultados
            })
        }]

    })

    getOption2 = () => ({
        title: {
            text: 'titulo'
        },
        tooltip: {},
        legend: {
            data: ['Dato']
        },
        xAxis: {
            data: ['valor 1', 'valor 2', 'valor 3', 'valor 4', 'valor 5', 'valor 6']
        },
        yAxis: {},
        series: [{
            name: 'nombre de algo',
            type: 'bar',
            data: [5, 20, 36, 10, 10, 20]
        }]
    })

    setsMasVotados = () => {
        return {
            color: ['#3398DB'],
            tooltip: {
                trigger: 'axis',
                axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                    type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    axisTick: {
                        alignWithLabel: true
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: [
                {
                    name: '直接访问',
                    type: 'bar',
                    barWidth: '60%',
                    data: [10, 52, 200, 334, 390, 330, 220]
                }
            ]
        }
    }

    render() {

        const { estatus } = this.state;
        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col span={24} lg={15}>
                        <div className="card-analytics">
                            <Title level={4} type="secondary">Sets de Imagenes más votados por encuesta</Title>

                            <div className="mt-3">
                                <RespuestaPorEncuestas
                                    tipo="voc"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col span={24} lg={9}>
                        <div className="card-analytics">
                            <Title level={4} type="secondary">Respuetas por Centro de Negocio</Title>
                            <div className="mt-3">
                                <Respuestas_CDN_Departamentos
                                    tipo="voc"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24} lg={15}>
                        <div className="card-analytics">
                            <div className="card-header">
                                <Title level={4} type="secondary">Respuestas de las últimas encuestas</Title>
                                <Tooltip placement="topLeft"
                                    title={() => {
                                        return <div>
                                            Info:
                                            <ul>
                                                <li>Últimas 10 encuestas creadas</li>
                                                <li>Estatus: {estatus === true ? 'Activo' : 'No Activo'}</li>
                                            </ul>
                                        </div>
                                    }}>
                                    <Button className="btn-tooltip-info">i</Button>
                                </Tooltip>
                            </div>
                            <div className="charts mt-3">
                                {
                                    this.state.encuestas.length === 0 ? <Empty style={{ paddingTop: '2em', paddingBottom: '2em', background: "white", borderRadius: '10px' }} description="No se encontro niguna encuesta" />
                                        : <ReactEcharts
                                            option={this.grafica1()}
                                            style={{ height: '400px', width: '100%' }}
                                            opts={{ renderer: 'svg' }}
                                            className='react_for_echarts' />

                                }

                            </div>
                        </div>
                    </Col>
                    <Col span={24} lg={9}>
                        <div className="card-analytics">
                            <div className="card-header">
                                <Title level={4} type="secondary">Reportes Recientes</Title>
                                <Button className="btn-next">
                                    <Link to="/admin/analytics/reportes">
                                        <img src="/iconos/siguiente.svg" alt="Ver Reportes" />
                                    </Link>
                                </Button>
                            </div>
                            {this.state.reports.length > 0 ? this.state.reports.map((reporte, i) => {

                                return <div className="card-pdf">
                                    <Col span={4} className="center">
                                        <img src="/images/pdf.svg" alt="pdf-logo" />
                                    </Col>
                                    <Col span={12}>
                                        <h4 className="limit-text">{reporte.nombre}</h4>
                                        <Button className="btn-pdf" onClick={this.handlePrintReport} id={reporte._id}>
                                            Reporte.pdf
                                        </Button>
                                    </Col>
                                    <Col span={6}>
                                        <small>{moment(reporte.fecha_final).format("DD-MMM-YYYY")}</small>
                                    </Col>
                                </div>
                            }) : <Empty style={{ paddingTop: '2em', paddingBottom: '2em', background: "white", borderRadius: '10px' }} description="No hay reportes" />
                            }

                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
