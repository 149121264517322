import React, {Component} from 'react';
import {Button,  Col, Form, Input, message, Modal, Row, Select, Spin, Radio} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const axios = require("axios").default;

const {Option} = Select;


/**
 * @class ModelUserForm
 * @description  Clase que contiene el modal para crear/editar un nuevo usuario
 * ruta: /admin/usuarios
 *
 **/
export default class ModelUserForm extends Component {

	constructor(props) {
		super(props)
		this.state = {
		loading: false,
			info: false,
			message: null,
			departments: {
				data: [],
				page: 1,
				quantity: 0,
				limit: 8,
				search: "",
				loading: false
			},
			centers: {
				data: [],
				page: 1,
				quantity: 0,
				limit: 8,
				search: "",
				loading: false
			},
			userToEditData: [],
			roles: [],

		}
	}

	/*
	* @memberof ModelUserForm
	*
	* @method componentDidMount
	* @description  Se trae los centro de negocios y departamentos de la BD, que son usados para el select del formualrio
	* de usuarios
	*/
	async componentDidMount(): void {
		const { centers, departments } = this.state;
		this.getDataPagination("/centrosNegocio", "centers", centers.page, centers.quantity, centers.limit, centers.search)
		this.getDataPagination("/departamentos", "departments", departments.page, departments.quantity, departments.limit, departments.search)
		this.loadRoles();
	}

	/*
	* @memberof ModelUserForm
	*
	* @method componentWillReceiveProps
	* @description  Cuando reciba un nuevo usuario por medio de props se le asigna al state de este componente
	*/
	componentWillReceiveProps(nextProps) {
		this.setState({userToEditData: nextProps.userToEdit})
	}

	/*
	* @memberof ModelUserForm
	*
	*	@method saveUsuario
	*	@description  Metodo handler que se llama al hacer submit al formulario, permita gurdar un nuevo usaurio o editar
	*/
	saveUsuario = (values) => {
		this.setState({loading: true})
		if(this.state.userToEditData.length === 0){
			this.NewUser(values);
		}
		else{
			this.UpdateUser(values)
		}
	}

	/**
	* @memberof ModelUserForm
	*
	* @method NewUser
	* @description  Envia los datos del formulario al Servidor para un nuevo registro
	*
	* @param values (array)
	* Contiene los campos del formulario para registrar al usuario
	*
	* @returns response (array)
	**/
	NewUser = (values) => {
		axios.post("/usuarios/add", {
			usuario           : values.nombre.toLowerCase().replace(/\s/g, ''), //nombre en minisculas y sin espacios
			nombre            : values.nombre,
			password          : values.password,
			email             : values.email,
			centronegocios_id : values.centronegocios_id,
			departamento_id   : values.departamento_id,
			roles_id          : values.roles_id, 
			tipo              : this.props.tipo,
		},
		{ 
			headers: { 
				Authorization: sessionStorage.getItem('token') 
			} 
		})
		.then(res => {
			if(res.data.success) {
				this.setState({loading: false, info: true, message: "!Usuario creado exitosamente!"});
				message.success(this.state.message);
				this.hideModalUser();
				this.props.ReloadList();
			}
			else{
				this.setState({loading: false, info: false, message: "¡Ooops! No se ha podido crear el usuario"});	
				message.error("¡Ooops! No se ha podido crear el usuario");

			}
		})
		.catch(error => {
			this.setState({loading: false, info: false, message: "¡Ooops! No se ha podido crear el usuario"});
			if (error.response.status === 403)
				message.error(`${error.response.data.message}`)
			else
				message.error(this.state.message);
		})
	}

	/**
	* @memberof ModelUserForm
	*
	* @method UpdateData
	* @description  Envia los datos del formulario al Servidor para actualizar un registro
	*
	* @param values (array)
	* Contiene los campos del formulario para registrar al perfil 
	*
	* @returns response (array)
	**/
	UpdateUser = (values) => {
		axios.put("/usuarios/update", {
			id                   : this.state.userToEditData._id,
			nombre               : values.nombre,
			email                : values.email,
			centronegocios_id    : values.centronegocios_id, 
			departamento_id      : values.departamento_id, 
			roles_id             : values.roles_id,     
		},
		{ 
			headers: { 
				Authorization: sessionStorage.getItem('token') 
			} 
		})
		.then(res => {
			if(res.data.success) {
				this.setState({ loading: false, info: true, message: "¡Usuario actualizado con éxito!" });
				message.success(this.state.message);
				this.hideModalUser();
				this.props.ReloadList();
			}
			else{
				this.setState({loading:false, info: false, message: "¡Ooops! No se ha podido actualizar el usuario" });	
				message.error( "¡Ooops! No se ha podido actualizar el usuario");

			}
		})
		.catch(error => {
			this.setState({loading:false, info: false, message: "¡Ooops! No se ha podido actualizar el usuario" });
			if (error.response.status === 403)
				message.error(`${error.response.data.message}`)
			else
				message.error(`${this.state.message}`);
		})
	}

	/**
	* @memberof ModelUserForm
	*
	* @method hideModalDptos
	* @description llama al metodo del componente padre (Usuarios) para cerrar el modal
	**/
	hideModalUser = () => {
		this.props.hideModalUser();
	}

	/**
	* @memberOf ModelUserForm
	* @function getDataPagination
	*
	* URL, type, page, quantity, limit, search
	* @param URL
	* @description URL a la cual se obtendrá la paginación.
	*
	* @param type
	* @description Es el nombre que tiene el objeto que almacena la paginacion, aqui es users, targets o profiles..
	*
	* @param page
	* @description Numero de pagina de la paginacíon.
	*
	* @param quantity
	* @description Total de registros.
	*
	* @param limit
	* @description Limite de Elementos en la paginación.
	*
	* @param search
	* @description Si hay algo que buscar, que lo busque.
	*
	* */
	getDataPagination = async (URL, type, page, quantity, limit, search) => {
		/* Para que funcione, necesito declarado un objeto en el estado con la siguiente estructura */
		//Obtengo el objeto del estado
		let before = this.state[type];
		//Activo el LOADING
		before.loading = true;
		this.state[type] = before;

		//Renderizo con lo nuevos valores
		this.setState({update: !this.state.update})
		//hago la solicitud
		return axios.get(URL,{
			params: {page, quantity, limit, search},
			headers: { Authorization: sessionStorage.getItem("token")}
		})
		.then(response => {
			//Actualizo los valores con la nueva paginación. Debe ser un AGGREGATAE en el servidor.
			var reponseData = [];
			if(URL === "/departamentos"){
				reponseData = response.data.data.itemsList;
			}else {
				reponseData = response.data.data;	
			}
			const {page, limit, search} = this.state[type];
			//Actualizo el objeto con los nuevos valores en la paginacion
			this.state[type] = {
				data: reponseData,
				page: page,
				quantity: reponseData.itemCount,
				limit: limit,
				search: search,
				loading: false
			};
			//Renderizo
			this.setState({update: !this.state.update})
		})
		.catch(error => {
			console.log('error', error)
		})
	}

	/*
	* @memberof ModelUserForm
	*
	* @method loadRoles
	* @description Metodo que hace un llamdo a la BD para traer los roles diponibles que se asignan a los usuarios
	*/
	loadRoles = () => {
		axios.get('/roles', {
			headers: { Authorization: sessionStorage.getItem("token") },
		})
			.then((response) => {
				const data = response.data.data;
				let roles = [];
				data.map((role) =>{
					roles.push({label: role.nombre, value: role._id})
				})
				this.setState({roles: roles})
			})
			.catch((response) => {
				console.log('error', response.message);
			})
	}

	/*
	* @memberof ModelUserForm
	*
	* @method selectOptions
	* @description Metodo que despliega las opciones del select del modal, que esten en el state, si al momento de editar
	* un usuario y su CDN o departamento al que pertenece no esta, este se agrega a la lista de elementos
	*/
	selectOptions = (options) =>{
		const { userToEditData } = this.state;
		let arr = []
		let arrOptions =  options.data.map((item,index)=>{
			arr.push(item._id)
			return <Option value={item._id} key={index}>
				{ <span>{item.nombre} </span>}
			</Option>
		})
		if(userToEditData.selectOption !== undefined){
			if( !arr.includes(userToEditData.selectOption.id)){
				arrOptions.push(
					<Option value={userToEditData.selectOption.id}>
					{ <span>{userToEditData.selectOption.nombre} </span>}
				</Option>
				)
			}
		}
		return arrOptions;
	}


	render() {
		//opciones para los checkboxes
		const RolOptions = this.state.roles;
		//props
		const {modalUserVisible,tipo, modalTitle} = this.props;

		const {departments, centers, } = this.state;

		return (
			<Modal
				visible={modalUserVisible}
				footer={null}
				title={null}
				onCancel={this.hideModalUser}
				className="modal-form"
				destroyOnClose = {true}
				closeIcon={<Button type="ghost" className="closable-button" onClick={this.hideModalUser}>
										<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
															fill="#E1E4ED"/>
												<path d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
															fill="#E1E4ED"/>
										</svg>
								</Button>}
			>

				<h3 className="modal-title">{modalTitle}</h3>

				<Form
					name="fomulario-usuario"
					onFinish={this.saveUsuario}
					layout="vertical"
					className="form-modal"
					initialValues={this.state.userToEditData}
				>
					<Spin tip="Espere un momento por favor..." spinning={this.state.loading}>
						<Row>
							<Col xs={{span: 20, offset: 2}} sm={{span: 16, offset: 4}}>

								<Form.Item
									label="Nombre de Usuario"
									name="nombre"
									rules={
										[{
											required: true,
											message: "Ingrese el nombre de usuario",
										}]}
								>
									
									<Input/>

								</Form.Item>

								<Form.Item
									label={tipo ? 'Departamento' : 'Centro de Negocio'}
									name={tipo ? 'departamento_id' : 'centronegocios_id'}
									rules={
										[{
											required: true,
											message: "Ingrese una opcion",
										}]}
								>
								
									{/*si el usuario es tipo CDN tipo = false si es VIANNEY tipo = true*/}
									{tipo ?
										<Select
											showSearch
											className="modal-select select-search-input"
											style={this.props.style}
											defaultActiveFirstOption={false}
											showArrow={false}
											filterOption={false}

											onSearch={(search) => this.getDataPagination("/departamentos", "departments", departments.page, departments.quantity, departments.limit, search)}
											notFoundContent = {
													departments.loading ? 
													<Spin 
														size="large" 
														indicator={<LoadingOutlined style={{fontSize: 34}} spin/>}/> : null
													}
										>
											{ this.selectOptions(departments) }  

										</Select>
										:
										<Select
											showSearch
											className="modal-select select-search-input"
											style={this.props.style}
											defaultActiveFirstOption={false}
											showArrow={false}
											filterOption={false}
											onSearch={(search) => this.getDataPagination("/centrosNegocio", "centers", centers.page, centers.quantity, centers.limit, search)}
											notFoundContent = { 
												centers.loading ? 
													<Spin 
														size="large" 
														indicator={<LoadingOutlined style={{fontSize: 34}} spin/>}/> : null}
										>
											 { this.selectOptions(centers) }
										</Select>
									}
								</Form.Item>

								<Form.Item
									label="Correo Electronico"
									name="email"
									rules={
										[{
											required: true,
											message: "Ingrese el correo electronico",
											type: 'email',
										}]}
								>
									<Input/>
								</Form.Item>

								{this.state.userToEditData.length === 0 ? 
								<Form.Item
									label="Contraseña"
									name="password"
									rules={
										[{
											required: true,
											message: "Ingrese una contraseña",
											min: 8,
										}]}
								>
									<Input.Password placeholder="Min. 8 caracteres"/>
								</Form.Item>: ''}
							</Col>
						</Row>

					 <Form.Item
							label="Rol"
							name="roles_id"
							rules={
								[{
									required: true,
									message: "Selecione un rol",
								}]}
						> 
							<Radio.Group className="roles-users-group">     
								{RolOptions.map((role) => {
									return <Radio value={role.value}>{role.label}</Radio>
								})}
							</Radio.Group>   
						</Form.Item>

						<Form.Item>
							<Button htmlType="submit" className="v-button-primary">Guardar</Button>
						</Form.Item>
					</Spin>
				</Form>
			</Modal>
		)
	}
}
