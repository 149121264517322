import React, { Component } from 'react';
import { Row, Col, Button, DatePicker, Radio, Dropdown, Menu } from 'antd';

//css
import '../../../styles/analytics.css';

//componentes
import GenericasAnalytics from "./GenericasAnalytics";
import VozAnalytics from "./VozAnalytics";
import locale from 'antd/es/date-picker/locale/es_ES';

//import DropdownFilterEncuestas from './Controls/DropdownFilterEncuestas'

const { RangePicker } = DatePicker;

//const axios = require("axios").default;

/**
 * @method ArrowIcon
 * @description  Icono Dropdown Barra fillter
 *
 **/
function ArrowIcon() {
	return <svg style={{ marginLeft: '10px' }} width="8" height="5" viewBox="0 0 8 5" fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.98817 0.00230214C5.10632 0.00230214 6.22447 0.00528392 7.34261 3.03092e-05C7.60672 -0.0012476 7.84092 0.0366636 7.95916 0.347053C8.08234 0.670505 7.90441 0.87 7.72407 1.07092C6.64496 2.27328 5.56699 3.47693 4.48787 4.6793C4.10679 5.10399 3.89515 5.10598 3.52244 4.69066C2.43623 3.47991 1.35129 2.26775 0.264064 1.05814C0.0803047 0.853814 -0.0783622 0.648497 0.0419053 0.32902C0.16268 0.00783972 0.40727 3.03092e-05 0.664787 0.000740257C1.77254 0.00414801 2.88029 0.00230214 3.98817 0.00230214Z"
			fill="white" />
	</svg>
}
export default class Analytics extends Component {

	constructor(props){
		super(props)

		this.state = {
			view: 'Genericas',
			encuestasDropdown: false,
			estatus: true,
			//fechas
			start: null,
			end: null,
			reports:[]
		}
	}

	/**
	* @memberof Analytics
	*
	* @method componentDidMount
	* @description Cambia el titulo del Navbar al entrar a este componente,
	**/
	componentDidMount() {
		this.props.updateNavigationInformation('Analytics');
		
	}

	/**
	* @memberof Analytics
	*
	* @method changeView
	* @description Cambia la vista para visualizar los datos dependiendo de si es voc o general
	**/
	changeView = (view) => {
		this.setState({view})
	}


	/**
	* @memberof Analytics
	*
	* @method handlefilterEstatus
	* @description cambia el state para filtrar las encuestas por su estatus
	**/
	handlefilterEstatus = (value) => {
		this.setState({estatus: value})
	}

	/**
	* @memberof Analytics
	*
	* @method onDatesChange
	* @description cambia el state para filtrar las encuestas por fecha de inicio y final
	**/
    onDatesChange = async (values) => {
        try {
        	this.setState({
        		start: values[0].format('YYYY-MM-DD'),
        		end: values[1].format('YYYY-MM-DD'),
        	})
        } catch (e) {
           this.setState({
        		start: null,
        		end: null,
        	})
        }
	};
	
	

	render(){
		return(				
			<div className="page-main" style={{ padding: '30px' }}>
				<Row className="controls">
					<Col xs={24} xl={18} className="filters filters-encuestas">
	                    <RangePicker
	                        locale={locale} 
	                        className="filter-control ranger-picker-control"
	                        onChange={this.onDatesChange}
	                    />
	                    <Dropdown className="filter-control" trigger={['click']}
								overlay={
									<Menu>
										<Menu.Item>
											<Radio 
	                                            onClick={()=>{this.handlefilterEstatus(true)}}
	                                            checked={this.state.estatus === true}
	                                        >
	                                            Activas
	                                        </Radio>
										</Menu.Item>
										<Menu.Item>
											<Radio 
	                                            onClick={()=>{this.handlefilterEstatus(false)}}
	                                            checked={this.state.estatus === false}
	                                        >
	                                            No Activas
	                                        </Radio>
										</Menu.Item>
									</Menu>}
								placement="bottomLeft"
							>
								<Button type="link" className="text-white"> Estatus <ArrowIcon /> </Button>
							</Dropdown>
                  
	                </Col>
					<Col xs={24} xl={6}>
						<div className="btn-box">
							<Button
								className={`btn-view-analitics ${this.state.view === 'Genericas' ? 'btn-selected' : ''}`}
								onClick={()=>this.changeView('Genericas')}
								>Genericas</Button>
							<Button
								className={`btn-view-analitics ${this.state.view === 'Voz' ? 'btn-selected' : ''}`}
								onClick={()=>this.changeView('Voz')}
								>Voz del Cliente</Button>
						</div>
					</Col>
				</Row>

				<div className="page-content">
					{	
						this.state.view === 'Genericas' ? 
						<GenericasAnalytics
							estatus = {this.state.estatus}
							start = {this.state.start}
							end ={this.state.end}
						/> : 
						<VozAnalytics
							estatus = {this.state.estatus}
							start = {this.state.start}
							end ={this.state.end}
						/>
					}	
				</div>
			</div>
		)
	}
}

