import React, { Component } from "react";
import { Button, Col, Dropdown, Input, Layout, Menu, message, Modal, Row, Switch, Table, Radio, Tooltip } from 'antd';
import { ExclamationCircleOutlined, AppstoreOutlined, FormOutlined } from '@ant-design/icons';

import '../../../styles/admin.css'

import ModalCDN from './ModalCDN';

const axios = require('axios').default;

const { Content } = Layout;
const { confirm } = Modal;
const { Search } = Input;



/* ICONOS  */
function ArrowIcon() {
    return <svg style={{ marginLeft: '10px' }} width="10" height="8" viewBox="0 0 10 8" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.98817 0.00230214C5.10632 0.00230214 6.22447 0.00528392 7.34261 3.03092e-05C7.60672 -0.0012476 7.84092 0.0366636 7.95916 0.347053C8.08234 0.670505 7.90441 0.87 7.72407 1.07092C6.64496 2.27328 5.56699 3.47693 4.48787 4.6793C4.10679 5.10399 3.89515 5.10598 3.52244 4.69066C2.43623 3.47991 1.35129 2.26775 0.264064 1.05814C0.0803047 0.853814 -0.0783622 0.648497 0.0419053 0.32902C0.16268 0.00783972 0.40727 3.03092e-05 0.664787 0.000740257C1.77254 0.00414801 2.88029 0.00230214 3.98817 0.00230214Z"
            fill="white" />
    </svg>
}

function IconPlus() {
    return <svg style={{ marginTop: '0.3em' }} width="20" height="20" viewBox="0 0 18 18" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.4298 11.5702C6.31805 11.5702 6.24069 11.5702 6.16046 11.5702C4.35817 11.5702 2.55587 11.5702 0.753582 11.5702C0.246418 11.5702 0 11.3266 0 10.8223C0 9.60172 0 8.38395 0 7.16332C0 6.68195 0.249284 6.4298 0.727794 6.4298C2.54441 6.4298 4.35817 6.4298 6.17478 6.4298C6.25215 6.4298 6.33238 6.4298 6.43266 6.4298C6.43266 6.33238 6.43266 6.25215 6.43266 6.17478C6.43266 4.36676 6.43266 2.55874 6.43266 0.747851C6.43266 0.243553 6.67622 0 7.18338 0C8.39542 0 9.60745 0 10.8195 0C11.3266 0 11.5673 0.243553 11.5673 0.753582C11.5673 2.55587 11.5673 4.35817 11.5673 6.16046C11.5673 6.24069 11.5673 6.32092 11.5673 6.43266C11.6533 6.43266 11.7307 6.43266 11.808 6.43266C13.616 6.43266 15.4241 6.43266 17.235 6.43266C17.7593 6.43266 18 6.67049 18 7.18911C18 8.40115 18 9.61318 18 10.8252C18 11.3209 17.7536 11.5673 17.2607 11.5673C15.4527 11.5673 13.6447 11.5673 11.8338 11.5673C11.7536 11.5673 11.6762 11.5673 11.5673 11.5673C11.5673 11.6562 11.5673 11.7335 11.5673 11.8138C11.5673 13.6218 11.5673 15.4298 11.5673 17.2407C11.5673 17.7622 11.3266 18 10.8052 18C9.59312 18 8.38109 18 7.16905 18C6.67622 18 6.4298 17.7507 6.4298 17.255C6.4298 15.447 6.4298 13.639 6.4298 11.8281C6.4298 11.7507 6.4298 11.6734 6.4298 11.5702Z"
            fill="white" />
    </svg>
}

/*
const paginationStructure = {
    dropdown: false,
    loading: false,

    data: [],
    page: 1,
    pageCount: 0,
    limit: 8,

    quantity: 0,

    search: undefined,
    selectedElements: []
};
*/


/**
 * @class CentrosNegocios
 * @description Representa la lista de los centros de negocios.
 * */
class CentrosNegocios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arrayData: [],
            paises: [],
            paisSeleccionado: '',

            estados: [],
            modalVisible: false,
            loading: false,

            /* Paginado */
            data: [],
            page: 1,
            quantity: 0,
            limit: 10,

            pais: null,
            estado: null,
            paisesDropdown: [],
            estadosDropdown: [],


            dropdowns: {
                nombre: false,
                paises: false,
            },

            /*Datos para el filtrado*/
            search: null,
            field: null,
            order: null,
            activo: 1,
            update: false

        }
    }

    refModalCN = React.createRef();

    componentDidMount() {
        axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');
        this.props.updateNavigationInformation('Centros de Negocios')
        /*LLAMADA AL PAGINADO*/

        this.getPaises();
        this.receivedData();


    }

    /**
     * @methodOf CentrosNegocios
     *
     * @function receivedData
     * @description Obteiene la paginacion de bla blah.
     *
     * @param page
     * @description Pagina actua.
     *
     * */
    receivedData = (
        page = this.state.page,
        limit = this.state.limit,
        field = this.state.field,
        order = this.state.order,
        pais = this.state.pais,
        estado = this.state.estado,
        activo = this.state.activo,
        search = this.state.search

    ) => {
        this.setState({ loading: true })
        axios.post('/centrosNegocioPaginado', {
            headers: { Authorization: sessionStorage.getItem("token") },
            params: {
                page,
                limit,
                field,
                order,
                pais,
                estado,
                activo: (activo === 1) ? true : false,
                search
            }
        })
            .then((response) => {
                const data = response.data.data;
                console.log(data.itemsList.length)
                this.setState({
                    page,
                    limit,
                    data: data.itemsList,
                    quantity: data.itemCount,
                    loading: false
                })
            })
            .catch((response) => {
                console.log('error', response.message);
                this.setState({ loading: false })
                message.error("Ocurrio un error al cargar los centros de negocio");
            })
    }


    /**
   * @memberof CentrosNegocios
   *
   * @method   handleSearch
   * @description  Metodo que realiza la busqueda de centros de Negocios
   * @param values String   con la info a buscar, por defecto cadena vacia para busquedas
   *
   **/
    handleSearch = (value) => {
        this.setState({ search: value }, () => {
            this.receivedData(1);
        })
    }


    /**
    * @method showDeleteConfirm
    * @description  Para cada row mostrar un modal de confirmacion para eliminar el registro, la eliminacion se realiza mediante una peticion al servidor
    *
    * @param item (row)
    **/
    showDeleteConfirm = (item) => {
        const reg_name = item.currentTarget.name;
        const reg_id = item.currentTarget.id;
        const esto = this;

        confirm({
            title: 'Eliminar Centro de Negocios',
            icon: <ExclamationCircleOutlined />,
            content: '¿Estás seguro que deseas eliminar el centro de negocios ' + reg_name + ' ?',
            okText: 'Continuar',
            okType: 'danger',
            cancelText: 'Cancelar',

            onOk() {
                axios({
                    method: 'delete',
                    url: '/centroNegocio/delete',
                    headers: { Authorization: sessionStorage.getItem('token') },
                    data: { id: reg_id }
                })
                    .then((response) => {
                        if (response.data.success) {
                            message.success("!Centro de Negocio eliminado exitosamente!")
                            esto.receivedData(esto.state.currentPage)
                        }
                        else {
                            message.error('¡Ooops! NO se ha eliminado el Centro de Negocio')
                        }
                    })
                    .catch((error) => {
                        console.log("error al borrar el registro", error.response);
                        if (error.response.status === 403)//no tiene perimiso o tiene usuarios relacionados
                            message.error('No es posible eliminar Centro de Negocios porque, ya hay usuarios asociados a este Centro de Negocios')
                        else
                            message.error('¡Ooops! NO se ha eliminado el Centro de Negocio')
                    })
            },
        });
    }

    /**
   * @memberof CentrosNegocios
   *
   * @method   handlePageClick
   * @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
   *
   **/
    handlePageClick = page => {
        this.receivedData(page);
    };


    /**
   * @memberof CentrosNegocios
   *
   * @method   modalCrearCN
   * @description  Metodo que Muestra el modal
   *
   **/
    modalCrearCN = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true
        })
    }

    /**
   * @memberof CentrosNegocios
   *
   * @method   modalEditarCN
   * @description  Metodo que Muestra el modal
   *
   **/
    modalEditarCN = (item) => {
        const reg_id = item.currentTarget.id;
        this.setState({
            accionModal: 'editar',
            registroId: reg_id,
            modalVisible: true
        })
    }

    /**
       * @memberof CentrosNegocios
       *
       * @method   hideModal
       * @description  Metodo que oculta el modal
       *
       **/
    hideModal = () => {
        this.receivedData();
        this.setState({
            registroId: '',
            modalVisible: false
        })
    }

    /**
    * @memberof CentrosNegocios
    *
    * @method   handlefilterSort
    * @description ordena los datos de la tabla, mandando la columna y el orden
    *
    **/
    handlefilterSort(field, order) {
        if (field === this.state.field && order === this.state.order) {
            this.setState({ field: null, order: null }, () => {
                this.receivedData();
            })
        } else {
            this.setState({ field: field, order: order }, () => {
                this.receivedData();
            })
        }
    }

    /**
     * @methodOf  Transacciones
     *
     * @function onSearch
     * @description Actualiza por medio de la barra de busqueda.
     *
     * @param e
     * @description EVento de la busqueda. 
     *
     * */
    onSearch = (e) => {
        const search = e.target.value;
        if (search !== "" && search !== null && search !== undefined) {
            this.state.search = search;
        } else {
            this.state.search = undefined;
        }
        this.receivedData()
    }


    /**
 * @methodOf  Encuestas
 *
 * @function handleVisibleChange
 * @description Permite mostrar u ocultar los dropdowns en general
 *
 * @param type
 * @description Tipo de dropdown (proyeto, rubro, subruro, area, cuenta y tipo)
 *
 * @flag
 * @description Si se mostrará u ocultará.
 *
 * */
    handleVisibleChange = (type, flag) => {
        let { dropdowns } = this.state;
        dropdowns[type] = flag;
        this.setState({ dropdowns });
    };



    /**
     * @methodOf  
     *
     * @function updateSort
     * @description Actualiza un sort de ordenamiento.
     *
     * @param id
     * @description ID del area a agregar
     *
     * */
    updateSort = async (type, id) => {
        if (this.state[type] === id)
            this.state[type] = undefined;
        else
            this.state[type] = id;
        this.receivedData();

    }




    /*****  METODOS DEL DROPDOWN : FILTRO DE PAISES ******/



    getPaises = (page = this.state.paisesDropdown.page, limit = this.state.paisesDropdown.limit, search = this.state.paisesDropdown.search, restart = false) => {
        if (restart)
            page = 1;

        if (search === "" || search === null || search === undefined) search = undefined;

        axios.get('/countries', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            this.setState({
                paisesDropdown: data.data
            })
        }).catch((error) => console.log(error))
    }


    selectedPais = (id) => {

        //Si el ID es nulo, se borran todos los elementos
        if (this.state.pais === id) {
            //Si el ID es igual al que esta en el state, entonces lo vamos a borrar junto con los rubros
            this.setState({ pais: undefined, estado: undefined, estados: [], }, () => {
                this.update();
                this.receivedData();
            })
            /* this.state.pais = undefined;
             this.state.estado = undefined;
             this.state.estados = [];*/
            //Si no, se buscan
        } else return axios.post('/statesByCountry', {
            pais_id: id,
            paginate: false
        })
            .then(async (estados) => {
                this.setState({ pais: id, estado: undefined, estadosDropdown: estados.data.data }, () => {
                    this.update();
                    this.receivedData();
                })
                /*this.state.pais = id;
                // this.state.estado = undefined;
                this.state.estadosDropdown = estados.data.data;*/
            })
    }



    selectedEstado = (id) => {


        if (id === this.state.estado)
            this.setState({ estado: undefined }, () => {
                this.update();
                this.receivedData();
            })
        else
            this.setState({ estado: id }, () => {
                this.update();
                this.receivedData();
            })

    }


    selectedActivo = (value) => {
        if (value === this.state.activo)
            this.state.activo = undefined;
        else
            this.state.activo = value;

        this.update()
        this.receivedData()
    }


    update = () => this.setState({ update: !this.state.update })




    enviarNot = () => {

        Modal.confirm({

            title: "¿Deseas enviar una correo a los centros de negocio?",
            content: "Se enviará un correo a todos los centros de negocio con un enlace de descarga .",
            onOk: () => {
                axios.post("/centroNegocio/update-app",{},{
                    headers: { Authorization: sessionStorage.getItem('token') },
                })
            }
        })

    }


    render() {

        const { onSearch, handleVisibleChange,  receivedData, getPaises } = this;
        //const { paisesDropdown } = this.state;
        const columns = [
            {
                title: '',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
            },
            {
                title: 'Número',
                dataIndex: 'numero',
                key: 'numero',
            },
            {
                title: 'País',
                dataIndex: ['pais', 'name'],
                key: 'pais',
            },
            {
                title: 'Estado',
                dataIndex: ['estado', 'name'],
                key: 'estado',
            },
            {
                title: 'Usuarios',
                dataIndex: 'regs',
                key: 'Usuarios',
            },
            {
                title: 'Activo',
                key: 'activo',
                render: (text, record) => (
                    (record.activo) ? <Switch checked disabled={true} /> :
                        <Switch disabled={true} />
                ),
            },
            {
                title: 'Acciones',
                key: 'actions',
                render: (text, record) => (

                    <div className="tbl-opciones">

                        <Button type="primary" className="btn-edit" name={record.nombre}
                            id={record._id} onClick={this.modalEditarCN} title="Editar">
                            <img src="/iconos/edit.svg" alt="edit" />
                        </Button>

                        <Button type="primary" className="btn-delete" name={record.nombre}
                            id={record._id} onClick={this.showDeleteConfirm} title="Eliminar">
                            <img src="/iconos/trash.svg" alt="delete" />
                        </Button>

                        <a title="Editar" className="blue-icon nView"
                            href={`/admin/productos/editarProducto/${record._id}`}><FormOutlined />
                        </a>
                    </div>
                ),
            },
        ];

        return (
            <Content className="page-main" style={{ padding: '30px' }}>
                <Row className="controls">
                    <Col xs={24} md={20} className="filters">
                        <Dropdown
                            trigger={['click']}
                            className="filter-control"
                            visible={this.state.dropdowns.nombre} updateSort
                            onVisibleChange={(flag) => handleVisibleChange('nombre', flag)}
                            overlay={
                                <Menu>
                                    <Menu.Item className="radio-vertical" >
                                        <Radio
                                            onClick={() => { this.handlefilterSort('nombre', 1) }}
                                            checked={this.state.order === 1}
                                        >
                                            A - Z
                                        </Radio>
                                    </Menu.Item>
                                    <Menu.Item className="radio-vertical" >
                                        <Radio
                                            onClick={() => { this.handlefilterSort('nombre', -1) }}
                                            checked={this.state.order === -1}
                                        >
                                            Z - A
                                        </Radio>
                                    </Menu.Item>
                                </Menu>
                            }>
                            <Button type="link">Nombre <ArrowIcon /> </Button>
                        </Dropdown>
                        <Dropdown
                            trigger={['click']}
                            className="filter-control"
                            visible={this.state.dropdowns.paises}
                            onVisibleChange={(flag) => handleVisibleChange('paises', flag)}
                            overlay={
                                <Menu
                                    style={{
                                        minWidth: '260px',
                                        padding: '10px 5px'
                                    }}
                                >
                                    {this.state.paisesDropdown.map((item, index) => {
                                        return <Menu.Item key={index}>
                                            <Radio
                                                value={item._id}
                                                onClick={() => this.selectedPais(item._id)}
                                                checked={this.state.pais === item._id}
                                            >
                                                {item.name}
                                            </Radio>
                                        </Menu.Item>
                                    })}

                                </Menu>
                            }>
                            <Button type="link">Paises <ArrowIcon /> </Button>
                        </Dropdown>



                        <Dropdown
                            trigger={['click']}
                            className="filter-control"
                            visible={this.state.dropdowns.estados}
                            onVisibleChange={(flag) => handleVisibleChange('estados', flag)}
                            overlay={
                                <Menu
                                    style={{
                                        minWidth: '260px',
                                        padding: '10px 5px'
                                    }}
                                >
                                    {this.state.estadosDropdown.map((item, index) => {
                                        return <Menu.Item key={index}>
                                            <Radio
                                                value={item._id}
                                                onClick={() => this.selectedEstado(item._id)}
                                                checked={this.state.estado === item._id}
                                            >
                                                {item.name}
                                            </Radio>
                                        </Menu.Item>
                                    })}

                                </Menu>
                            }>
                            <Button type="link">Estados <ArrowIcon /> </Button>
                        </Dropdown>


                        <Dropdown
                            trigger={['click']}
                            className="filter-control"
                            visible={this.state.dropdowns.activo}
                            onVisibleChange={(flag) => handleVisibleChange('activo', flag)}
                            overlay={
                                <Menu
                                    style={{
                                        minWidth: '260px',
                                        padding: '10px 5px'
                                    }}
                                >
                                    <Menu.Item key={1}>
                                        <Radio
                                            value={1}
                                            onClick={() => { this.selectedActivo(1) }}
                                            checked={this.state.activo === 1}
                                        >
                                            Activo
                                        </Radio>
                                    </Menu.Item>
                                    <Menu.Item key={2}>
                                        <Radio
                                            value={0}
                                            onClick={() => { this.selectedActivo(0) }}
                                            checked={this.state.activo === 0}
                                        >
                                            Inactivo
                                        </Radio>
                                    </Menu.Item>
                                </Menu>
                            }>
                            <Button type="link">Status <ArrowIcon /> </Button>
                        </Dropdown>


                        <Search
                            className="search-control"
                            placeholder="Buscar"
                            size="normal"
                            bordered={false}
                            enterButton
                            onChange={onSearch}
                        // onSearch={value => this.handleSearch(value)}
                        />
                    </Col>
                    <Col span={4}>
                        <Button className="add-control" onClick={this.modalCrearCN}>
                            <IconPlus />
                        </Button>

                        <Tooltip title="Envia una notificación a todos los centros de negocio."> 
                            <Button className="add-control" onClick={this.enviarNot} style={{ position: 'relative', left: -10 }}>
                                <AppstoreOutlined style={{ fontSize: '35px', position: 'relative', left: -5, top: 3 }} />
                            </Button>
                        </Tooltip>

                    </Col>
                </Row>

                <div className="page-content">
                    <h5 className="items-number">Centros Negocios {this.state.quantity}</h5>
                    <Table
                        onChange={({ current, pageSize }) => {
                            receivedData(current, pageSize)
                        }}
                        columns={columns}
                        dataSource={this.state.data}
                        className="admin-table"
                        loading={this.state.loading}
                        scroll={{ x: 1200 }}
                        pagination={{
                            total: this.state.quantity,
                            current: this.state.page,
                            pageSize: this.state.limit,
                            showSizeChanger: true
                        }}
                    />
                </div>

                <ModalCDN
                    ref={this.refModalCN}
                    modalVisible={this.state.modalVisible}
                    accionModal={this.state.accionModal}
                    registroId={this.state.registroId}
                    closeMethod={this.hideModal}
                />
            </Content>
        )
    }

}
export default CentrosNegocios;
