import React, { Component } from "react";
import { Row, Col, Button, Typography, Table,  Modal, message } from 'antd';
import ReactEcharts from 'echarts-for-react';
//css 
import '../../../../../styles/encuestas.css';

const { Title } = Typography;
const axios = require("axios").default;


export default class AnalyticsPorPais extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			resultados: [],
		}
	}

	/**
	* @memberof AnalyticsPorPais
	*
	* @method componentDidMount
	* @description 
	**/
	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		if(this.props.id !== undefined){
			console.log('buscar dentro')
			this.getResultadosPorPais(this.props.id)
		}
	}

	/**
	* @memberof AnalyticsPorPais
	*
	* @method componentDidUpdate
	* @description 
	**/
	componentDidUpdate(prevProps) {
	}



	/**
	* @memberof AnalyticsPorPais
	*
	* @method componentDidMount
	* @description Obtiene los resultados de una encuesta, agrupados por pais
	**/
	getResultadosPorPais = (encuesta_id) => {
		this.setState({loading: true})
		axios.get('/encuesta/analytic/pais',{
			params:{
				encuesta_id
			}
		}).then(response=>{
			this.setState({resultados: response.data.data})
		}).catch(error=>{
			console.log(error)
			message.error('Error al traer la información por país')
		})
	}

	/**
	* @methodOf AnalyticsPorPais
	* @function chartInfo
	*
	* @description informacion para la grafica de Pastel
	*
	* */
	chartInfo = () => ({
		tooltip: {
			trigger: 'item',
			formatter: "{b} : {c} ({d}%)"
		},
		series: [
			{
				name: '',
				type: 'pie',
				radius: '60%',
				center: ['50%', '40%'],
				data: this.state.resultados.map((item, index) => {

					return {
						value: item.resultados,
						name: item.pais,
						itemStyle: {
							//color,
							borderWidth: 2,
							borderColor: '#76236c'
						}
					}
				}),
				itemStyle: {
					emphasis: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					}
				}
			}
		]
	});



	render() {

		const columns = [{
			title: 'País',
			dataIndex: 'pais',
			width: '60%',
		}, {
			title: 'Resultados',
			dataIndex: 'resultados',
		}]


		return (
			<div>
				<div className="card-analytics mt-2">
					<div className="card-header-analytic-encuesta pd-1">
						<div className="card-info-pregunta">
							<Title level={4} className="text-vianney">Resultados por País</Title>
						</div>
					</div>
					<Row>
						<Col xs={24} lg={8}>
							<div className="">
								{<ReactEcharts
									option={this.chartInfo()}
									style={{ height: 300 }}
								/>}
							</div>
						</Col>
						<Col xs={24} lg={{ span: 12, offset: 2 }}>
							<div>

								<Table
									className="table-analytics mb-2"
									columns={columns}
									rowClassName={((e) => {
										// switch (e.resultados) {
										// 	case this.state.mayores.primero:
										// 		return "primero"
										// 		//break;
										// 	case this.state.mayores.segundo:
										// 		return "segundo"
										// 		//break;
										// 	case this.state.mayores.tercero:
										// 		return "tercero"
										// 		//break;
												
										// 		default: break;
										// }
									})}
									
									dataSource={this.state.resultados}
									pagination={false}

									size="small"
									bordered={false}

								/>
							</div>
						</Col>
					</Row>
				</div>
				<Modal
					visible={this.state.ModalVisible}
					onCancel={() => this.setState({ ModalVisible: false })}
					title={null}
					footer={null}
					className="modal-form preview-image"
					closeIcon={<Button type="ghost" className="closable-button" onClick={() => this.setState({ ModalVisible: false })}>
						<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
								fill="#E1E4ED" />
							<path
								d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
								fill="#E1E4ED" />
						</svg>
					</Button>}
				>
					<img
						style={{
							// marginTop: '2.5em',
							width: '100%',
							borderRadius: '5px'
						}}
						src={this.state.urlImage}
						alt="imagen del producto"
					/>
				</Modal>
			</div>
		)
	}
}

