import React, { Component } from "react";
import { Row, Col, Typography, Card, Tag, Spin, Empty, Modal, Button } from 'antd';
// import ReactEcharts from 'echarts-for-react';
// import { DownOutlined, UnorderedListOutlined } from '@ant-design/icons';

//css 
import '../../../../../styles/encuestas.css';

const { Title, Text, Paragraph } = Typography;
const axios = require("axios").default;


export default class AnalyticsSetsVoc extends Component {


	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: [],
			images: [],

			no_cuenta: 0,

			ModalVisible: false,
			urlImage: '',
		}
	}

	/**
	* @memberof AnalyticsSetsVoc
	*
	* @method componentDidMount
	* @description trae informacion
	**/
	componentDidMount() {
		this.getImagesByProfilesSets(this.props.id);
	}

	componentDidUpdate() {
        if (this.props.update !== this.state.update){
            this.state.update = this.props.update;
            this.getImagesByProfilesSets()
        }
    }

	
	/*
	* @memberof AnalyticsSetsVoc
	*
	* @method getImagesByProfiles
	* @description trae las imagenes mas votadas por perfil, divididas por set
	*/
	getImagesByProfilesSets( id = this.props.id, country = this.props.country, states = this.props.states) {
		this.setState({ loading: true })
		axios.get('/analyticEncuesta/grupos/perfiles/sets', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: { id, country, states}
		}).then((response) => {
			this.setState({
				loading: false,
				data: response.data.data,
				dataPreguntas: response.data.encuesta.preguntas
			})
		}).then(() => {
			// this.setBackgroundColor();
		})
			.catch((error) => {
				console.log(error)
			})
	}

	render() {
		return (
			<div>
				<Spin tip="Espere un momento por favor..." spinning={this.state.loading}>
					{
						this.state.data.map(pregunta => {
							console.log(pregunta);
							
							return (
								<div className="card-analytics mt-2">
									<Row gutter={[25, 25]}>
										<Col span={24}>
											<Title className="title-question" level={3}>LINEA: {pregunta.nombre}</Title>
										</Col>
									</Row>
									{pregunta.sets.map((set, index) => {
										return (
											<div>
												<Row className="sub-title-question">
													<Col span={14}>
														<Text strong> SET:  {index + 1}</Text>
													</Col>
												</Row>
												{set.perfiles.map((perfil) => {

													let mayores = perfil.mayores;

													return (
														<Row gutter={[25, 25]} className="mt-1">
															<Col span={24} className="">
																<Text className="ml-1" strong>Perfil: {perfil.nombre}</Text>
																<Text className="ml-1" style={{ fontSize: 15 }}>Votos que encajan en el perfil: {perfil.cantidad}</Text>
															</Col>
															{perfil.imagenes.map((imagen, index) => {

															//	console.log(imagen);


																let isNotNull = (imagen._id !== null)

																let clase = (imagen.disponible !== undefined && imagen.disponible) ? "ImagenDisponible" : "";
																let title = (imagen.disponible !== undefined && imagen.disponible) ? "Disponible" : "No disponible";

																let urlImage = `${axios.defaults.baseURL}/upload/${imagen.imagen}`;
																let posicion = null;

																if (imagen.resultados !== 0)
																	switch (imagen.resultados) {
																		case mayores.primero:
																			posicion = "primera"
																			break;

																		case mayores.segundo:
																			posicion = "segunda"
																			break;

																		case mayores.tercero:
																			posicion = "tercera"
																			break;
																			default: break;
																	}

																return (
																	<Col className="survey-content" xs={24} sm={24} md={12}>
																		<Card className={`card-image-analytics ${posicion}`}>
																			<h1
																				title={title}
																				className={"imagen-view " + clase}

																				onClick={(isNotNull==true)?() => this.setState({ ModalVisible: true, urlImage: urlImage }):undefined}
																				style={{ backgroundImage: `url(${(isNotNull==true)?urlImage:'/images/ninguno.png'})` }}></h1>
																			<Paragraph className="mt-1 text-center"> Votos Totales:</Paragraph>
																			<div className="center">
																				<Tag className="tag-count">{imagen.resultados}</Tag>
																			</div>
																		</Card>
																	</Col>
																)
															})}
														</Row>
													)
												})}
											</div>
										)
									})}
								</div>
							)
						})
					}
					{this.state.data.length === 0 ? <Empty style={{
						paddingTop: '2em',
						paddingBottom: '2em',
						background: "white",
						borderRadius: '20px',
						marginLeft: 0,
						marginRigth: 0,
						marginTop: '1rem',
					}} description="No se encontro ningún resultado" />
						: ''}
				</Spin>
				<Modal
					visible={this.state.ModalVisible}
					onCancel={() => this.setState({ ModalVisible: false })}
					title={null}
					footer={null}
					className="modal-form preview-image"
					closeIcon={<Button type="ghost" className="closable-button" onClick={() => this.setState({ ModalVisible: false })}>
						<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
								fill="#E1E4ED" />
							<path
								d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
								fill="#E1E4ED" />
						</svg>
					</Button>}
				>
					<img
						style={{
							// marginTop: '2.5em',
							width: '100%',
							borderRadius: '5px'
						}}
						src={this.state.urlImage}
						alt="imagen del producto"
					/>
				</Modal>
			</div>
		)
	}
}

