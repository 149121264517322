import React, { Component } from "react";
import { Row, Popover, Col, Form, Input, Select, DatePicker, Switch, Button, Typography, Spin, message, Dropdown, Menu, Checkbox, Empty } from 'antd';
import { Link } from "react-router-dom";

import AnalyticsPregunta from './Analitycs/AnalyticsPregunta.js'
import AnalyticsPreguntaImagenVoc from './Analitycs/AnalyticsPreguntaImagenVoc.js'
// import AnalyticsImagesVoc from './Analitycs/AnalyticsImagesVoc.js'
//import AnalyticsPerfilesVoc from './Analitycs/AnalyticsPerfilesVoc.js'
import AnalyticsSetsVoc from './Analitycs/AnalyticsSetsVoc.js'

import AnalyticsReporteGeneraelFinalVOC from './Analitycs/AnalyticsReporteGeneraelFinalVOC'
import AnalyticsReporteGeneraelFinalLineaVOC from './Analitycs/AnalyticsReporteGeneraelFinalLineaVOC'


import AnalyticsLineaPerfil from './Analitycs/AnalyticsLineaPerfi'
import AnalyticsPorPaisVoc from './Analitycs/AnalyticsPorPaisVoc'

import {
    AppstoreOutlined,
    DownloadOutlined,
    PieChartOutlined,
    GlobalOutlined,
    DownOutlined,
    FileExcelOutlined
} from '@ant-design/icons';
//css
import '../../../../styles/encuestas.css';

const { Paragraph } = Typography;
const axios = require("axios").default;
const { RangePicker } = DatePicker;
const { Option } = Select;
const Moment = require('moment');
const moment = Moment;


export default class AnalyticsEncuestas extends Component {

    ref = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            loadingEncuesta: false,
            values: {
                tipo: 'general',
                reportes: [],
                preguntas: [],
                resultados: []
            },
            usuarios: [],
            resultados: [],
            viewImages: false,
            renderView: 'preguntas',
            rutaRPT: "",


            countries: [],
            states: [],

            selectedCountry: null,
            selectedStates: [],


            loadingPreguntas: false,


            update: 0
        }
    }

    /**
     * @memberof AnalyticsEncuestas
     *
     * @method componentDidMount
     * @description Cambia el titulo del Navbar al entrar a este componente y carga la informacion de la encuesta
     **/
    componentDidMount() {
        const { id } = this.props.match.params // id que esta en la url
        this.getEncuesta(id);
        this.props.updateNavigationInformation('Ver Encuesta');
        this.GetCountries();

    }


    /**
    * @memberof DepartamentoForm
    *
    * @method GetCountries
    * @description Metodo que manda a llamar a los paises y guardarlos en el state.countries
    *
    **/
    GetCountries = (country) => {

        axios.get('/countries', {
            params: { search: country }
        })
            .then((response) => {
                this.setState({ countries: response.data.data });
            })
            .catch((response) => {
                console.log('error', response);
            })
    }

    getStateByCountry = value => {
        this.state.states = []
        this.state.selectedCountry = value
        this.state.selectedStates = []
        if (value !== null)
            axios.post('/statesByCountry', { pais_id: value },
                { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    console.log('OBTENIENDO ESTADOS');
                    this.getPreguntas()
                    var pais_estados = res.data.data;
                    this.setState({ states: pais_estados, disabled: false });
                })
                .catch((error) => {
                    console.log(error.response)
                })
        else {

            console.log('Pais Nulo');
            this.getPreguntas()
        }

    }

    /**
     * @methodOf AnalyticsEncuestas
     * @function getEncuesta
     *
     * @description trae la infomacion de la encuesta por medio del id que se pasa por la url
     *
     * */
    getEncuesta = async (
        id = this.props.match.params.id,
        selectedCountry = this.state.selectedCountry,
        selectedStates = this.state.selectedStates
    ) => {
        this.setState({ loadingEncuesta: true, })
        axios.get('/analyticEncuesta/id', {
            headers: { Authorization: sessionStorage.getItem("token") },
            params: {
                id,
                selectedCountry,
                selectedStates
            }
        }).then(async (response) => {
            const data = response.data.data;
            console.log('data get encuesta', response.data)
            //valores
            await this.setState(state => {
                state.values = {
                    nombre: data.nombre,
                    tipo: data.tipo,
                    'range-dates': [moment(data.fecha_inicio), moment(data.fecha_final)],
                    estatus: data.activo,
                    categorias: data.categorias.map(item => {
                        return { key: item.nombre, value: item.nombre }
                    }),
                    reportes: data.reportes.map(item => {
                        return { key: item.nombre, value: item.nombre }
                    }),
                    target: data.targets.map(item => {
                        return { key: item.nombre, value: item.nombre }
                    }),
                    preguntas: response.data.resultados,
                    resultadosCount: response.data.resultadosCount,
                }
                return state;
            })

            if (this.ref.current !== undefined)
                this.ref.current.resetFields()

        }).then(() => {
            this.setState({ loadingEncuesta: false })
            //console.log(this.state)
        })
            .catch((error) => {
                console.log(error)
            })
    }


    /**
     * @methodOf AnalyticsEncuestas
     * @function getEncuesta
     *
     * @description trae la infomacion de la encuesta por medio del id que se pasa por la url
     *
     * */
    getPreguntas = async (id = this.props.match.params.id, selectedCountry = this.state.selectedCountry, selectedStates = this.state.selectedStates) => {
        this.setState(state => {
            state.values.preguntas = []
            state.loadingPreguntas = true

            if (state.update > 100)
                state.update = 0;
            else
                state.update++;

            return state
        })
        axios.get('/analyticEncuesta/id', {
            headers: { Authorization: sessionStorage.getItem("token") },
            params: {
                id,
                selectedCountry,
                selectedStates
            }
        }).then(async (response) => {
            this.setState(state => {
                state.values.preguntas = response.data.resultados
                return state;
            })
        })
            .finally(e => this.setState({ loadingPreguntas: false }))
    }

    /**
      * @methodOf AnalyticsEncuestas
      * @function copyToClipboard
      *
      * @description copia el link de la encuesta al portapapeles
      *
      * */
    copyToClipboard = () => {
        const link = this.inputLink
        link.select()
        document.execCommand("copy")
        message.success('¡Link Copiado!')
    }



    getReporteExcel = () => {
        this.setState({ loadingEncuesta: true })

        const { id } = this.props.match.params // id que esta en la url

        axios.get('/encuesta/reporteExcel', {
            headers: { Authorization: sessionStorage.getItem("token") },
            params: {
                encuesta_id: id,
                country: this.state.selectedCountry,
                states: this.state.selectedStates
            }
        })
            .then(response => {
                let csvContent = "data:text/csv;charset=utf-8," + '\ufeff' + response.data;
                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download",  this.state.values.nombre + ' ' + moment().format('YYYY-MM-DD HH:mm') + '.csv');
                document.body.appendChild(link);
                link.click();
                this.setState({ loadingEncuesta: false })
                // var a = document.createElement("a");
                // a.href = response.;
                // a.click();

                // if (response.data.success) {
                //     let filename = response.data.filename;
                //     let urlFile = `${axios.defaults.baseURL}/docs/${filename}`

                // }

            })
            .catch(error => {
                console.log(error)
                this.setState({ loadingEncuesta: false })
                message.error('Error al traer la información en excel')
            })
    }



    updateSelectedStates = (id, value) => {


        if (value == true) {
            let index = this.state.selectedStates.findIndex(stateElement => stateElement == id)
            if (index == -1)
                this.state.selectedStates.push(id)

        } else {
            let index = this.state.selectedStates.findIndex(stateElement => stateElement == id)
            if (index != -1)
                this.state.selectedStates.splice(index, 1)
        }

        console.log('Actualizando estados');
        this.getPreguntas()
    }

    /**
     * @methodOf AnalyticsEncuestas
     * @function renderView
     *
     * @description Cambia la vista segun sea necesario
     *
     * */
    renderView = (vista) => {
        const { id } = this.props.match.params

        switch (vista) {
            case 'preguntas':
                return this.state.values.preguntas.map((pregunta, index) => (pregunta.tipo === 'image' && this.state.values.tipo === 'voc') ? <AnalyticsPreguntaImagenVoc pregunta={pregunta} /> : <AnalyticsPregunta pregunta={pregunta} numero={index} />)
            //break;
            case 'reporte-total':
                return <AnalyticsReporteGeneraelFinalVOC
                    id={id}
                    country={this.state.selectedCountry}
                    states={this.state.selectedStates}
                    update={this.state.update}
                />
            //break;
            case 'reporte-general-final':
                return <AnalyticsReporteGeneraelFinalLineaVOC
                    id={id}

                    country={this.state.selectedCountry}
                    states={this.state.selectedStates}

                    update={this.state.update}
                />
            //break;
            case 'reporte-general':
                return <AnalyticsLineaPerfil
                    id={id}
                    country={this.state.selectedCountry}
                    states={this.state.selectedStates}
                    update={this.state.update}
                />
            //break;
            case 'reporte-perfil-set':
                return <AnalyticsSetsVoc
                    id={id}
                    country={this.state.selectedCountry}
                    states={this.state.selectedStates}
                    update={this.state.update}
                />
            //break;
            case 'por-pais':
                return <AnalyticsPorPaisVoc

                    id={id}
                    country={this.state.selectedCountry}
                    states={this.state.selectedStates}
                    update={this.state.update}

                />
            //break;
            default:
                break;

        }
    }


    /**
     * @function getUrl
     * @description Obtenemos el URL del reporte en cuestión
     *
     * @vista Tipo de vista actual
     *
     * */
    getURL = (vista) => {
        const { id } = this.props.match.params // id que esta en la url

        let text;
        switch (vista) {
            case 'preguntas':
                text = "reporte";
                break;

            case 'reporte-total':
                text = "reporte-total";
                break;

            case 'reporte-general-final':
                text = "reporte-general-final";
                break;

            case 'reporte-general':
                text = "reporte-general";
                break;

            case 'reporte-perfil-set':
                text = "reporte-set-perfil";
                break;

            case 'centronegocios':
                text = "reporte-cn";
                break;

            default:
                text = "reporte";
                break;
        }
        let url = new URL(axios.defaults.baseURL + "/encuesta/" + text + "/" + id);
        if (this.state.selectedCountry !== null)
            url.searchParams.append("country", this.state.selectedCountry);
        if (Array.isArray(this.state.selectedStates))
            for (let state of this.state.selectedStates) {
                url.searchParams.append("states[]", state); // this is what 
            }
        url.searchParams.append("Authorization", sessionStorage.getItem('token'));



        return url
    }




    render() {
        const { id } = this.props.match.params
        const { rutaRPT } = this.state;
        return (
            <div className="page-main" style={{ padding: '30px' }}>
                <Spin tip="Espere un momento por favor..." spinning={this.state.loadingEncuesta}>
                    <Form
                        ref={this.ref}
                        initialValues={this.state.values}
                        layout="vertical"
                        name="formulario-encuesta"
                    >
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>

                        <div className="page-content">
                            <div className="form-survey">

                                <Row className="form-survey-content">
                                    <Col xs={24} lg={8} className="form-survey-col">
                                        <Form.Item
                                            name="nombre"
                                            label="Nombre de Encuesta"
                                            className="form-item"
                                        >
                                            <Input className="input" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8} className="form-survey-col">
                                        <Form.Item
                                            label="Tipo de Encuesta"
                                        >
                                            <a href="#" className="survey-type">
                                                {this.state.values.tipo === 'voc' ? 'Voz del Cliente' : 'Genérica'}
                                            </a>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8} className="form-survey-col">
                                        <Form.Item
                                            name="target"
                                            label="Target"
                                        >
                                            <Select
                                                className="input-box"
                                                mode="multiple"
                                                labelInValue
                                                disabled
                                                filterOption={false}
                                            >
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="form-survey-content">
                                    <Col xs={24} lg={8} className="form-survey-col">
                                        <Form.Item
                                            name="range-dates"
                                            label="Fechas de Encuesta"
                                        >
                                            <RangePicker className="input-box"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8} className="form-survey-col">
                                        <Form.Item
                                            name="estatus"
                                            label="Estatus Actual"
                                        >
                                            <Switch checked={this.state.values.estatus} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8} className="form-survey-col">
                                        <Form.Item
                                            label="Respuestas Actuales"
                                        >
                                            <Paragraph className="survey-type">{this.state.values.resultadosCount}</Paragraph>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="form-survey-content">
                                    <Col xs={24} lg={8} className="form-survey-col">
                                        <Form.Item
                                            name="reportes"
                                            label="Envío de Reportes"
                                        >
                                            <Select
                                                mode="multiple"
                                                labelInValue
                                                disabled
                                                filterOption={false}
                                                className="input-box"

                                            >
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={8} className="form-survey-col">
                                        <Form.Item
                                            name="categorias"
                                            label="Perfiles"
                                        >
                                            <Select
                                                mode="multiple"
                                                labelInValue
                                                disabled
                                                filterOption={false}
                                                className="input-box"

                                            >

                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} lg={8} className="form-survey-col">

                                        {this.state.values.tipo === 'general' ? <Form.Item label="Link">
                                            <div className="link-survey-analytics">
                                                <Input
                                                    className="input-link"
                                                    ref={(link) => this.inputLink = link}
                                                    value={`${axios.defaults.baseURL_Prod}/encuesta/${id}`} />
                                                <Button
                                                    type="primary"
                                                    className="btn-copy"
                                                    onClick={() => this.copyToClipboard()}>
                                                    C
                                            </Button>
                                                <Link to={"/encuesta/" + id}>
                                                    <Button type="primary" className="btn-link">
                                                        IR
                                               </Button>
                                                </Link>
                                            </div>
                                        </Form.Item> : ''}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                    <Row className="mt-1">
                        {this.state.values.tipo === 'voc' ? <div>
                            <Popover content="Se visualizarán los resultados por linea con formato de graficas." title="Resumen en Graficas">
                                <Button className="btn-view-analytics" onClick={() => this.setState({ viewImages: false, renderView: 'preguntas' })}>
                                    <PieChartOutlined />
                                </Button>
                            </Popover>

                            <Popover content={'Se visualizarán todos los modelos.'} title="Reporte Total">
                                <Button className="btn-view-analytics" onClick={() => this.setState({ viewImages: true, renderView: 'reporte-total' })}>
                                    <AppstoreOutlined />
                                </Button>
                            </Popover>
                            <Popover content={'Se visualizan todas las imagenes por linea.'} title="Reporte General Final">
                                <Button className="btn-view-analytics" onClick={() => this.setState({ viewImages: true, renderView: 'reporte-general-final' })}>
                                    <img src="/iconos/groups.svg" width="20" height="20" style={{ position: "relative", top: -2 }} alt="0" />
                                </Button>
                            </Popover>
                            {/* <Popover content={'Se visualizan todas las imagenes por linea agrupadas por perfil.'} title="Reporte General">
                                <Button className="btn-view-analytics" onClick={() => this.setState({ viewImages: true, renderView: 'reporte-general' })}>
                                    <img src="/iconos/perfil_sets.svg" width="30" height="30" style={{ position: "relative", top: -3 }} />
                                </Button>
                            </Popover> */}
                            <Popover content={'Se visualizan todas las imagenes por linea agrupadas por set segun el perfil.'} title="Reporte por Set segun el Perfil">
                                <Button className="btn-view-analytics" onClick={() => this.setState({ viewImages: true, renderView: 'reporte-perfil-set' })}>
                                    <img src="/iconos/diagram.svg" style={{
                                        width: "22px",
                                        position: "relative",
                                        top: "-2px"
                                    }}
                                        alt="1" />
                                </Button>
                            </Popover>
                            <Popover content={'Se visualizan los resultados agrupados por país'} title="Respuestas por país ">
                                <Button className="btn-view-analytics" onClick={() => this.setState({ renderView: 'por-pais' })}>
                                    <GlobalOutlined />
                                </Button>
                            </Popover>
                        </div> : ''}


                        {this.state.values.tipo === 'voc' ? <Col span={10} className="options-rigth">
                            <Select
                                ref={ref => this.countriesSelect = ref}

                                value={this.state.selectedCountry}
                                className="filter-analitycs"
                                placeholder="Seleccione el País"
                                allowClear
                                onSelect={(search) => this.getStateByCountry(search)}
                                onClear={() => this.getStateByCountry(null)}
                            >

                                {this.state.countries.map((country, index) => <Option value={country._id}>{country.name}</Option>)}
                            </Select>
                            <Dropdown

                                onVisibleChange={flag => this.setState({ statesVisible: flag })}
                                visible={this.state.statesVisible}
                                overlay={<Menu style={{ maxHeight: 300, overflow: 'auto' }} onClick={event => this.setState({ statesVisible: true })}>
                                    {(Array.isArray(this.state.states) && this.state.states.length > 0) ? (this.state.states.map((country, index) => <Menu.Item onClick={event => this.setState({ statesVisible: true })}>
                                        <Checkbox onChange={flag => this.updateSelectedStates(country._id, flag.target.checked)} /> {country.name}
                                    </Menu.Item>)) : <Empty description="Debes seleccionar un País" />}
                                </Menu>}
                                placement="bottomLeft"

                                trigger={['click']}
                            >
                                <Button className="btn-view-analytics dropdown-states" style={{ color: (this.state.selectedStates.length > 0) ? undefined : "rgba(0, 0, 0, 0.30)" }}>
                                    {(this.state.selectedStates.length > 0) ? this.state.selectedStates.length + " estados seleccionados" : "Seleccione un estado"}<DownOutlined />
                                </Button>
                            </Dropdown>

                            <Popover title="Descargar la información actual en formato EXCEL" content="" >
                                <Button className="btn-view-analytics" onClick={(e) => this.getReporteExcel()}>
                                    <FileExcelOutlined />
                                </Button>
                            </Popover>


                            <Popover title="Descargar la información actual en formato PDF" content="" >
                                <Button className="btn-view-analytics">
                                    <a href={this.getURL(this.state.renderView)} target="_blank">
                                        <DownloadOutlined />
                                    </a>
                                </Button>
                            </Popover>

                            <Popover content="Ver modelos agrupados por perfil" title="REPORTE GENERAL">
                                <a href={this.getURL('reporte-general')} target="_blank">
                                    <Button className="btn-view-analytics" >
                                        <img src={"/iconos/perfiles.svg"} style={{ width: "30px", position: 'relative', bottom: '4px' }} alt="3" />
                                    </Button>
                                </a>
                            </Popover>
                            <Popover title="Ver Centros de Negocios mas participativos" content="Obtenemos los centros de negocios con mas participación.">
                                <a href={this.getURL('centronegocios')} target="_blank">
                                    <Button className="btn-view-analytics">
                                        <img src={"/iconos/building.svg"} style={{ width: "20px" }} alt="4" />
                                    </Button>
                                </a>
                            </Popover>

                        </Col>
                            : ''}
                    </Row>
                </Spin >
                <Row>
                    <Col span={24}>
                        <Spin spinning={this.state.loadingPreguntas}>
                            {this.renderView(this.state.renderView)}
                        </Spin>

                    </Col>
                </Row>
            </div >
        )
    }
}
