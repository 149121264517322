import React, { Component } from "react";
import { Row, Col, Button, Typography, Table,  Modal } from 'antd';
import ReactEcharts from 'echarts-for-react';
//import { DownOutlined } from '@ant-design/icons';
//css 
import '../../../../../styles/encuestas.css';

const { Title, Text } = Typography;
const axios = require("axios").default;


export default class AnalyticsPreguntaImagenVoc extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pregunta: {
				respuestas: []
			},
			resultados: [],
			ModalVisible: false,
			urlImage: '',
			mayores: {}
		}
	}

	/**
	* @memberof AnalyticsPreguntaImagenVoc
	*
	* @method componentDidMount
	* @description 
	**/
	componentDidMount() {
		let pregunta = this.props.pregunta;
		console.log(pregunta)
		let mayores = {};

		pregunta.respuestas.sort((a, b)=>{
		  var A = a.resultados; 
		  var B = b.resultados;
		  if (A > B) return -1;
		  if (A < B) return 1;

		  return 0;
		});

		let add_null = false;

		pregunta.respuestas = pregunta.respuestas.map(respuesta => {
			if (mayores.primero === undefined)
				mayores.primero = respuesta.resultados
			else if (mayores.segundo === undefined && mayores.primero !== respuesta.resultados)
				mayores.segundo = respuesta.resultados
			else if (mayores.tercero === undefined && mayores.segundo !== respuesta.resultados)
				mayores.tercero = respuesta.resultados

			if(respuesta.ninguno) add_null = true;

			return respuesta
		})

		if(add_null){
			let index = pregunta.respuestas.findIndex(res => res._id === 'no_importa')
			if(index === -1)
				pregunta.respuestas.push({
					_id: 'no_importa',
                    image: null,
                    resultados: 0})
		}

		console.log('mayores', mayores);

		this.state.mayores = mayores;
		this.setState({
			pregunta,
		})

	}


	/**
	* @methodOf AnalyticsPreguntaImagenVoc
	* @function chartInfo
	*
	* @description informacion para la grafica de Pastel
	*
	* */
	chartInfo = () => ({
		tooltip: {
			trigger: 'item',
			formatter: "{a} <br/>Opcion {b} : Resultados {c} ({d}%)"
		},
		series: [
			{
				name: this.state.pregunta.nombre,
				type: 'pie',
				radius: '60%',
				center: ['50%', '40%'],
				data: this.state.pregunta.respuestas.map((item, index) => {
					let color;
					switch (item.resultados) {
						case this.state.mayores.primero:
							color = "#8eceb0"
							break;
						case this.state.mayores.segundo:
							color = "#91b2ed"
							break;
						case this.state.mayores.tercero:
							color = "#ddb099"
							break;
						default: 
							color = item.style?.color;
							break;
					}


					return {
						value: item.resultados,
						name: (index + 1),
						itemStyle: {
							color,
							borderWidth: 2,
							borderColor: '#76236c'
						}
					}
				}),
				itemStyle: {
					emphasis: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					}
				}
			}
		]
	});

	/**
	* @methodOf AnalyticsPreguntaImagenVoc
	* @function renderImages
	*
	* @description muestra las imagenes dentro de la tabla
	*
	* */
	renderImages = (row) => {
		return <div className="images-analytics-encuesta">
			{row.numero}
			{row.arrImages.map(imagen => {
				console.log(imagen)
				let urlImage = `${axios.defaults.baseURL}/upload/${imagen}`
				return <div>
					<h1 
						className="imagen-view"
						style={{ backgroundImage: `url(${urlImage})` }}></h1>
				</div>
			})}
		</div>
	}

	render() {

		//const { resultados } = this.state

		const columns = [{
			title: 'Imagenes',
			dataIndex: 'imagen',
			width: '60%',
			render: (text, row, index) => {
				//console.log(row);

				
				let clase = (row.disponible !== undefined && row.disponible)  ? "ImagenDisponible" : "";
				let title = (row.disponible !== undefined && row.disponible)  ? "Disponible" : "No disponible";
				
				let urlImage = `${axios.defaults.baseURL}/upload/${row.image}`
				return {
					children: <div style={{ display: 'flex', alignItems: 'center', }}>
						<div>{row.numero}</div>
						<div className="images-analytics-encuesta" >
							<div style={{ display: "flex" }}>
								<p style={{ fontWeight: "bold", fontSize: 20, left: -5 }}>{index + 1}</p>
								{row.image ? <h1
									title={title}
									className={"imagen-view " + clase}
									onClick={() => this.setState({ ModalVisible: true, urlImage: urlImage })}
									style={{ backgroundImage: `url(${urlImage})` }}></h1>
									: <h1
									title={title}
									className={"imagen-view " + clase}
									style={{ backgroundImage: `url('/images/ninguno.png')` }}></h1>
									}
							</div>
						</div>
					</div>
				}
			}
		}, {
			title: 'Votos',
			dataIndex: 'resultados',
		},];


		return (
			<div>
				<div className="card-analytics mt-2">
					<div className="card-header-analytic-encuesta pd-1">
						<div className="card-info-pregunta">
							<Title level={4} className="text-vianney"> {this.state.pregunta.numero + 1}</Title>
							<Text className="ml-3">{this.state.pregunta.nombre}</Text>
							<Text className="ml-3">{this.state.pregunta.pregunta}</Text>
						</div>
					</div>
					<Row>
						<Col xs={24} lg={8}>
							<div className="">
								<ReactEcharts
									option={this.chartInfo()}
									style={{ height: 300 }}
								/>
							</div>
						</Col>
						<Col xs={24} lg={{ span: 12, offset: 2 }}>
							<div>

								<Table
									className="table-analytics mb-2"
									columns={columns}
									rowClassName={((e) => {
										switch (e.resultados) {
											case this.state.mayores.primero:
												return "primero"
												//break;
											case this.state.mayores.segundo:
												return "segundo"
												//break;
											case this.state.mayores.tercero:
												return "tercero"
												//break;
												
												default: break;
										}
									})}
									
									dataSource={this.state.pregunta.respuestas}
									pagination={false}

									size="small"
									bordered={false}

								/>
							</div>
						</Col>
					</Row>
				</div>
				<Modal
					visible={this.state.ModalVisible}
					onCancel={() => this.setState({ ModalVisible: false })}
					title={null}
					footer={null}
					className="modal-form preview-image"
					closeIcon={<Button type="ghost" className="closable-button" onClick={() => this.setState({ ModalVisible: false })}>
						<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
								fill="#E1E4ED" />
							<path
								d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
								fill="#E1E4ED" />
						</svg>
					</Button>}
				>
					<img
						style={{
							// marginTop: '2.5em',
							width: '100%',
							borderRadius: '5px'
						}}
						src={this.state.urlImage}
						alt="imagen del producto"
					/>
				</Modal>
			</div>
		)
	}
}

