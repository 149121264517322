import React, { Component } from 'react';
import { Button, Col, Row, Typography, Tooltip, Empty,message } from 'antd';
import { Link } from "react-router-dom";
import ReactEcharts from 'echarts-for-react';


import RespuestaPorEncuestas from './Graphics/RespuestaPorEncuestas'
import Respuestas_CDN_Departamentos from './Graphics/Respuestas_CDN_Departamentos'

const { Title } = Typography;
const axios = require("axios").default;
const moment = require("moment")
export default class GenericasAnalytics extends Component {


	constructor(props) {
		super(props)
		this.state = {
			encuestas: [],
			estatus: this.props.estatus,
			//fechas
			start: null,
			end: null,
			reports: [],
			createPDF:false
		}
	}

	/**
	* @memberof GenericasAnalytics
	*
	* @method componentDidMount
	* @description Cambia el titulo del Navbar al entrar a este componente
	**/
	componentDidMount() {

		this.getGenericas();
	}

	/** 
	* @memberof GenericasAnalytics
	*
	* @method componentWillReceiveProps
	* @description cueando se ordenen de forma diferente las preguntas se vean los cambios
	**/
	componentWillReceiveProps(nextProps) {
		this.setState({ estatus: nextProps.estatus, start: nextProps.start, end: nextProps.end }, () => {
			this.getGenericas();
			this.getReports();
		})
	}


	/**
	 * @memberof GenericasAnalytics
	 * @function getGenericas
	 *
	 * @description trae la infomacion de la encuesta por medio del id que se pasa por la url
	 *
	 * */
	getGenericas = async () => {
		axios.get('/analytics/genericas', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				id: 'lol',
				activo: this.state.estatus,
				start: this.state.start,
				end: this.state.end,
			}
		}).then(async (response) => {
			const data = response.data.data;
			console.log(data)
			this.setState({ encuestas: data })
		})
			.catch((error) => {
				console.log(error.response)
			})
	}

	/**
	 * @memberof GenericasAnalytics
	 * @function getReports
	 *
	 * @description trae las ultimas 5 encuestas finalizadas
	 *
	 * */
	getReports = () => {
		axios.get('/analytics/reportes', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				tipo: 'general',
			}

		}).then(async (response) => {
			const data = response.data.data;
			console.log('analytics reports', data)
			this.setState({ reports: data })
		})
			.catch((error) => {
				console.log(error.response)
			})
	}

	/**
	* @memberof GenericasAnalytics
	*
	* @method GenericasAnalytics
	* @description  Metodo handlePrintReport, descarga el pdf del reporte
	*
	* @params event (elemento seleccionado)
	**/
	handlePrintReport = (event) => {
		const encuesta_id = event.currentTarget.id;
		console.log('IMPRIMIENDO ')
		this.setState({ createPDF: true })
		message.loading('Generando documento, por favor espere....', () => { return this.state.createPDF })
		axios.post('/reporte/stream',
			{ id: encuesta_id },
			{
				headers: { Authorization: sessionStorage.getItem("token") }
			})
			.then(res => {
				this.DownloadReport(res.data.filename);
			}).catch(error => {
				message.error(error.data.message);
			}).finally(f => {
				this.setState({ createPDF: false })
			})

	}

	/**
	* @memberof GenericasAnalytics
	*
	* @method handlePrintReport
	* @description  Metodo DownloadReport, descarga el pdf del reporte
	* 
	**/

	DownloadReport = (filename) => {
		console.log('filename',filename);
		this.setState({ downloading: true });
		message.loading('Descargando documento', () => { return this.state.downloading });

		window.open(`${axios.defaults.baseURL}/docs/${filename}`, "_blank") 		 
	}
	/**
	 * @memberof GenericasAnalytics
	 * @function onChartClick
	 *
	 * @description 
	 *
	 * */
	onChartClick = (param, echarts) => {
		console.log(param, echarts);
		alert('chart click');
		this.setState({
			cnt: this.state.cnt + 1,
		})
	};

	/**
	 * @memberof GenericasAnalytics
	 * @function onChartLegendselectchanged
	 *
	 * @description 
	 *
	 * */
	onChartLegendselectchanged = (param, echart) => {
		console.log(param, echart);
		alert('chart legendselectchanged');
	};

	/**
	 * @memberof GenericasAnalytics
	 * @function onChartReady
	 *
	 * @description 
	 *
	 * */
	onChartReady = (echarts) => {
		console.log('echart is ready', echarts);
	};

	/**
	 * @memberof GenericasAnalytics
	 * @function grafica1
	 *
	 * @description trae la infomacion de la encuesta por medio del id que se pasa por la url
	 *
	 * */
	grafica1 = () => ({
		title: {
			text: ''
		},
		tooltip: {},
		legend: {
			data: ['Dato 1']
		},
		xAxis: {
			data: this.state.encuestas.map(encuesta => {
				return encuesta.nombre
			})
		},
		yAxis: {},
		series: [{
			name: 'Respuestas',
			type: 'bar',
			data: this.state.encuestas.map(encuesta => {
				return encuesta.num_resultados
			})
		}]
	})

	render() {

		const { estatus } = this.state;

		return (
			<div>
				<Row gutter={[16, 16]}>
					<Col span={24} lg={15}>
						<div className="card-analytics">
							<Title level={4} type="secondary">Encuestas con mayor número de respuestas</Title>
							<RespuestaPorEncuestas />
						</div>
					</Col>
					<Col span={24} lg={9}>
						<div className="card-analytics">
							<Title level={4} type="secondary">Respuestas por Departamentos</Title>
							<div className="mt-3">
								<Respuestas_CDN_Departamentos />
							</div>
						</div>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={24} lg={15}>
						<div className="card-analytics">
							<div className="card-header">
								<Title level={4} type="secondary">Respuestas de las últimas encuestas</Title>
								<Tooltip placement="topLeft"
									title={() => {
										return <div>
											Info:
											<ul>
												<li>Últimas 10 encuestas creadas</li>
												<li>Estatus: {estatus === true ? 'Activo' : 'No Activo'}</li>
											</ul>
										</div>
									}}>
									<Button className="btn-tooltip-info">i</Button>
								</Tooltip>
							</div>
							<div className=" charts mt-3">
								{
									this.state.encuestas.length === 0 ? <Empty style={{ paddingTop: '2em', paddingBottom: '2em', background: "white", borderRadius: '10px' }} description="No se encontro niguna encuesta" />
										: <ReactEcharts
											option={this.grafica1()}
											style={{ height: '400px', width: '110%' }}
											opts={{ renderer: 'svg' }}
											className='react_for_echarts' />

								}
							</div>
						</div>
					</Col>
					<Col span={24} lg={9}>
						<div className="card-analytics">
							<div className="card-header">
								<Title level={4} type="secondary">Reportes Recientes</Title>
								<Button className="btn-next">
									<Link to="/admin/analytics/reportes">
										<img src="/iconos/siguiente.svg" alt="Ver Reportes" />
									</Link>
								</Button>
							</div>
							{this.state.reports.length > 0 ? this.state.reports.map((reporte, i) => {
							return	<div className="card-pdf">
									<Col span={4} className="center">
										<img src="/images/pdf.svg" alt="pdf-logo" />
									</Col>
									<Col span={12}>
										<h4 className="limit-text">{reporte.nombre}</h4>
										<Button className="btn-pdf" onClick={this.handlePrintReport} id={reporte._id}>
											Reporte.pdf
									</Button>
									</Col>
									<Col span={6}>
										<small>{moment(reporte.fecha_final).format("DD-MMM-YYYY")}</small>
									</Col>
								</div>
							}) : <Empty style={{ paddingTop: '2em', paddingBottom: '2em', background: "white", borderRadius: '10px' }} description="No hay reportes" />
							}


						</div>
					</Col>
				</Row>
			</div>
		)
	}
}

