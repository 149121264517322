import React, { Component } from 'react';
import { Modal,   Button,   Row, Col, Descriptions  } from 'antd';

//import { LoadingOutlined} from '@ant-design/icons';


// const axios = require("axios").default;
// const {Title} = Typography;
// const { Option } = Select;
// const {TextArea} = Input;


/**
 * @class ModalViewTarget
 * @description  Clase que contiene el modal para VISUALIZAR un target
 *
 **/
export default class ModalViewTarget extends Component{

	constructor(props){
		super(props)
	}



	/**
	* @memberof ModelTargetForm
	*
	* @method hideModalViewTarget
	* @description llama a al funcion del comoponente padre (Target.js) para cerrar este modal
	**/
	hideModalViewTarget = () => {
		this.props.hideModalViewTarget();
	}


	render(){
		//props
		const { modalViewTargetVisible, targetToView } = this.props

		return(
			<Modal
				visible={modalViewTargetVisible}
				footer={null}
				title={null}
				onCancel={this.hideModalViewTarget}
				destroyOnClose = {true}
				closeIcon={<Button type="ghost" className="closable-button" onClick={this.hideModalViewTarget}>
					<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
							  fill="#E1E4ED"/>
						<path d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
							  fill="#E1E4ED"/>
					</svg>
				</Button>}
				className="modal-form"
			>
				<h3 className="modal-title"> Target: {targetToView.nombre} </h3>

				<Row>
					<Col span={24}>

					<Descriptions column={1} className="description-modal-content">
						<Descriptions.Item label="Descripcion">{targetToView.descripcion}</Descriptions.Item>
						
						<Descriptions.Item span={2} label="Centro de Negocio">
							<ul>
     					{ (targetToView.centros_negocios !== undefined && targetToView.centros_negocios.length > 0) ? 
     						(targetToView.centros_negocios.map( CDN => <li>{CDN.nombre}</li>)) 
     						: (<p>No Tiene</p>)}
							</ul>
						</Descriptions.Item>
						<Descriptions.Item span={2} label="Departamentos">
							<ul>
     					{ (targetToView.departamentos !== undefined && targetToView.departamentos.length > 0) ? 
     						(targetToView.departamentos.map( CDN => <li>{CDN.nombre}</li>)) 
     						: (<p>No Tiene</p>)}
							</ul>
						</Descriptions.Item>
						<Descriptions.Item span={2} label="Usuarios">
							<ul>
     					{ (targetToView.usuarios !== undefined && targetToView.usuarios.length > 0) ? 
     						(targetToView.usuarios.map( CDN => <li>{CDN.nombre}</li>)) 
     						: (<p>No Tiene</p>)}
							</ul>
						</Descriptions.Item>
					</Descriptions>
					<Button htmlType="submit" className="v-button-primary mt-3" onClick={this.hideModalViewTarget}>Cerrar</Button>
					</Col>
				</Row>

			</Modal>
		)
	}
}
