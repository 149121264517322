import React, { Component } from 'react';
import { Avatar, Button, Card, Col, List, Row, Typography, message, Empty } from 'antd'
import { Link } from "react-router-dom";

//css
import '../../styles/dashboard.css';

const { Title, Paragraph, Text } = Typography;
const axios = require("axios").default;
const moment = require("moment")

export default class Dashboard extends Component {

	constructor(props) {
		super(props)
		this.state = {
			contadores: [],
			activas: [],
			programadas: [],
			progreso: [],
			recientes: [],
			createPDF:false
		}
	}

	/**
	* @memberof Dashboard
	*
	* @method componentDidMount
	* @description Cambia el nombre de la seccion
	*
	**/
	componentDidMount() {
		this.props.updateNavigationInformation('Tablero');
		this.getContadores();
		this.getActivas();
		this.getProgramadas();
		this.getProgreso();
	}

	/**
	* @memberof Dashboard
	*
	* @method getContadores
	* @description consigue el numero de usuarios, CDN y encuetas totales
	*
	**/
	getContadores = () => {
		axios.get('/dashboard/contadores', {
			headers: { Authorization: sessionStorage.getItem("token") },
		})
			.then((response) => {
				const data = response.data.data
				this.setState({ contadores: data })
			})
			.catch((response) => {
				this.setState({ loading: false })
				message.error("Ocurrio un error al cargar los datos");
				console.log('error', response.message);
			})
	}

	/**
	* @memberof Dashboard
	*
	* @method getContadores
	* @description las utilmas encuestas creadas y que sean activas
	*
	**/
	getActivas = () => {
		axios.get('/dashboard/activas', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				limit: 4,
				order: -1,
			}
		})
			.then((response) => {
				const data = response.data.data
				this.setState({ activas: data })
			})
			.catch((response) => {
				this.setState({ loading: false })
				message.error("Ocurrio un error al cargar los datos");
				console.log('error', response.message);
			})
	}


	/**
	* @memberof Dashboard
	*
	* @method getContadores
	* @description las utilmas encustas creadas, que no sean activas, y su fecha final no haya pasado
	*
	**/
	getProgramadas = () => {
		axios.get('/dashboard/programadas', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				limit: 6,
				order: -1,
			}
		})
			.then((response) => {
				const data = response.data.data
				this.setState({ programadas: data })
			})
			.catch((response) => {
				this.setState({ loading: false })
				message.error("Ocurrio un error al cargar los datos");
				console.log('error', response.message);
			})
	}


	/**
	* @memberof Dashboard
	*
	* @method getContadores
	* @description trate por un aprte las utlimas encuestas creadas y que sean activas (mejorar) y por otro parte trae las
	* las encuestas que ya no este activas, y su fecha final ya haya pasado  2 x 1
	*
	**/
	getProgreso = () => {
		axios.get('/dashboard/progreso', {
			headers: { Authorization: sessionStorage.getItem("token") },
		})
			.then((response) => {

				const data = response.data
				this.setState({ progreso: data.progreso, recientes: data.recientes })
			})
			.catch((response) => {
				this.setState({ loading: false })
				message.error("Ocurrio un error al cargar los datos");
				console.log('error', response.message);
			})
	}

	/**
	* @memberof Dashboard
	*
	* @method getdates
	* @description dadas las fechas de inicio y final regresa el porsentaje de progreso entre esas dos
	*
	**/
	getdates = (fecha_inicio, fecha_final) => {

		let dayi = moment(fecha_inicio).get('date')
		let monthi = moment(fecha_inicio).get('month')
		let yeari = moment(fecha_inicio).get('year')

		let dayf = moment(fecha_final).get('date')
		let monthf = moment(fecha_final).get('month')
		let yearf = moment(fecha_final).get('year')

		var inicio = new Date(yeari, monthi, dayi)
		var final = new Date(yearf, monthf, dayf)
		var hoy = new Date()

		return Math.round(((hoy - inicio) / (final - inicio)) * 100) + '%'
	}


	/**
	* @memberof GenericasAnalytics
	*
	* @method GenericasAnalytics
	* @description  Metodo handlePrintReport, descarga el pdf del reporte
	*
	* @params event (elemento seleccionado)
	**/
	handlePrintReport = (event) => {
		const encuesta_id = event.currentTarget.id;
		console.log('encuesta id',encuesta_id);
		console.log('IMPRIMIENDO ')
		this.setState({ createPDF: true })
		message.loading('Generando documento, por favor espere....', () => { return this.state.createPDF })
		axios.post('/reporte/stream',
			{ id: encuesta_id },
			{
				headers: { Authorization: sessionStorage.getItem("token") }
			})
			.then(res => {
				this.DownloadReport(res.data.filename);
			}).catch(error => {
				// message.error(error.data.message);
			}).finally(f => {
				this.setState({ createPDF: false })
			})

	}

	/**
	* @memberof GenericasAnalytics
	*
	* @method handlePrintReport
	* @description  Metodo DownloadReport, descarga el pdf del reporte
	* 
	**/

	DownloadReport = (filename) => {
		console.log('filename',filename);
		this.setState({ downloading: true });
		message.loading('Descargando documento', () => { return this.state.downloading });

		window.open(`${axios.defaults.baseURL}/docs/${filename}`, "_blank") 		 
	}
	render() {
		this.getdates();
		//const cookies = new Cookies();
		const { contadores, activas, programadas } = this.state;
		const username = sessionStorage.getItem('userNombre')
		const colores = [
			{
				backgroundColor: "#FFE9E2",
				borderRadius: '6px',
				color: "#F14D19"
			},
			{
				backgroundColor: "#fff6d4",
				borderRadius: '6px',
				color: "#f4c100"
			},
			{
				backgroundColor: "#e5e8ff",
				borderRadius: '6px',
				color: "#1630f4"
			},
			{
				backgroundColor: "#FFE9E2",
				borderRadius: '6px',
				color: "#F14D19"
			},
			{
				backgroundColor: "#fff6d4",
				borderRadius: '6px',
				color: "#f4c100"
			},
			{
				backgroundColor: "#e5e8ff",
				borderRadius: '6px',
				color: "#1630f4"
			},
		];
		return (
			<div className="page-main">
				<Row className="main " gutter={[22, 22]}>
					<Col className="welcome-content"  xs={24} sm={24} lg={18}>
						<Card className="welcome">
							<Row>
								<Col className="ribbon-content" span={14}>
									<Title className="welcome-title" level={3}>¡HOLA {username}!</Title>
									<Paragraph className="welcome-description"> Bienvenido a Vianney - Voz del Cliente.</Paragraph>
									<Button
										type="ghost"
										className="welcome-button mt-1">
										<Link to="/admin/encuestas" className="font-12">Ver Encuestas</Link>
									</Button>
								</Col>
								<img src="/images/p2.png" alt="presona" className="image-presonaje" />
							</Row>
						</Card>
					</Col>
					<Col className="" xs={24} sm={24} lg={6}>
						<Title level={4} className="title">Voz del Cliente</Title>
						<Card className="sample-Cliente1 ">
							<Row>
								<Col className="sample-data" span={8}>
									<Title className="sample-data-title">Usuarios</Title>
									<Paragraph className="colour1">{contadores.usuarios}</Paragraph>
								</Col>
								<Col className="sample-data" span={8}>
									<Title className="sample-data-title">CN</Title>
									<Paragraph className="colour2">{contadores.centros_negocio}</Paragraph>
								</Col>
								<Col className="sample-data" span={8}>
									<Title className="sample-data-title">Encuestas</Title>
									<Paragraph className="colour3">{contadores.encuestas}</Paragraph>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<Row className="main" gutter={[22, 22]}>
					<Col xs={24} sm={24} lg={18}>
						<Row gutter={[18]}>
							<Col xs={24} sm={24} lg={12}>
								<Title level={4}>Encuestas en Progreso</Title>
								{this.state.progreso.length < 1 ? <Empty style={{ paddingTop: '4.5em', paddingBottom: '4.5em', background: "white", borderRadius: '10px', marginTop: '1rem' }} description="No hay ninguna encuesta en Progreso" /> : ''}
								{this.state.progreso.map((item, index) => {
									return <Card className="card-dashboard" key={index}>
										<Row>
											<Col className="center" span={8}>
												<div className="num-resultados">
													{this.getdates(item.fecha_inicio, item.fecha_final)}
												</div>
											</Col>
											<Col className="text-left" span={16}>
												<Text strong className="font-16">{item.nombre}</Text>
												<Text className="font-14">Numero de respuestas: {item.num_resultados}</Text>
												<Text className="font-12">
													concluye: {moment(item.fecha_final).format("D-MMM-YYYY")}
												</Text>
											</Col>
										</Row>
									</Card>
								})}

							</Col>
							<Col xs={24} sm={24} lg={12}>
								<Title level={4}>Reportes Recientes</Title>
								{(Array.isArray(this.state.recientes) && this.state.recientes.length >= 1) ? <div>

									<Card className="card-reportes">
										<Row>
											<Col className="center" span={6}>
												<img src="/images/pdf.svg" alt="pdf.svg" />
											</Col>
											<Col span={18}>
												<div className="text-left">
													<Text strong className="font-16 limit-text"> {this.state.recientes[0]?.nombre}  </Text>
												</div>
												<Button type="ghost" className="btn-pdf" onClick={this.handlePrintReport} id={this.state.recientes[0]?._id}>
													Reporte.pdf
												</Button>
											</Col>
										</Row>
										<hr />
										<Row>
											<Col className="center" span={6}>
												<img src="/images/pdf.svg" alt="pdf.svg" />
											</Col>
											<Col span={18}>
												<div className="text-left">
													<Text strong className="font-16 limit-text"> {this.state.recientes[1]?.nombre}  </Text>
												</div>
												<Button type="ghost" className="btn-pdf" onClick={this.handlePrintReport} id={this.state.recientes[1]?._id}>
													Reporte.pdf
												 </Button>
											</Col>
										</Row>
									</Card>

								</div> : <Empty style={{ paddingTop: '2em', paddingBottom: '2em', background: "white", borderRadius: '10px', marginTop: '1rem' }} description="No hay ninguna encuesta reciente" />}
							</Col>
						</Row>
						<Row className="mt-1">
							<Col className="sample-data" span={24}>
								<Title level={4} className="title">Encuestas Activas</Title>	
								{activas.length > 0 ? activas.map((encuesta, index) => {
									return <Card className={`card-active ${index % 2 === 0 ? '' : 'color-active'}`} key={index}>
										<Row>
											<Col className="center" xs={24} lg={5}>
												<Title className="active-title limit-text">{encuesta.nombre}</Title>
											</Col>
											<Col className="center" xs={24} lg={5}>
												<Title className="active-data">Preguntas: {encuesta.preguntas.length}</Title>
											</Col>

											<Col className="center" xs={24} lg={5}>
												<Title className="active-data">
													{moment(encuesta.fecha_inicio).format("D-MMM-YYYY")}
												</Title>
											</Col>
											<Col className="center" xs={24} lg={5}>
												<Title className="active-data">
													{moment(encuesta.fecha_final).format("D-MMM-YYYY")}
												</Title>
											</Col>
											<Col className="center" xs={24} lg={4}>
												<Title className="active-data">...</Title>
											</Col>
										</Row>
									</Card>
								}) : <Empty style={{ paddingTop: '2em', paddingBottom: '2em', background: "white", borderRadius: '10px' }} description="No hay ninguna activa" />}

							</Col>
						</Row>
					</Col>
					<Col xs={24} sm={24} lg={6}>
						<Title level={4}>Encuestas programadas</Title>
						<Card className="sample-data-card-programadas mt-1">
							<List
								className="demo-loadmore-list"
								itemLayout="horizontal"
								dataSource={[
									{
										loading: false,
										name: {
											last: "juan"
										}
									},
								]}
								renderItem={item => (
									<List.Item className="survey-schedule-content">
										<Col className="sample-data" span={24}>
											{programadas.map((encuesta, index) => {
												const salida = (
													<List.Item.Meta
														key={index}
														avatar={
															<Avatar style={colores[index]}>{encuesta.nombre.charAt(0)}</Avatar>
														}
														title={<span className="survey-program-title">{encuesta.nombre}</span>}
														description={
															<span className="survey-program-description">
																{moment(encuesta.fecha_inicio).format("D-MMM-YYYY")} - {moment(encuesta.fecha_final).format("D-MMM-YYYY")}
															</span>}
													/>)
												return salida
											})}
										</Col>
									</List.Item>
								)}
							/>
							<Button
								type="ghost"
								className="welcome-button2 title-botton2"
								onClick={this.showModal}>
								<Link to="/admin/encuestas" className="font-12">Nueva Encuesta</Link>
							</Button>
						</Card>
					</Col>
				</Row>
			</div>
		)
	}
}
