import React, { Component } from 'react';
import { Modal,  Form, Input, Button, Select, Row, Col, message, Spin  } from 'antd';

import { LoadingOutlined} from '@ant-design/icons';


const axios = require("axios").default;
const { Option } = Select;
const {TextArea} = Input;

/**
 * @class ModalTargetForm
 * @description  Clase que contiene el modal para editar/crear un target ruta: /admin/targets
 *
 **/
export default class ModalTargetForm extends Component{

	constructor(props){
		super(props)
		this.state = {
			loading: false,
			info: false,
			message: null,
			departments: {
				data: [],
				page: 1,
				quantity: 0,
				limit: 8,
				search: "",
				loading: false
			},
			users: {
				data: [],
				page: 1,
				quantity: 0,
				limit: 8,
				search: "",
				loading: false
			},
			centers: {
				data: [],
				age: 1,
				quantity: 0,
				limit: 8,
				search: "",
				loading: false
			},
			targetToEditData : []
		}
	}

	/*
	* @memberof ModalTargetForm
	*
	* @method componentDidMount
	* @description  Obtiene los primeros 8 registros de departamentos, usuarios y centros de negocio de la BD, para mostralos en los selects
	* del formulario
	* de usuarios
	*/
	async componentDidMount(): void {
		const {  departments, users, centers } = this.state;
		this.getDataPagination("/departamentos", "departments", departments.page, departments.quantity, departments.limit, departments.search);
		this.getDataPagination("/usuarios", "users", users.page, users.quantity, users.limit, users.search);
		this.getDataPagination("/centrosNegocio", "centers", centers.page, centers.quantity, centers.limit, centers.search)
	}

	/*
	* @memberof ModalTargetForm
	*
	* @method componentWillReceiveProps
	* @description  Si se quiere editar un Target, cuando se presione el boton de Edit de la tabla, este modal recibe por medio de props a 
	* el target a editar, y lo pone en el state
	*/
	componentWillReceiveProps(nextProps) {
		this.setState({targetToEditData: nextProps.targetToEdit})
	}

	/**
	* @memberof ModalTargetForm
	*
	* @method hideModalTarget
	* @description llama a al funcion del comoponente padre (Target.js) que recibe por props para cerrar este modal
	**/
	hideModalTarget = () => {
		this.props.hideModalTarget();
	}

	/*
	* @memberof ModalTargetForm
	*
	*	@method saveTarget
	*	@description  Metodo handler que se llama al hacer submit al formulario (onFinish)
	*/
	saveTarget = (values) => {
		if(this.state.targetToEditData.length === 0){
			this.NewTarget(values);
		} else {
			 this.UpdateTarget(values);
		}
		this.hideModalTarget();

	}

	/**
	* @memberof ModalTargetForm
	*
	* @method NewTarget
	* @description  Envia los datos del formulario al Servidor para un nuevo registro
	*
	* @param values (array)
	* Contiene los campos del formulario para registrar al usuario
	*
	* @returns response (array)
	**/
	NewTarget = (values) => {
		console.log('nuevo target')
		axios.post("/targets/add", {
			nombre 				: values.nombre,
			descripcion			: values.descripcion,
			departamentos 		: values.departamentos,
			usuarios   			: values.usuarios,
			centros_negocios 	: values.centros_negocios,
		},
		{
			headers: {
				Authorization: sessionStorage.getItem('token')
			}
		})
		.then(res => {
			if(res.data.success) {
				console.log('target creado')
				this.setState({loading: false, info: true, message: "¡Target creado exitosamente!"});
				message.success(this.state.message);
				this.props.ReloadList();
			}
			else{
				this.setState({loading: false, info: false, message: ""});	
				message.error("¡Ooops! No se ha podido crear el Target");
			}

		})
		.catch(error => {
			console.log(error.message)
			this.setState({loading: false, info: false, message: "¡Ooops! No se ha podido crear el Target"});
			if (error.response.status === 403)
        		message.error(`${error.response.data.message}`)
      		else
				message.error(this.state.message);
		})
	}

	/**
	* @memberof ModalTargetForm
	*
	* @method UpdateTarget
	* @description  Envia los datos del formulario al Servidor para actualizar un registro
	*
	* @param values (array)
	* Contiene los campos del formulario para registrar al perfil 
	*
	* @returns response (array)
	**/
	UpdateTarget = (values) => {
		axios.put("/targets/update", {
			id                   : this.state.targetToEditData._id,
			nombre             	 : values.nombre,     
			descripcion          : values.descripcion,
			departamentos        : values.departamentos,
			centros_negocios     : values.centros_negocios, 
			usuarios             : values.usuarios, 
		},
		{ 
			headers: { 
				Authorization: sessionStorage.getItem('token') 
			} 
		})
		.then(res => {
			if(res.data.success){
				this.setState({ loading: false, info: true, message: "!Target actualizado con exito!" });
				message.success(this.state.message);
				this.props.ReloadList();
			}
			else{
				this.setState({loading:false, info: false, message: "" });
				message.error("¡Ooops! No se ha podido actualizar el Target");

			}
		})
		.catch(error => {
			console.log(error);
			this.setState({loading:false, info: false, message: "¡Ooops! No se ha podido actualizar el Target" });
			 if (error.response.status === 403)
        		message.error(`${error.response.data.message}`)
      		else
				message.error(this.state.message);
		})
	}

	/**
	* @memberOf ModalTargetForm
	* @function getDataPagination
	*
	* URL, type, page, quantity, limit, search
	* @param URL
	* @description URL a la cual se obtendrá la paginación.
	*
	* @param type
	* @description Es el nombre que tiene el objeto que almacena la paginacion, aqui es users, targets o profiles..
	*
	* @param page
	* @description Numero de pagina de la paginacíon.
	*
	* @param quantity
	* @description Total de registros.
	*
	* @param limit
	* @description Limite de Elementos en la paginación.
	*
	* @param search
	* @description Si hay algo que buscar, que lo busque.
	*
	**/
	getDataPagination = async (URL, type, page, quantity, limit, search) => {
		/* Para que funcione, necesito declarado un objeto en el estado con la siguiente estructura */
		//Obtengo el objeto del estado
		let before = this.state[type];
		//Activo el LOADING
		before.loading = true;
		this.state[type] = before;
		//Renderizo con lo nuevos valores
		this.setState({update: !this.state.update})
		//hago la solicitud
		return axios.get(URL,{
			params: {page, quantity, limit, search},
			headers: { Authorization: sessionStorage.getItem("token")}
		})
		.then(response => {
			//Actualizo los valores con la nueva paginación. Debe ser un AGGREGATAE en el servidor.
			var reponseData = [];
			if(URL === "/departamentos" || URL === "/usuarios"){
  				reponseData = response.data.data.itemsList;
      		} else {
        		reponseData = response.data.data;	
			}
			const {page, limit, search} = this.state[type];
			//Actualizo el objeto con los nuevos valores en la paginacion
			this.state[type] = {
				data: reponseData,
				page: page,
				quantity: reponseData.itemCount,
				limit: limit,
				search: search,
				loading: false
			};
			//Renderizo
			this.setState({update: !this.state.update})
		})
		.catch(error => {
			console.log('error', error)
		})
	}

	/*
	* @memberof ModelTargetForm
	*
	* @method selectOptions
	* @description Metodo que despliega las opciones del select del modal, que esten en el state, si al momento de editar
	* un target y su CDN, departamento o usuario relacionado no esta, este se agrega a la lista de elementos
	*/
	selectOptions = (options, index) =>{
		const { targetToEditData } = this.state;
		let arr = []
		let arrOptions =  options.data.map((item,index)=>{
			arr.push(item._id)
			return <Option value={item._id} key={index}>
				{ <span>{item.nombre} </span>}
			</Option>
		})

		switch(index){
			case 1:
				if(targetToEditData.DeptOptions !== undefined){
					targetToEditData.DeptOptions.map((dpt)=>{
						if( !arr.includes(dpt.id)){
							arrOptions.push(
								<Option value={dpt.id}><span>{dpt.nombre} </span></Option>
							)
						}
					})
				}
			break;
			case 2:
				if(targetToEditData.UserOptions !== undefined){
					targetToEditData.UserOptions.map((user)=>{
						if( !arr.includes(user.id)){
							arrOptions.push(
								<Option value={user.id}><span>{user.nombre} </span></Option>
							)
						}
					})
				}
			break;
			case 3:
				if(targetToEditData.CDNOptions !== undefined){
					targetToEditData.CDNOptions.map((cdn)=>{
						if( !arr.includes(cdn.id)){
							arrOptions.push(
								<Option value={cdn.id}><span>{cdn.nombre} </span></Option>
							)
						}
					})
				}
			break;
		}
		return arrOptions;
	}

	render(){
		//state
		const {departments, users, centers } = this.state;
		//props
		const { modalTargetVisible, modalTitle } = this.props;

		return(
			<Modal
				visible={modalTargetVisible}
				footer={null}
				title={null}
				onCancel={this.hideModalTarget}
				destroyOnClose = {true}
				closeIcon={<Button type="ghost" className="closable-button" onClick={this.hideModalTarget}>
					<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
							  fill="#E1E4ED"/>
						<path d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
							  fill="#E1E4ED"/>
					</svg>
				</Button>}
				className="modal-form"
			>
				<h3 className="modal-title">{modalTitle}</h3>

				<Row>
					<Col xs={{span: 20, offset: 2}} sm={{span: 16, offset: 4}}>
						<Form
							name="fomulario-target"
							className="form-modal"
							onFinish={this.saveTarget}
							layout="vertical"
							initialValues={this.state.targetToEditData}
						>

							<Form.Item
								className="v-label-gray"
								label="Nombre de Target"
								name="nombre"
								rules={
									[{
										required: true,
										message: "Ingrese el nombre de Target",
									}]}
							>
								<Input></Input>
							</Form.Item>

							<Form.Item
								className="v-label-gray"
								label="Descripción"
								name="descripcion"
								rules={
									[{
										required: false,
										message: "Ingrese una descripción para el Target",
									}]}
							>
								<TextArea rows={4}/>
							</Form.Item>

							<Form.Item
								className="v-label-gray multiple-select-form-item"
								label="Departamentos"
								name="departamentos"
								rules={
									[{
										required: false,
										message: "Seleccione un departamento",
									}]}
							>
								<Select
									mode="multiple"								
									filterOption={false}
									onSearch={(search) => this.getDataPagination("/departamentos", "departments", departments.page, departments.quantity, departments.limit, search)}
									notFoundContent={departments.loading ? <Spin size="large" indicator={<LoadingOutlined
										style={{fontSize: 34}} spin/>}/> : null}
								>
									{ this.selectOptions(departments,1) }
								</Select>
							</Form.Item>

							<Form.Item
								className="multiple-select-form-item v-label-gray"
								label="Usuarios"
								name="usuarios"
								
							>
								<Select
									mode="multiple"									
									filterOption={false}
									onSearch={(search) => this.getDataPagination("/usuarios", "users", users.page, users.quantity, users.limit, search)}
									notFoundContent={users.loading ? <Spin size="large" indicator={<LoadingOutlined
										style={{fontSize: 34}} spin/>}/> : null}
								>
									{ this.selectOptions(users,2) }
								</Select>
							</Form.Item>

							<Form.Item
								className="multiple-select-form-item v-label-gray"
								label="Centros de Negocio"
								name="centros_negocios"
								rules={
									[{
										required: false,
										message: "Seleccione un centro de Negocio",
									}]}
							>
								<Select
									mode="multiple"
									filterOption={false}
									onSearch={(search) => this.getDataPagination("/centrosNegocio", "centers", centers.page, centers.quantity, centers.limit, search)}
									notFoundContent={centers.loading ? <Spin size="large" indicator={<LoadingOutlined
										style={{fontSize: 34}} spin/>}/> : null}
								>
									{ this.selectOptions(centers,3) }
								</Select>
							</Form.Item>


							<Form.Item>
								<Button htmlType="submit" className="v-button-primary">Guardar</Button>
							</Form.Item>
						</Form>

					</Col>
				</Row>

			</Modal>
		)
	}
}
