import React, { Component } from "react";
import { Row, Col,  Button, Typography, Table, Modal } from 'antd';
import ReactEcharts from 'echarts-for-react';
//import { DownOutlined } from '@ant-design/icons';

//css 
import '../../../../../styles/encuestas.css';

const { Title, Text } = Typography;
const axios = require("axios").default;


export default class AnalyticsPregunta extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			pregunta: {
				respuestas: []
			},
			resultados: {},
			ModalVisible: false,
			urlImage: '',
		}
	}



	/**
	 * @methodOf AnalyticsPregunta
	 * @function chartInfo
	 *
	 * @description informacion para la grafica de Pastel
	 *
	 * */
	chartInfo = () => ({
		tooltip: {
			trigger: 'item',
			formatter: "{a} <br/>Opcion {b} : Resultados {c} ({d}%)"
		},
		series: [
			{
				name: this.props.pregunta.nombre,
				type: 'pie',
				radius: '60%',
				center: ['50%', '40%'],
				data: this.props.pregunta.respuestas.map((item) => {


					console.log('item charts',item);

					//let numero = parseInt(item.numero) + 1;
					

					let numero = parseInt(item.numero) + 1;

					return {
						value: item.resultados,
						name: item.numero,
						itemStyle: {
							// color: item.style?.color,
							borderWidth: 2,
							borderColor: '#76236c'
						}
					}
				}),
				itemStyle: {
					emphasis: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					}
				}
			}
		]
	});


	/**
	 * @methodOf AnalyticsPregunta
	 * @function renderImages
	 *
	 * @description muestra las imagenes dentro de la tabla
	 *
	 * */
	renderImages = (row) => {
		return <div style={{ display: 'flex', alignItems: 'center' }}>
			<div>{row.numero}</div>
			<div className="images-analytics-encuesta">
				{row.arrImages.map(imagen => {					
					let urlImage = `${axios.defaults.baseURL}/upload/${imagen}`
					return <div>
						<h1
							onClick={() => this.setState({ ModalVisible: true, urlImage: urlImage })}
							className="imagen-view" 
							style={{ backgroundImage: `url(${urlImage})` }}></h1>
					</div>
				})}
			</div>
		</div>
	}

	render() {

		const columns = [{
			title: 'Opciones de Respuesta',
			dataIndex: 'nombre',
			width: '60%',
			render: (text, row) => {
				if (row.image === false) {
					return text
				} else {
					return this.renderImages(row)
				}
			}
		}, {
			title: 'Respuesta',
			dataIndex: 'respuesta',
			width: '40%'
		},
		];
		//adecua la informacion para usarla en la tabla
		const data = this.props.pregunta.respuestas.map((respuesta, index) => {
			let nombre = respuesta.descripcion
			let numero = parseInt(respuesta.numero) + 1
			let cantidad = respuesta.resultados;

			const arr = respuesta.imagen.map(imagen => imagen.imagen)

			return {
				key: index,
				nombre: `${numero}. ${nombre}`,
				respuesta: cantidad ? cantidad : 0,
				image: arr.length > 0 ? true : false,
				arrImages: arr,
				numero: numero,
				posicion: respuesta.style?.posicion,
			}
		})


		return (
			<div>
				<div className="card-analytics mt-2">
					<div className="card-header-analytic-encuesta pd-1">
						<div className="card-info-pregunta">
							<Title level={4} className="text-vianney"> {this.props.numero + 1}</Title>
							<Text className="ml-3">{this.props.pregunta.nombre}</Text>
							<Text className="ml-3">{this.props.pregunta.pregunta}</Text>
						</div>
					</div>
					<Row>
						<Col xs={24} lg={8}>
							<div className="">
								<ReactEcharts
									option={this.chartInfo()}
									style={{ height: 300 }} />
							</div>
						</Col>
						<Col xs={24} lg={{ span: 12, offset: 2 }}>
							<div>
								<Table
									className="table-analytics mb-2"
									columns={columns}
									dataSource={data}
									pagination={false}
									rowClassName={(record, index) => {
										if (record.posicion)
											return record.posicion
									}}
									size="small"
									bordered={false}
									footer={() => {
										let total = 0;

										this.state.pregunta.respuestas.forEach((respuesta) => {
											total += respuesta.count;
										})
										return <div className="text-right">Total {total}</div>
									}
									} />
							</div>
						</Col>
					</Row>
				</div>
				<Modal
					visible={this.state.ModalVisible}
					onCancel={() => this.setState({ ModalVisible: false })}
					title={null}
					footer={null}
					className="modal-form preview-image"
					closeIcon={<Button type="ghost" className="closable-button" onClick={() => this.setState({ ModalVisible: false })}>
						<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
								fill="#E1E4ED" />
							<path
								d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
								fill="#E1E4ED" />
						</svg>
					</Button>}
				>
					<img
						style={{
							// marginTop: '2.5em',
							width: '100%',
							borderRadius: '5px'
						}}
						src={this.state.urlImage}
						alt="imagen del producto"
					/>
				</Modal>
			</div>
		)
	}
}