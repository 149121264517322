import React, {Component} from 'react';
import {Button, Col, Modal, Radio, Row, Typography} from 'antd'

const {Title, Paragraph} = Typography;

/*
* funcion Radio Button centro de negocio, Valor del radio: false
*/
function UsuarioCentroNegocio(props) {

    const checked = props.checked;

    /*Obtenemos el color, si esta dentro es blanco, si esta fuera, es el rosa*/
    const getColor = () => (checked) ? 'white' : '#994E90'

    return (

        <Radio.Button value={false} className="button-select center-vianney-l" style={{
            borderColor: getColor(),
            background: ((checked) ? 'linear-gradient(90deg, #9A4F91 0%, #76236C 102.04%)' : 'white')
        }}>
            <img width="170" className="image-content" src={checked?"/iconos/icono_cn-white.svg":"/iconos/icono_cn2.svg"} alt=""/>
            <Paragraph className="text-content" style={{color: getColor()}}>CENTRO DE NEGOCIOS</Paragraph>
        </Radio.Button>

    )
}

/*
* funcion Radio Button Vianney, Valor del radio: true
*/
function UsuarioVianney(props) {

    const checked = props.checked;

    /*Obtenemos el color, si esta dentro es blanco, si esta fuera, es el rosa*/
    const getColor = () => (checked) ? 'white' : '#994E90'
    const getIcon = () => (checked) ? "/iconos/Vianney-blanco.svg":"/iconos/Vianney-morado.svg"

    return (
        <Radio.Button value={true} className="button-select center-vianney" style={{
            borderColor: getColor(),
            background: ((checked) ? 'linear-gradient(90deg, #9A4F91 0%, #76236C 102.04%)' : 'white')
        }}>
            <img className="image-content" src={getIcon()} alt="vianney"/>
            <Paragraph className="text-content" style={{color: getColor()}}>VIANNEY</Paragraph>
        </Radio.Button>
    )
}


/**
 * @class ModalTypeUser
 * @description  Clase que contiene el primer modal para crear un nuevo usuario, eligiendo el tipo de usuario
 * mismos, ruta: /admin/usuarios
 *
 **/
export default class ModalTypeUser extends Component {

    //Cierra este modal
    hideModalTypeUser = () => {
        this.props.hideModalTypeUser();
    }

    /**
     * @state tipo
     * @description Almacena el tipo de usuario
     */
    state = {
        tipo: null,
    }

    /**
     * @function accept
     * @description Valida si ya esta selccionado el tipo de modal. Si pasa cierra este modal (Tipo de usario)
     * y abre el siguinte (Formulario de usuario: ModalUserForm.js)
     * */
    accept = () => {
        if (this.state.tipo === null) {
            Modal.error({title: "Debe seleccionar un tipo de encuesta",})
            return;
        } else {
            //this.props.accept(this.state.tipo);
            this.props.hideModalTypeUser();
            this.props.showModalUser(this.state.tipo);
        }
    };

    render() {

        const {modalTypeUserVisible} = this.props;
        const {tipo} = this.state;

        return (

            <Modal
                visible={modalTypeUserVisible}
                title={null}
                footer={null}
                onCancel={this.hideModalTypeUser}
                closeIcon={<Button type="ghost" className="closable-button" onClick={this.hideModalTypeUser}>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
                              fill="#E1E4ED"/>
                        <path d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
                              fill="#E1E4ED"/>
                    </svg>
                </Button>}
                className="modal-form"

            >
                <Title level={3} className="modal-title">Tipo de Usuario </Title>

                <Paragraph className="modal-sub-title">¿Qué tipo de usuario quieres crear?</Paragraph>

                <Radio.Group
                    onChange={(e) => this.setState({tipo: e.target.value})}
                    defaultValue="a"
                    className="buttons-selection-group">

                    <Row gutter={[20, 20]} className="buttons-selection">
                        <Col xs={{span: 16, offset: 1}} sm={12} md={11}>
                            <UsuarioCentroNegocio checked={tipo === false}/>
                        </Col>
                        <Col xs={{span: 16, offset: 1}} sm={12} md={11}>
                            <UsuarioVianney checked={tipo === true}/>
                        </Col>
                    </Row>
                </Radio.Group>

                <Button style={{paddingTop: '5px',}} type="ghost" className="v-button-primary mt-3"
                        onClick={this.accept}>Continuar</Button>
            </Modal>
        )
    }
}

