import React, { Component } from 'react';
import { Row, Col, Menu, Dropdown, Button, Input, Modal, Layout, Switch, Table, message, Radio } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import DepartamentoForm from './DepartamentoForm'
import DepartamentosView from './DepartamentosView';

const { Content } = Layout;
const axios = require('axios').default;
const { Search } = Input;
const { confirm } = Modal;


/**
 * @method ArrowIcon
 * @description  Icono Dropdown Barra fillter
 *
 **/
function ArrowIcon() {
	return <svg style={{ marginLeft: '10px' }} width="8" height="5" viewBox="0 0 8 5" fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.98817 0.00230214C5.10632 0.00230214 6.22447 0.00528392 7.34261 3.03092e-05C7.60672 -0.0012476 7.84092 0.0366636 7.95916 0.347053C8.08234 0.670505 7.90441 0.87 7.72407 1.07092C6.64496 2.27328 5.56699 3.47693 4.48787 4.6793C4.10679 5.10399 3.89515 5.10598 3.52244 4.69066C2.43623 3.47991 1.35129 2.26775 0.264064 1.05814C0.0803047 0.853814 -0.0783622 0.648497 0.0419053 0.32902C0.16268 0.00783972 0.40727 3.03092e-05 0.664787 0.000740257C1.77254 0.00414801 2.88029 0.00230214 3.98817 0.00230214Z"
			fill="white" />
	</svg>
}



/**
 * @class Departamentos
 * @description  Clase que contiene la tabla de Departamentos, junto con el modal de creacion y edicion de los
 * mismos, ruta: /admin/departamentos
 *
 **/
export default class Departamentos extends Component {


	constructor(props) {
		super(props)

		this.state = {

			visible: false,
			loading: false,
			departments: [],

			/**paginador */
			currentPage: null,
			itemCount: null,
			perPage: null,
			pageCount: null,

			modalShowToggle: false,
			id_dep_selected: undefined,

			modalView: false,

			countries: [],
			states: [],

			/*datos de filtrado de la barra*/
			search      : null,   //Busqueda input
			field       : null,   //Campo a ordenar
			order       : null,   // asc o desc
			searchCountryId : null, //id del pais
			searchStateId : null,	//id del estado
			searchActivo: null,
		}
	}


	/**
   	* @memberof Departamentos
   	*
   	* @method componentDidMount
   	* @description Cambia el titulo del Navbar al entrar a este componente, manda a llamar los datos de la tabla
   	**/
	componentDidMount() {
		this.props.updateNavigationInformation('Departamentos');
		this.LoadData(1);
		this.GetCountries();
	}

	/**
	* @memberof Departamentos
	*
	* @method LoadData
	* @description  Metodo para paginar los datos de la tabla
	*
	**/
	LoadData(page) {
		this.setState({ loading: true })
		axios.get('/departamentos', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				page: page,
				limit: 10,
				search: this.state.search,
				field: this.state.field,
				order: this.state.order,
				countryId: this.state.searchCountryId,
				stateId: this.state.searchStateId,
				activo: this.state.searchActivo,
			}
		})
		.then((response) => {
			const data = response.data.data.itemsList;
			this.setState({
				departments: data,
				currentPage: response.data.data.currentPage,
				itemCount: response.data.data.itemCount,
				pageCount: response.data.data.pageCount,
				loading: false,
			})
		})
		.then(() => {
			//Si se elimina el utlimo registro de una pagina, te manda a la primera pagina
			if(this.state.currentPage > this.state.pageCount){
				this.LoadData(1)
			}
		})
		.catch((response) => {
			this.setState({ loading: false })
			message.error("Ocurrio un error al cargar los Departamentos");
			console.log('error', response);
		})
	}

	/**
  * @memberof DepartamentoForm
  *
  * @method GetCountries
  * @description Metodo que manda a llamar a los paises y guardarlos en el state.countries
  *
  **/
	GetCountries = (country) => {

		axios.get('/countries', {
			params: { search: country }
		})
			.then((response) => {
				this.setState({ countries: response.data.data });
			})
			.catch((response) => {
				console.log('error', response);
			})
	}

	/**
	 * @memberof DepartamentoForm
	 *
	 * @method GetStateByCountry
	 * @description  Al cambiar el pais seleccionado, se actualizan el state.states para el  select estados
	 *
	 **/
	GetStateByCountry = value => {
		axios.post('/statesByCountry', { pais_id: value },
			{ headers: { Authorization: sessionStorage.getItem("token") } })
			.then((res) => {
				var pais_estados = res.data.data;
				this.setState({ states: pais_estados, disabled: false, searchCountryId: value, searchStateId: null });
				this.ReloadTable()
			})
			.catch((error) => {
				console.log(error)
			});
	}

	/**
	 * @memberof Departamentos
	 *
	 * @method handleFilterCountry
	 * @description  Al cambiar el pais seleccionado se manda a llamar al GetStateByCountry para la asignacion de los estados,
	 * en caso de deseleccionar un pais borra los datos del filtrado
	 *
	 **/
	handleFilterCountry = (country) => {
		if(country.target.value === this.state.searchCountryId){
			this.setState({searchCountryId: null, searchStateId: null, states: []}, () => {
				this.ReloadTable();
			})
		}else {
			this.GetStateByCountry(country.target.value);
		}
	}

	/**
	 * @memberof Departamentos
	 *
	 * @method handleFilterState
	 * @description  Al seleccionar un estado lo pone en el state y manda a actualizar la tabla con los datos filtrados
	 *
	 **/
	handleFilterState = (state) => {
		if(state === this.state.searchStateId){
			this.setState({searchStateId: null}, () => {
				this.ReloadTable();
			})
		}else {
			this.setState({searchStateId: state}, () => {
				this.ReloadTable()
			})
		}
	}

	/**
	 * @memberof Departamentos
	 *
	 * @method handleFilterActivo
	 * @description  Filtra los datos de la tabla, si estan activos o no
	 *
	 **/
	handleFilterActivo = (bool) => {
		if(bool === this.state.searchActivo){
			this.setState({searchActivo: null}, () =>{
				this.ReloadTable()
			})
		}else{
			this.setState({searchActivo: bool}, () => {
				this.ReloadTable();
			})
		}
	}

	handleSearch
	/**
	 * @memberof Departamentos
	 *
	 * @method handleFilterActivo
	 * @description  Filtra los datos de la tabla a por el dato introducido 
	 *
	 **/
	handleSearch = (value) => {
		this.setState({search: value}, () =>{
			this.ReloadTable()
		})
		
	}
	

	/**
    * @memberof Departamentos
    *
    * @method ModalPopUpHandler
    * @description  cambia el state para mostrar u ocultar el modal
    *
    **/
	ModalPopUpHandler = (item) => {
		this.GetDepartment(item);
		const changeModal = this.state.modalShowToggle;
		this.setState({ modalShowToggle: !changeModal });
	}


	/**
	* @memberof Departamentos
	*
	* @method ModalView
	* @description  cambia el state para mostrar u ocultar el modal de visualizacion
	*
	**/
	ModalView = (item) => {
		this.GetDepartment(item);
		const changeModal = this.state.modalView;
		this.setState({ modalView: !changeModal });

	}

	/**
    * @memberof Departamentos
    *
    * @method   GetDepartment
    * @description  Metodo que asigna al state.id_dep_selected el id del departamento seleccionado para mostrar el modal con los datos de ese usuario
    *
    **/
	GetDepartment(item) {
		const dep_id = item._id;
		this.setState({ id_dep_selected: dep_id })

	}

	/**
	 * @memberof Departamentos
	 *
	 * @method   handlePageClick
	 * @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	 *
	 **/
	handlePageClick = (page) => {
		console.log("pagina", page);
		this.LoadData(page);
	}


	/**
   * @memberof Departamentos
   *
   * @method ReloadTable
   * @description  Refrescar la tabla de Departamentos, con la pagina en la que se encuentra
   *
   **/ 
	ReloadTable = () => {
		this.LoadData(this.state.currentPage)
	}

	/**
	* @memberof Departamentos
	*
	* @method   handlefilterSort
	* @description ordena los datos de la tabla
	*
	**/
	 handlefilterSort(field,order){
		this.setState({field: field, order: order},() => {
			this.ReloadTable();
		})
	}

	/**
	 * @method showDeleteConfirm
	 * @description  Para cada row mostrar un modal de confirmacion para eliminar el registro, la eliminacion se realiza mediante una peticion al servidor
	 *
	 * @params item (row)
	 **/
	showDeleteConfirm = (item) => {
		const dep_name = item.currentTarget.name;
		const dep_id = item.currentTarget.id;
		const esto = this;

		confirm({
			title: 'Eliminar Departamento',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estás seguro que deseas eliminar el Departamento ' + dep_name + ' ?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',

			onOk() {
				axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');

				axios({
					method: 'delete',
					url: '/departamentos/delete',
					headers: { Authorization: sessionStorage.getItem('token') },
					data: { id: dep_id, status: 0 }
				})
					.then((response) => {
						if(response.data.success ) {
							esto.LoadData(esto.state.currentPage);
							message.success("!Departamento eliminado exitosamente!");
						}
						else {
							message.error('¡Ooops! NO se ha eliminado el Departamento');
						}
					})
					.catch((error) => {
						console.log("error al borrar el Departamento", error);
						if (error.response.status === 403)
							message.error(`${error.response.data.message}`)
						else
							message.error('¡Ooops! NO se ha eliminado el Departamento');
					})
			},
		});
	}

	render() {

		var departments = this.state.departments;
		var loading = this.state.loading;

		const columns = [
			{
				title: '',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'NOMBRE',
				dataIndex: 'nombre',
				key: 'nombre',
			},
			{
				title: 'NÚMERO',
				dataIndex: 'numero',
				key: 'numero',
				render: (text, record) => {
					return <p>{record.number}</p>
				}
			},
			{
				title: 'PAÍS',
				dataIndex: 'pais',
				key: 'pais',
				render: (text, record) => {
                    if (record.pais_id && record.pais_id.name)
					return <p>{record.pais_id.name}</p>
					// return <p>{record.pais_id.name}</p>
				}
			},
			{
				title: 'ESTADO',
				dataIndex: 'estado',
				key: 'estado',
				render: (text, record) => {

                    if (record.estado_id && record.estado_id.name)
					return <p>{record.estado_id.name}</p>
				}
			},
			// {
			// 	title: 'USUARIOS',
			// 	dataIndex: 'usuarios',
			// 	key: 'usuarios',
			// 	render: (text, record) => {
			// 		return <p>0</p>
			// 	}
			// },
			{
				title: 'STATUS',
				dataIndex: 'activo',
				key: 'activo',
				render: (text, record) => (
					(record.activo) ? <Switch checked disabled={true}/> : <Switch disabled={true} />
				)
			},
			{
				title: 'ACCIONES',
				dataIndex: '_id',
				key: 'actions',
				render: (text, record) => (
					<div>
						<Button className="btn-view" onClick={()=>this.ModalView(record)}>
							<img src="/iconos/view.svg" alt="Ver" />
						</Button>
						<Button className="btn-edit" onClick={()=>this.ModalPopUpHandler(record)}>
							<img src="/iconos/edit.svg" alt="edit" />
						</Button>
						<Button className="btn-delete" id={record._id} name={record.nombre} onClick={this.showDeleteConfirm}>
							<img src="/iconos/trash.svg" alt="delete" />
						</Button>
					</div>
				)
			},
		];

	   var modalview= this.state.modalView
		return (
			<Content className="page-main" style={{ padding: '30px' }}>
				<Row className="controls">
					<Col xs={24}md={20} className="filters">
						<Dropdown className="filter-control" trigger={['click']}
							overlay={
								<Menu>
									<Menu.Item onClick={()=>{this.handlefilterSort('nombre',1)}}>A-Z</Menu.Item>
									<Menu.Item onClick={()=>{this.handlefilterSort('nombre',-1)}}>Z-A</Menu.Item>
								</Menu>}
							placement="bottomLeft"
						>
							<Button type="link">Nombre <ArrowIcon /> </Button>
						</Dropdown>

						<Dropdown className="filter-control" trigger={['click']}
							overlay={
								<Menu>
									{this.state.countries.map( (country, index) => {
										return <Menu.Item className="radio-vertical" >
											<Radio 
												value={country._id} 
												onClick={(e) => this.handleFilterCountry(e)}
												checked={this.state.searchCountryId === country._id}
											>
												{country.name}
											</Radio>
										</Menu.Item>
									})}
								</Menu>
							} placement="bottomLeft">
							<Button type="link">Pais <ArrowIcon /> </Button>
						</Dropdown>

						<Dropdown className="filter-control" trigger={['click']}
							overlay={
								<Menu>
									{this.state.states.map( (state, index) => {
										return <Menu.Item className="radio-vertical" >
											<Radio 
												value={state._id} 
												onClick={(e) => this.handleFilterState(e.target.value)}
												checked={this.state.searchStateId === state._id}
											>
												{state.name}
											</Radio>
										</Menu.Item>
									})}
								</Menu>
							} placement="bottomLeft">
							<Button type="link">Estado<ArrowIcon /> </Button>
						</Dropdown>
						<Dropdown className="filter-control" trigger={['click']}
							overlay={
								<Menu>
									<Menu.Item>
										<Radio 
											value={true} 
											onClick={(e) => this.handleFilterActivo(e.target.value)}
											checked={this.state.searchActivo === 'true'}
										>
											Activo
										</Radio>
									</Menu.Item>
									<Menu.Item>
										<Radio 
											value={false} 
											onClick={(e) => this.handleFilterActivo(e.target.value)}
											checked={this.state.searchActivo === 'false'}
										>
											Inactivo
										</Radio>
									</Menu.Item>
								</Menu>}
							placement="bottomLeft"
						>
							<Button type="link">Status <ArrowIcon /> </Button>
						</Dropdown>

						<Search
							className="search-control"
							placeholder="Buscar"
							size="normal"
							bordered={false}
							enterButton
							onSearch={value => this.handleSearch(value)}
						/>
					</Col>
					<Col span={4}>
						<Button className="add-control" onClick={() => { this.setState({ modalShowToggle: true }) }}>
							<svg style={{ marginTop: '0.3em' }} width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6.4298 11.5702C6.31805 11.5702 6.24069 11.5702 6.16046 11.5702C4.35817 11.5702 2.55587 11.5702 0.753582 11.5702C0.246418 11.5702 0 11.3266 0 10.8223C0 9.60172 0 8.38395 0 7.16332C0 6.68195 0.249284 6.4298 0.727794 6.4298C2.54441 6.4298 4.35817 6.4298 6.17478 6.4298C6.25215 6.4298 6.33238 6.4298 6.43266 6.4298C6.43266 6.33238 6.43266 6.25215 6.43266 6.17478C6.43266 4.36676 6.43266 2.55874 6.43266 0.747851C6.43266 0.243553 6.67622 0 7.18338 0C8.39542 0 9.60745 0 10.8195 0C11.3266 0 11.5673 0.243553 11.5673 0.753582C11.5673 2.55587 11.5673 4.35817 11.5673 6.16046C11.5673 6.24069 11.5673 6.32092 11.5673 6.43266C11.6533 6.43266 11.7307 6.43266 11.808 6.43266C13.616 6.43266 15.4241 6.43266 17.235 6.43266C17.7593 6.43266 18 6.67049 18 7.18911C18 8.40115 18 9.61318 18 10.8252C18 11.3209 17.7536 11.5673 17.2607 11.5673C15.4527 11.5673 13.6447 11.5673 11.8338 11.5673C11.7536 11.5673 11.6762 11.5673 11.5673 11.5673C11.5673 11.6562 11.5673 11.7335 11.5673 11.8138C11.5673 13.6218 11.5673 15.4298 11.5673 17.2407C11.5673 17.7622 11.3266 18 10.8052 18C9.59312 18 8.38109 18 7.16905 18C6.67622 18 6.4298 17.7507 6.4298 17.255C6.4298 15.447 6.4298 13.639 6.4298 11.8281C6.4298 11.7507 6.4298 11.6734 6.4298 11.5702Z" fill="white" />
							</svg>
						</Button>

					</Col>
				</Row>

				<div className="page-content">
					<h5 className="items-number">Departamentos {this.state.itemCount}</h5>
					<Table
						columns={columns}
						dataSource={departments}
						className="admin-table"
						loading={loading}
						scroll={{ x: 1200 }}
						/**Paginacion de la tabla controlada desde el WebService */
						pagination={{
							onChange: this.handlePageClick,
							total: this.state.itemCount,
							current: this.state.currentPage
						}}
					/>
					<Modal
						visible={this.state.modalShowToggle}
						footer={null}
						title={null}
						destroyOnClose={true}
						closeIcon={<Button type="ghost" className="closable-button" onClick={this.ModalPopUpHandler}>
							<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
									fill="#E1E4ED" />
								<path d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
									fill="#E1E4ED" />
							</svg>
						</Button>}
						className="modal-form"

					>
						<Row>
							<Col xs={{span: 20, offset: 2}} sm={{span: 16, offset: 4}}>
								<DepartamentoForm id_dep={this.state.id_dep_selected} UpdateTable={this.ReloadTable} close={() => this.setState({ modalShowToggle: false })} />
							</Col>
						</Row>
					</Modal>
					<Modal
						visible={modalview}
						footer={null}
						title={null}
						destroyOnClose={true}
						closeIcon={<Button type="ghost" className="closable-button" onClick={this.ModalView}>
							<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
									fill="#E1E4ED" />
								<path d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
									fill="#E1E4ED" />
							</svg>
						</Button>}
						className="modal-form"
					>
						<DepartamentosView id_dep={this.state.id_dep_selected} close={() => this.setState({ modalView: false })}/>

					</Modal>
				</div>
			</Content>
		)
	}
}
