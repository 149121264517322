import React, { Component } from "react";
import { Button, Col, Dropdown, Input, Layout, Menu, Pagination, Row, Spin, DatePicker } from 'antd';


import CardEncuesta from './CardEncuesta';
import ModalEncuesta from './ModalEncuesta';
import locale from 'antd/es/date-picker/locale/es_ES';

import '../../../styles/encuestas.css';
import { Redirect } from "react-router-dom";

const axios = require('axios').default;
const { Search } = Input;
const { Content } = Layout;
const { RangePicker } = DatePicker;

function ArrowIcon() {
    return <svg style={{ marginLeft: '10px' }} width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.98817 0.00230214C5.10632 0.00230214 6.22447 0.00528392 7.34261 3.03092e-05C7.60672 -0.0012476 7.84092 0.0366636 7.95916 0.347053C8.08234 0.670505 7.90441 0.87 7.72407 1.07092C6.64496 2.27328 5.56699 3.47693 4.48787 4.6793C4.10679 5.10399 3.89515 5.10598 3.52244 4.69066C2.43623 3.47991 1.35129 2.26775 0.264064 1.05814C0.0803047 0.853814 -0.0783622 0.648497 0.0419053 0.32902C0.16268 0.00783972 0.40727 3.03092e-05 0.664787 0.000740257C1.77254 0.00414801 2.88029 0.00230214 3.98817 0.00230214Z" fill="white" />
    </svg>
}

/*
const menu = (
    <Menu>
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="http://www.alipay.com/">
                1st menu item
            </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="http://www.taobao.com/">
                2nd menu item
            </a>
        </Menu.Item>
        <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">
                3rd menu item
            </a>
        </Menu.Item>
    </Menu>
);
*/
/**
 * @class Encuestas
 * @description Componente de lista de encuestas
 * */
export default class Encuestas extends Component {


    state = {
        modalVisible: false,
        redirect: false,

        data: [],
        page: 1,
        quantity: 0,
        limit: 10,

        loading: false,


        nombre: undefined,
        descripcion: undefined,

        status: undefined,
        start: undefined,
        end: undefined,
        search: undefined,




        dropdowns: {
            nombre: false,
            status: false,
        },

    };


    surveyType = 'generica'

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.props.updateNavigationInformation('Encuestas');
        const { page, quantity } = this.state;
        this.getSurveys(page, quantity)
    }

    /**
     * @methodOf Encuestas
     * @description Realiza la busqueda de encuestas y la integra como estado.
     * */
    getSurveys = (
        page = this.state.page,
        quantity = this.state.limit,
        nombre = this.state.nombre,
        status = this.state.status,
        start = this.state.start,
        end = this.state.end,
        search = this.state.search,
    ) => {
        this.setState({
            loading: true
        })
        axios.get('/encuestas', {
            headers: {
                Authorization: sessionStorage.getItem('token')
            },
            params: {
                page: page,
                limit: quantity,
                nombre,
                status,
                start,
                end,
                search,
            }
        })
            .then((response) => {
                const data = response.data.data;
                console.log(data)
                
                this.setState({
                    page: data.currentPage,
                    data: [...data.itemsList],
                    quantity: data.itemCount,
                    loading: false
                })
            })
            .catch((error) => {
                console.log('error', error)
            })
    }


    /**
     * @methodOf  Encuestas
     *
     * @function handleVisibleChange
     * @description Permite mostrar u ocultar los dropdowns en general
     *
     * @param type
     * @description Tipo de dropdown (proyeto, rubro, subruro, area, cuenta y tipo)
     *
     * @flag
     * @description Si se mostrará u ocultará.
     *
     * */
    handleVisibleChange = (type, flag) => {
        let { dropdowns } = this.state;
        dropdowns[type] = flag;
        this.setState({ dropdowns });
    };



    /**
 * @methodOf  Encuestas
 *
 * @function updateSort
 * @description Actualiza un sort de ordenamiento.
 *
 * @param id
 * @description ID del area a agregar
 *
 * */
    updateSort = async (type, id) => {
        if (this.state[type] === id)
            this.state[type] = undefined;
        else
            this.state[type] = id;
        this.getSurveys();

    }


    /**
     * @methodOf  Transacciones
     *
     * @function onDatesChange
     * @description Se actualizan los valores de las fechas.
     *
     * @param values
     * @description Valores de los rangerspicers
     *
     * */
    onDatesChange = async (values) => {
        try {
            this.state.start = values[0].format('YYYY-MM-DD');
            this.state.end = values[1].format('YYYY-MM-DD');
        } catch (e) {
            this.state.start = null;
            this.state.end = null;
        }
        this.getSurveys();
    };

    /**
     * @methodOf  Transacciones
     *
     * @function onSearch
     * @description Actualiza por medio de la barra de busqueda.
     *
     * @param e
     * @description EVento de la busqueda.
     *
     * */
    onSearch = (e) => {
        const search = e.target.value;
        if (search !== "" && search !== null && search !== undefined) {
            this.state.search = search;
        } else {
            this.state.search = undefined;
        }
        this.getSurveys()
    }

    /**
     * @methodOf Encuestas
     * @description Abre el modal
     * */
    showModal = () => this.setState({ modalVisible: true })

    /**
     * @methodOf ModalEncuesta
     * @description Cierra el modal
     * */
    hideModal = () => this.setState({ modalVisible: false })

    /**
     * @methodOf Realiza la redireccion
     * @description Cierra el modal
     * */
    redirect = () => (this.state.redirect) ? <Redirect to={'/encuestas/' + this.surveyType} /> : null

    render() {

        const { modalVisible, data, quantity, loading } = this.state;
        const {
            hideModal,
            showModal,
            redirect,
            getSurveys,
            updateSort,
            handleVisibleChange,
            onDatesChange,
            onSearch } = this;

        return <Content className="page-main" style={{ padding: '30px' }}>
            {redirect()}
            <Row className="controls">
                <Col xs={24} md={20} className="filters filters-encuestas">
                    <RangePicker
                        locale={locale}
                        className="filter-control ranger-picker-control"
                        onChange={onDatesChange}
                    />
                    <Dropdown
                        trigger={['click']}
                        className="filter-control"
                        visible={this.state.dropdowns.nombre}
                        onVisibleChange={(flag) => handleVisibleChange('nombre', flag)}
                        overlay={
                            <Menu
                                style={{
                                    minWidth: '260px',
                                    padding: '10px 5px'
                                }}
                            >
                                <Menu.Item key="1" onClick={() => {
                                    updateSort('nombre', 1)
                                }}>
                                    <input
                                        onChange={() => updateSort('nombre', 1)}
                                        onClick={() => updateSort('nombre', 1)}
                                        type="radio"
                                        name="nombre"
                                        value={1}
                                        style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                        checked={this.state.nombre === 1}
                                    />
                                    A - Z
                                </Menu.Item>
                                <Menu.Item key="2" onClick={() => {
                                    updateSort('nombre', -1)
                                }}>
                                    <input
                                        onChange={() => updateSort('nombre', -1)}
                                        onClick={() => updateSort('nombre', -1)}
                                        type="radio"
                                        name="nombre"
                                        value={-1}
                                        style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                        checked={this.state.nombre === -1}
                                    />
                                    Z - A
                                </Menu.Item>
                            </Menu>
                        }>
                        <Button type="link">Nombre <ArrowIcon /> </Button>
                    </Dropdown>
                    <Dropdown
                        trigger={['click']}
                        className="filter-control"
                        visible={this.state.dropdowns.status}
                        onVisibleChange={(flag) => handleVisibleChange('status', flag)}
                        overlay={
                            <Menu
                                style={{
                                    minWidth: '260px',
                                    padding: '10px 5px'
                                }}
                            >
                                <Menu.Item key="1" onClick={() => {
                                    updateSort('status', true)
                                }}>
                                    <input
                                        onChange={() => updateSort('status', true)}
                                        onClick={() => updateSort('status', true)}
                                        type="radio"
                                        name="status"
                                        value={true}
                                        style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                        checked={this.state.status === true}
                                    />
                                    Activo
                                </Menu.Item>
                                <Menu.Item key="1" onClick={() => {
                                    updateSort('status', false)
                                }}>
                                    <input
                                        onChange={() => updateSort('status', false)}
                                        onClick={() => updateSort('status', false)}
                                        type="radio"
                                        name="status"
                                        value={false}
                                        style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                        checked={this.state.status === false}
                                    />
                                    Inactivo
                                </Menu.Item>
                            </Menu>
                        }>
                        <Button type="link">Status <ArrowIcon /> </Button>
                    </Dropdown>
                    <Search
                        className="search-control"
                        placeholder="Buscar"
                        size="normal"
                        bordered={false}
                        enterButton
                        onChange={onSearch}
                    />
                </Col>
                <Col span={4}>
                    <Button className="add-control" onClick={showModal}>
                        <svg style={{ marginTop: '0.3em' }} width="20" height="20" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.4298 11.5702C6.31805 11.5702 6.24069 11.5702 6.16046 11.5702C4.35817 11.5702 2.55587 11.5702 0.753582 11.5702C0.246418 11.5702 0 11.3266 0 10.8223C0 9.60172 0 8.38395 0 7.16332C0 6.68195 0.249284 6.4298 0.727794 6.4298C2.54441 6.4298 4.35817 6.4298 6.17478 6.4298C6.25215 6.4298 6.33238 6.4298 6.43266 6.4298C6.43266 6.33238 6.43266 6.25215 6.43266 6.17478C6.43266 4.36676 6.43266 2.55874 6.43266 0.747851C6.43266 0.243553 6.67622 0 7.18338 0C8.39542 0 9.60745 0 10.8195 0C11.3266 0 11.5673 0.243553 11.5673 0.753582C11.5673 2.55587 11.5673 4.35817 11.5673 6.16046C11.5673 6.24069 11.5673 6.32092 11.5673 6.43266C11.6533 6.43266 11.7307 6.43266 11.808 6.43266C13.616 6.43266 15.4241 6.43266 17.235 6.43266C17.7593 6.43266 18 6.67049 18 7.18911C18 8.40115 18 9.61318 18 10.8252C18 11.3209 17.7536 11.5673 17.2607 11.5673C15.4527 11.5673 13.6447 11.5673 11.8338 11.5673C11.7536 11.5673 11.6762 11.5673 11.5673 11.5673C11.5673 11.6562 11.5673 11.7335 11.5673 11.8138C11.5673 13.6218 11.5673 15.4298 11.5673 17.2407C11.5673 17.7622 11.3266 18 10.8052 18C9.59312 18 8.38109 18 7.16905 18C6.67622 18 6.4298 17.7507 6.4298 17.255C6.4298 15.447 6.4298 13.639 6.4298 11.8281C6.4298 11.7507 6.4298 11.6734 6.4298 11.5702Z"
                                fill="white" />
                        </svg>
                    </Button>

                </Col>
            </Row>
            <Spin tip="Cargando..." spinning={loading}>
                <div className="page-content">
                    <h5 className="items-number">Encuestas: {quantity}</h5>
                    <Row gutter={[25, 25]}>
                        {data.map(card => {
                            return <Col className="survey-content" xs={24} sm={24} md={12}  >
                                <CardEncuesta
                                    id={card._id}
                                    nombre={card.nombre}
                                    inicio={card.fecha_inicio}
                                    fin={card.fecha_final}
                                    status={card.activo}
                                    tipo={(card.tipo === "voc") ? "Voz del Cliente" : "General"}
                                    resultados={card.resultados}
                                    update={() => this.getSurveys(this.state.page, this.state.limit)}
                                />
                            </Col>
                        })}
                    </Row>
                    <Row justify="end">
                        <Pagination defaultCurrent={1} total={quantity} onChange={getSurveys} />
                    </Row>
                </div>
            </Spin>
            <ModalEncuesta
                visible={modalVisible}
                hideModal={hideModal}
                link={true}
            />
        </Content>


    }

}
