import React, { Component } from 'react';
import { Row, Col, Button,  Form, Select, Divider, Switch, Input, Spin, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const axios = require("axios").default;


/**
 * @class DepartamentoForm
 * @description  Clase que contiene el modal para crear/editat un departamento
 *
 **/
export default class DepartamentoForm extends Component {


  constructor(props) {
    super(props)
    this.state = {
      nombreVista: 'Editar Departamento',
      status: false,
      countries: [],
      states: [],

      department: [],
      id_dep: '',
      loading: false,
      disabled: true
    }

    this.countryRef = React.createRef();
    this.stateRef = React.createRef();
    this.DepartmentForm = React.createRef();
  }



  /**
  * @memberof DepartamentoForm
  *
  * @method componentDidMount
  * @description Si se recibe un ID Cargar los datos del Departamento,
  * En caso contrario mostrar formulario vacio
  * Carga de paises al abrir el formulario
  **/
  componentDidMount() {

    this.GetCountries();

    axios.defaults.headers.get['Authorization'] = sessionStorage.getItem('token');

    var id = this.props.id_dep;

    if (id !== undefined || id !== null) {
      this.LoadDataDep(id);
    }
    else {
      this.setState({ nombreVista: 'Nuevo Departamento', loading: false });
    }


  }

  /**
  * @memberof DepartamentoForm
  *
  * @method LoadDataDep
  * @description Metodo que envia una solicitud al servidor para cargar los datos de un departamento
  */
  LoadDataDep = (id_dep) => {

    this.setState({ loading: true })
    axios.post('/departamentos/get', { id: id_dep },
      {headers: {Authorization: sessionStorage.getItem("token")}})
      .then((response) => {

        this.GetStateByCountry(response.data.data.pais_id);
        this.setState({
          id_dep: response.data.data._id,
          status: response.data.data.activo
        });

        this.DepartmentForm.current.setFieldsValue({
          nombre: response.data.data.nombre,
          numero: response.data.data.number,
          pais: response.data.data.pais_id,
          estado: response.data.data.estado_id,
        });


      })
      .catch((response) => {
        console.log('error', response);
          message.error("¡Ooops! NO se pudo cargar la informacion del Departamento");
      })
      .finally(res => {
        this.setState({ loading: false })
      })

  }

  /**
  * @memberof DepartamentoForm
  *
  * @method GetCountries
  * @description Metodo que manda a llamar a los paises y guardarlos en el state.countries
  *
  **/
  GetCountries = (country) => {

    axios.get('/countries', {
      params: { search: country }
    })
      .then((response) => {
        this.setState({ countries: response.data.data });
      })
      .catch((response) => {
        console.log('error', response);
      })
  }

  /**
   * @memberof DepartamentoForm
   *
   * @method GetStateByCountry
   * @description  Al cambiar el pais seleccionado, se actualizan el state.states para el  select estados
   *
   **/
  GetStateByCountry = value => {

    this.DepartmentForm.current.setFieldsValue({ estado: "" });

    axios.post('/statesByCountry', { pais_id: value },
      { headers: { Authorization: sessionStorage.getItem("token") } })
      .then((res) => {
        var pais_estados = res.data.data;
        this.setState({ states: pais_estados, disabled: false });
      })
      .catch((error) => {
        console.log(error.response)
      });
  }

  /**
   * @memberof DepartamentoForm
   *
   * @method handleChangeCountry
   * @description  Al cambiar el pais seleccionado se manda a l lamar al GetStateByCountry para la asignacion de los estados
   *
   **/
  handleChangeCountry = (country) => {
    console.log("pais seleccionado", country);
    this.DepartmentForm.current.setFieldsValue({ estado: "" });
    this.GetStateByCountry(country);
  }

  /**
	 * @memberof DepartamentoForm
	 *
	 * @method Exist
	 * @description  compara dos cadenas de texto para ver si son iguales
	 * @param tosave
	 *
	 **/
  Exist(tosave) {
    console.log('comparando pais', tosave)
    const countries = this.state.countries;
    var result=false;
    countries.some(country => {
     result = tosave.localeCompare(country.name, undefined, { sensitivity: 'base' }) === 0;
     console.log('comparando', tosave, 'con', countries.name, 'con resultado', result);
      return result
    });

    
    return result;
  }

  /**
   * @memberof DepartamentoForm
   *
   * @method searchState
   * @description  Filtro de los estados en el input search
   *
   **/
  searchState = (state) => {
    console.log('search:', state);
  }

  /**
  * @memberof FormDepartamentos
  *
  * @method AddState
  * @description Metodo que añade un nuevo pais a las opciones del select y en la bd
  *
  **/
  AddCountry = () => {
    var countrytoSave = this.countryRef.current.state.value;

    if (this.Exist(countrytoSave)) {
      message.error('El pais ingresado ya existe');

    } else {
      axios.post('/countries/add', {
        name: countrytoSave,
      }).then(res => {
        console.log('respuesta al guardar nuevo estado', res);
        this.GetCountries();
      }).catch(error => {
        console.log('error', error.response)
      }).finally(
        this.countryRef.current.state.value = null
      );
    }

  }


  /**
  * @memberof FormDepartamentos
  *
  * @method AddState
  * @description Metodo que añade un nuevo estado a las opciones del select y en la bd
  *
  **/
  AddState = () => {
    var countrytoSave = this.DepartmentForm.current.getFieldValue('pais');
    var statetoSave = this.stateRef.current.state.value;

    axios.post('/states/add', {
      name: statetoSave,
      country_id: countrytoSave
    }).then(res => {
      this.handleChangeCountry(countrytoSave);
    }).catch(error => {
      console.log('error', error.response)
    }).finally(
      this.stateRef.current.state.value = null

    );


  }


  /**
  * @memberof FormDepartamentos
  *
  * @method onChangeEstatus
  * @description Metodo que cambia el valor del switch de Estatus
  * 
  * @param checked  (boolean)
  **/
  onChangeEstatus = (checked) => {
    this.setState({ status: checked });
  }


  /**
 * @memberof FormDepartamento
 *
 * @method UpdateData
 * @description  Envia los datos del formulario al Servidor para actualizar un registro
 *
 * @param values (array)
 * Contiene los campos del formulario para registrar al Departamento 
 *
 * @returns response (array)
 **/
  UpdateData = (values) => {
    console.log('actualizacion de datos', values)
    axios.put("/departamentos/update", {
      id: this.state.id_dep,
      nombre: values.nombre,
      number: values.numero,
      pais_id: values.pais,
      estado_id: values.estado,
      activo: this.state.status,

    },
      { headers: { Authorization: sessionStorage.getItem('token') } })
      .then(res => {
        if(res.data.success) {
          this.setState({ loading: false, info: true, message: "¡Departamento actualizado con éxito!" });
          message.success(this.state.message);
          this.props.UpdateTable();
        }
        else{
          this.setState({ loading: false, info: false});
          message.error("¡Ooops! No se ha podido actualizar el Departamento");  
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, info: false, message: "¡Ooops! No se ha podido actualizar el Departamento" });
        message.error(this.state.message);

      })


  }

  /**
   * @memberof FormDepartamento
   *
   * @method NewData
   * @description  Envia los datos del formulario al Servidor para un nuevo registro
   *
   * @param values (array)
   * Contiene los campos del formulario para registrar al departamento 
   *
   * @returns response (array)
   **/
  NewData = (values) => {
    axios.post("/departamentos/add", {
      nombre: values.nombre,
      number: values.numero,
      pais_id: values.pais,
      estado_id: values.estado,
      activo: this.state.status,

    },
      { headers: { Authorization: sessionStorage.getItem('token') } })
      .then(res => {
        if(res.data.success) {
          this.setState({ loading: false, info: true, message: "!Departamento creado exitosamente!" });
          message.success(this.state.message);
          this.props.UpdateTable();
        }
        else {
          this.setState({ loading: false, info: false, message: "" });
        message.error("¡Ooops! No se ha podido crear el Departamento");

        }
      })
      .catch(error => {
        console.log(error.response);
        this.setState({ loading: false, info: false, message: "¡Ooops! No se ha podido crear el Departamento" });
        message.error(this.state.message);
      })

  }

  /**
   * @memberof FormPerfil
   *
   * @method closeForm
   * @description  Cierra el formulario
   * 
   */
  closeForm = () => {
    this.props.close();
  }


  /**
   * @memberof FormDepartamento
   *
   * @method handleSubmit
   * @description  Envia los datos del formulario al Servidor
   * @if hay un usuario cargado ( es una edicion) manda a llamar al metodo UpdateData
   * @else Es un nuevo registro, manda a llamar al metodo NewData
   *
   * @param values (array)
   * Contiene los campos del formulario para registrar al departamento
   **/
  handleSubmit = (values) => {
    this.setState({ loading: true });

    if (this.state.id_dep.length > 0) {
      this.UpdateData(values);
    }
    else {
      this.NewData(values);
    }

    this.closeForm();

  }

  render() {

    return (
      <Form name="formulario-departamento" onFinish={this.handleSubmit} layout="vertical" className="form-modal" ref={this.DepartmentForm}>
        <Row>
          <Col span={24}>
            <h3 className="modal-title modal-title-sm" style={{ textAlign: 'center' }}>{this.state.nombreVista}</h3>
          </Col>
        </Row>

        <Spin tip="Espere un momento por favor..." spinning={this.state.loading}>
          <Form.Item label="Nombre de Departamento" name="nombre" rules={[{ required: true, message: "Por favor, ingrese el nombre del departamento" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Número de Departamento" name="numero" rules={[{ required: true, message: "Por favor, ingrese el número del departamento" }]}>
            <Input type="number" min={1}/>
          </Form.Item>
          <Form.Item label="País" name="pais" rules={[{ required: true, message: "Por favor, ingrese el país" }]} >
            <Select
              placeholder="Buscar..."
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(search) => this.GetCountries(search)}
              onSelect={(search) => this.GetStateByCountry(search)}
              notFoundContent={<p>No se ha encontrado el País</p>}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                    <Input style={{ flex: 'auto' }} ref={this.countryRef} />
                    <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.AddCountry} >
                      <PlusOutlined /> Añadir
                    </a>
                  </div>
                </div>
              )}
            >
              {this.state.countries.map(function (pais, index) {
                return <Option value={pais._id}>{pais.name}</Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Estado" name="estado" rules={[{ required: true, message: "Por favor, ingrese el estado" }]} >
            <Select
              disabled={this.state.disabled}
              placeholder="Buscar ..."
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={this.searchState}
              onSelect={() => this.stateRef.current.state.value = null}
              notFoundContent={<p>No se ha encontrado el Estado</p>}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                    <Input style={{ flex: 'auto' }} ref={this.stateRef} />
                    <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.AddState} >
                      <PlusOutlined /> Añadir
                    </a>
                  </div>
                </div>
              )}
            >
              {this.state.states.map(function (state, index) {
                return <Option value={state._id}>{state.name}</Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Estatus" name="estatus" >
            <Switch onChange={this.onChangeEstatus} checked={this.state.status} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="v-button-primary">Guardar</Button>
          </Form.Item>
        </Spin>
      </Form >
    )
  }
}