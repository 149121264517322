import React, { Component } from 'react';
import { Button, Col, Modal, Radio, Row, Typography, Carousel, Input, Form, Tooltip, Switch, Image, Empty } from 'antd'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import MinusCircleOutlined from "@ant-design/icons/lib/icons/MinusCircleOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { ArrowLeftOutlined, QuestionCircleOutlined } from "@ant-design/icons";
//css
import '../../../../styles/preguntas.css';


const axios = require("axios").default;
const { Title, Paragraph, Text } = Typography;

export default class ModalPreguntaVisualizar extends Component {

	constructor(props){
		super(props);
		this.state = {
			pregunta: {}
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.pregunta !== undefined && this.props.pregunta !== prevProps.pregunta){
		console.log(this.props.pregunta)
			this.setState({pregunta: this.props.pregunta})
		}
	}

	/**
	* @memberof ModalPreguntaVisualizar
	*
	* @method hideModal
	* @description Cierra el modal
	**/
	hideModal = () => {
		this.props.cleseMethod();
		
	}

	render(){
		const { pregunta } = this.state
		return(
			<Modal 
				visible={this.props.visible}
				width={"800px"}
				onCancel={this.hideModal}
				title={null}
				footer={null}
				className="modal-form crear-encuesta modal-detalleLinea"
				destroyOnClose={true}
				closeIcon={<Button type="ghost" className="closable-button" onClick={this.hideModal} title="Cerrar">
					<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
							fill="#E1E4ED" />
						<path d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
							fill="#E1E4ED" />
					</svg>
				</Button>}>

					<Row>
						<Col span={24} className="center" style={{ textAlign: "center" }}>
							<Title className="center mdl-title"
								style={{ textAlign: "center" }}
							> Linea " {pregunta.nombre ?  pregunta.nombre : pregunta.pregunta} "</Title>
						</Col>

						{pregunta.condicional?.activada ? <>
							<Col className="mt-2 col-condicional"> 
								<div>
									<Text className="text-vianney">Pregunta condicional: </Text>
									<Text className="text-gray">{pregunta.condicional?.texto} </Text>
								</div>
								<div className="pl-1">
									<Text className="text-vianney">Opción Si: </Text>
									<Text className="text-gray"> {pregunta.condicional?.opcion_si} </Text>
								</div>
								<div className="pl-1">
									<Text className="text-vianney">Opción No:</Text>
									<Text className="text-gray"> {pregunta.condicional?.opcion_no} </Text>
								</div>
								
							</Col>
							<hr/>
							</>
						:null}


						<Col span={24}>
							{pregunta.tipo === "image" ?
								pregunta.respuestas?.map((item, i) => {
									let imagenes = item.imagen ? item.imagen : item.imagenes;
									return (
										<Row key={"rowMdlLinea-", i} className="row-linea" style={{ height: 'auto' }}>
											<Text>Set {" ", i}</Text>
											<Row className="row-set" style={{ width: '100%' }}>
												{imagenes.length == 0 ? <Empty width="100%" description="Sin imagenes" style={{ marginLeft: "35%" }} /> : null}

												{imagenes.map((img) => {
													let urlImage = `${axios.defaults.baseURL}/upload/${img.imagen ? img.imagen : img.name}`
													return <Col xs={8} md={6} className="col-set-img">
														<Image
															className="set-img"
															width={150}
															src={urlImage}
															style={{ display: 'block' }}
															/>
														<p style={{ fontSize: '10px', display: 'block' }}>
															<span><strong>Linea:</strong></span>
															<br/>
															<span>{pregunta._id}</span>
															<br/>
															<br/>
															<span><strong>Set:</strong></span>
															<br/>
															<span>{item._id}</span>
															<br/>
															<br/>
															<span><strong>Imagen:</strong></span>
															<br/>
															<span>{img._id}</span>
															<br/>
															<br/>
														</p>
													</Col>
												})}

											</Row>
										</Row>
								   );
								})
								:
								<Row className="row-linea" style={{ height: 'auto', marginTop:"20px" }}>
								  
									<Text style={{width:"100%", color:"#585a5f"}}>¿ {pregunta.pregunta} ?</Text>
									<Text style={{color: '#822c78'}}>Posibles respuestas</Text>
									{pregunta.respuestas?.map((item, i) => {
										return (
											<Row className="row-answer" style={{ width: '100%' }}>
												<Col className="col-answer">
													<Text className="txt-answer">
														{item.descripcion}
													</Text>
												</Col>
											</Row>
										);
									})}
								</Row>
							}
						</Col>
					</Row>
			</Modal>
		)
	}	
}


