import React, { Component } from "react"
import { Layout, Input, Row, Col, Modal, Button, Form as AntdForm, Carousel } from 'antd';


import { Redirect } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

const axios = require('axios').default;
const { Content } = Layout;
class PasswordRecovery extends Component {

    recovery = React.createRef();
    url = new URL(window.location.href);

    constructor(props) {
        super(props)
        this.state = {
            return: false
        }
    }


    async componentDidMount() {
        try {
        
            console.log('-------------------------------------');
            console.log(this.props.match.params);

            let email = this.props.match.params.email;
            if (email === null || this.props.match.params.token === null)
                this.setState({ return: true });

            this.recovery.current.setFieldsValue({
                email: this.props.match.params.email
            });

        } catch (e) {
            console.log('e', e)
        }

    }

    /**
   * @memberof PasswordForgot
   *
   * @method handleSubmit
   * @description  Envia los datos del formulario al Servidor
   *
   * @param values (array)
   * Contiene los campos del formulario para registrar al producto
   *
   * @returns response (array)
   **/
    handleSubmit = value => {
        value.email = this.props.match.params.email;
        value.token = this.props.match.params.token;
        axios.put('/password/recovery/update', value)
            .then(success => {
                console.log(success)
                Modal.success({
                    title: 'Actualizado.',
                    content: 'Se ha actualizado la contraseña',
                    onOk: e => { e(); this.setState({ return: true }) }
                });

            })
            .catch(error => {
                console.log(error.response)
                Modal.warning({
                    title: 'Error',
                    content: 'No es posible actualizar.'
                });
            });
    };
    /**
 * @memberof PasswordForgot
 *
 * @method renderRedirect
 * @description  Activa el redireccionamiento si el formulario se envio con exito
 *
 **/
    renderRedirect = e => ((this.state.return) ? <Redirect to="/login" /> : null);

    render() {
        return (

            <Layout>
                {this.renderRedirect()}
                <Content className="login">
                    <Row className="" align="center" style={{ paddingTop: '20px', width: '100%', heigth: '100%' }}>
                        {this.renderRedirect()}
                        <Col xs={22} md={20} lg={{ span: 8, pull: 1 }} xl={{ span: 6, pull: 1 }} style={{ paddingTop: '30px' }}>

                            <Row style={{ marginBottom: '50px' }}>
                                <Col style={{ margin: '5px' }}>
                                    <img width='100px' src="/images/logo-vianney.png" alt="Vienney logo"/>
                                </Col>
                                <Col style={{ paddingLeft: '5px' }}>
                                    <h1 style={{ fontWeight: 'bold', fontSize: '18pt', marginBottom: '0px' }}>Voz del
                                Cliente</h1>
                                    <label style={{ fontSize: '9pt', color: '#A4A4A4' }}> Ingresa tu nueva contraseña.</label>
                                </Col>
                            </Row>
                            <AntdForm
                                ref={this.recovery}
                                initialValues={{ remember: true }}
                                onFinish={this.handleSubmit}
                                layout={"vertical"} >
                                <AntdForm.Item
                                    name="email"
                                    label="Email"
                                    rules={[{ required: true, message: 'Por favor ingresa tu  correo' }]}
                                >
                                    <Input

                                        prefix={<UserOutlined />}
                                        placeholder="E-mail"
                                        className="input-box"
                                    />
                                </AntdForm.Item>
                                <AntdForm.Item
                                    label="Contraseña"
                                    name="password"
                                    rules={[{ required: true, message: 'Por favor ingresa tu  contraseña' }]}>
                                    <Input
                                        prefix={<LockOutlined />}
                                        type="password"
                                        placeholder="Contraseña"
                                        className="input-box"
                                    />
                                </AntdForm.Item>
                                <AntdForm.Item
                                    name="confirm"
                                    label="Confirmar Contraseña"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Las contraseñas no coinciden.!');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined />}
                                        type="password-confirm"
                                        placeholder="Confirmar Contraseña"
                                        className="input-box"
                                    />
                                </AntdForm.Item>
                                <AntdForm.Item>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="btn btn-primary m-1 center"
                                    >Recuperar</Button>


                                </AntdForm.Item>

                            </AntdForm>


                        </Col>
                        <Col xs={22} md={20} lg={{ span: 10, push: 1 }} xl={{ span: 12, push: 1 }} className="carousel-images">
                            <Carousel autoplay>
                                <img className={"carousel-item"} src="/images/Opcion1.png" alt="Vienney imagen"/>
                                <img className="carousel-item" src="/images/Opcion2.png"  alt="Vienney imagen"/>
                                <img className="carousel-item" src="/images/Opcion3.jpg"  alt="Vienney imagen"/>
                            </Carousel>
                        </Col>
                    </Row>


                </Content>
            </Layout>
            /*
            <div className="login">
                {this.renderRedirect()}
                <Row style={{ height: "100%" }} glutter={[16, 16]}>
                    <Col xs={24} sm={24} md={7} lg={7} xl={7} offset={1} className="bg-gray bg-login">
                        <div className="center pd-2">
                            <img src={"/images/logo-white.svg"} alt="logo" width="200" className=" pd-1"></img>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14} offset={1} pull={1} className="bg-white box-bordered">
                        <Row className="login-form ">
                            <Col span={24}>
                                <h3 className="title-login  center pd-3">Iniciar Sesión</h3>
                            </Col>
                            <Col span={12} offset={6}>
                            </Col>
                        </Row>
                    </Col>
                </Row>


            </div>*/

        )
    }
}

export default PasswordRecovery
