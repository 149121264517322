import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Tag, Badge, Dropdown, Menu, Button, Empty } from 'antd';
import { DownOutlined, ArrowLeftOutlined,  RightOutlined } from '@ant-design/icons';
import Cookies from 'universal-cookie';

import '../../../styles/header.css'

import PropTypes from 'prop-types';

import io from 'socket.io-client';

const axios = require('axios').default

const moment = require('moment')


export default class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: null,


            notifications: [],
            newNotifications: 0
        }
    }


    componentDidMount() {
        let socket = io.connect(axios.defaults.baseURL, { forceNew: true });
        socket.emit("suscripcion", sessionStorage.getItem("token"))

        //suscrito
        socket.on('suscrito', this.IO_suscrito);
        socket.on('notificaciones', this.IO_notificaciones)
        socket.on('notificacion', this.IO_notificacion)
        socket.on('count', this.IO_count)
        this.socket = socket;

    }

    /**
     *
     * @function IO_count
     * @description Permite establecer la cantidad de notificaciones pendientes actual.
     * @param {*} count
     */
    IO_count = (count) => {
        this.setState({
            newNotifications: count
        })
    }


    /**
     *
     * @function IO_suscrito
     * @description Inicio de la conexion. Permite establecer la lista de notificaciones actua;.
     * @param {*} count
     */
    IO_suscrito = ({ suscrito, notifications }) => {
        if (suscrito) {
            
            this.setState({
                notifications,
            })

        }
    }

    /**
     *
     * @function IO_notificaciones
     * @description Inicio de la conexion. Permite establecer la lista de notificaciones actua;.
     * @param {*} data
     */
    IO_notificaciones = (data) => {
        this.setState({
            notifications: data,
        })
    }

    /**
     *
     * @function IO_notificacion
     * @description Inicio de la conexion. Permite establecer la lista de notificaciones actua;.
     * @param {*} notificacion
     * Contiene la estructura de la notificacion extra.
     *
     * co
     */
    IO_notificacion = ({ notificacion }) => {
        
        let notifications = this.state.notifications;
        notifications.push(notificacion)

        this.setState({ notifications })
    }

    enviarLeidos = () =>{

        
        this.socket.emit("leidos",{
            token: sessionStorage.getItem("token"),
            lista: this.state.notifications.map(i=>i._id)
        })

    }

    /**
     * @memberof Navbar
     *
     * @method LogOut
     * @description Limpia las cookies y el sessionStorage, y cambia el valor del state para que sea redirigido al login
     **/
    LogOut = () => {
        const cookies = new Cookies();

        sessionStorage.removeItem('token')
        sessionStorage.removeItem('username')

        cookies.remove('token', { path: '/' })
        cookies.remove('username', { path: '/' })

        this.setState({ redirect: '/login' })
    }

    render() {
        const { props } = this;

        let { notifications, newNotifications } = this.state;

        notifications = notifications.reverse()

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }

        let username = sessionStorage.getItem("username");

        return (
            <div className="header">
                <div className="header-logo">
                    <img className="logo" src={"/images/logo-vianney-morado.png"} alt="Vianney" style={{ height: '40px'}}/>
                </div>
                <div className="header-content">
                    <h2 className="header-title"> {(props.back) ? <Button onClick={props.onBack} type="text"><ArrowLeftOutlined /></Button> : null}{props.title}</h2>

                    <div className="header-user">
                        <Dropdown
                            overlay={
                                <Menu className="notifications">
                                    {(notifications.length > 0) ? notifications.map((element, index) => {
                                        const { objeto_id } = element;

                                        if (objeto_id[0]) {
                                            
                                        let  { tipo, nombre, activo, fecha_inicio, fecha_final } = objeto_id[0];

                                        return <Menu.Item className="notifications-container" key={index} >
                                            <p className="notification-title">{nombre}</p>
                                            <p className="notification-description">{element.evento}</p>
                                            <p className="notification-dates">{moment(fecha_inicio).format("YYYY-MM-DD")} al {moment(fecha_final).format("YYYY-MM-DD")}</p>
                                            <Button className="notification-button" type="primary" icon={<RightOutlined />} />
                                            <Tag className="notification-tag" color={(activo) ? "green" : "gray"}>{(tipo === "voc") ? "VOC" : "Generica"}</Tag>
                                            {(index !== 0) ? <div className="divider" /> : null}
                                        </Menu.Item>
                                        }
                                    }) : <Empty description={"No hay notificaciónes"} />}
                                </Menu>}
                            trigger={['click']}
                            onClick={ this.enviarLeidos}
                            >
                            <Badge count={newNotifications}>
                                    <img src="/iconos/notification.svg" width="28px" style={{color:'#7E3376'}} alt="0"/> 
                                    {/* <BellOutlined style={{ fontSize: "28px", color: "#7E3376" }} /> */}
                            </Badge>
                        </Dropdown>
                        <img className="user-logo" src="/images/usuario.svg" alt="user-logo" width="28px"/>
                        <Dropdown overlay={
                            <Menu>
                                <Menu.Item key="0" onClick={this.LogOut}>Cerrar sesión</Menu.Item>
                            </Menu>} trigger={['click']}>
                            <a className="ant-dropdown-link" href="#" onClick={e => e.preventDefault()}>
                                {username} <DownOutlined />
                            </a>
                        </Dropdown>
                    </div>
                </div>
            </div>
        )
    }
}

Navbar.propTypes = {
    title: PropTypes.string,
    back: PropTypes.bool,
    onBack: PropTypes.func
};

Navbar.defaultProps = {
    back: false,
    title: 'Vianney',
};
