import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { Modal, Row, Col, Card, Typography, Form, Radio, InputNumber, Button, Space, Layout,  message, Carousel, Result } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

//imagen
import Logo from '../../../assets/images/logo-panel.png'

//css 
import '../../../styles/encuestaExterna.css'

const axios = require("axios").default;
const { Title,  Text } = Typography;
const { Content } = Layout;

export default class EncuestaExterna extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: null,
			message: null,
			encuesta: {
				preguntas: []
			},
			preguntas: [],
			numHijos: 0,
			finish: false,
			genero_encuestado: 0, //hombre valor inicial
		}
	}

	/**
	* @memberof AnalyticsEncuestas
	*
	* @method componentDidMount
	* @description Trae la info de la encuesta
	**/
	componentDidMount() {
		const { id } = this.props.match.params
		this.getEncuesta(id);
	}


	/**
	 * @methodOf AnalyticsEncuestas
	 * @function getEncuesta
	 *
	 * @description trae la infomacion de la encuesta por medio del id que se pasa por la url
	 *
	 * */
	getEncuesta = async (id) => {
		this.setState({ loadingEncuesta: true })
		axios.get('/encuestas/id', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				id: id,
				imagenes_info: true,
			}
		}).then(async (response) => {
			const data = response.data.data;
	
			console.log(data)


			if (data.activo == true){
				this.setState({ encuesta: data, preguntas: data.preguntas.map(preg=>{
					if(preg.condicional?.activada) preg.show=true
					else preg.show = false 
					return preg
				})})
			}else 
				Modal.error({
					title: "Esta encuesta está cerrada",
					onOk: () => this.setState({ redirect: true })

				})
		}).then(() => {
			this.setState({ loadingEncuesta: false })
		}).catch((error) => {
			console.log(error)
			Modal.error({
				title: "Error al cargar la encuesta, intentelo más tarde.",
				onOk: () => this.setState({ redirect: true })
			})
		})
	}

	/**
	 * @methodOf AnalyticsEncuestas
	 * @function onFinish
	 *
	 * @description Metodo de que se llama al termianr de responder la encuesta
	 * */
	onFinish = (values) => {
		values.encuesta_id = this.state.encuesta._id;
		this.saveEncuesta(values);
	}


	/**
	 * @methodOf EncuestaExterna
	 * @function saveEncuesta
	 *
	 * @description Guarda los datos generales del usuario y de la encuesta en la coleccion de resultados
	 *
	 * */
	saveEncuesta = async (values) => {
		axios.post("/resultado-respuesta", {
			encuesta_id: values.encuesta_id,
			// preguntas: 
			edad: values.edad,
			sexo: values.sexo,
			adi: false,
			hijos: this.state.numHijos,
			hijos_info: values.hijos_info,
			//user: id
			resultados: values.resultados,
		},
			{
				headers: {
					Authorization: sessionStorage.getItem('token')
				}
			})
			.then(res => {
				this.setState({ loading: false, message: "Se guardo el resultado general", finish: true });
				message.success(this.state.message);

			})
			.catch(error => {
				this.setState({ loading: false, info: false, message: "No se ha podido guardar el resultado general" });
				console.log(error)
				message.error(this.state.message);
			})
	}

	addHijo = () => {
		this.setState({ numHijos: this.state.numHijos + 1 })
	}

	removeHijo = () => {
		this.setState({ numHijos: this.state.numHijos - 1 })
	}

	redirect = () => (this.state.redirect === true) ? <Redirect to="/login" /> : null

	/**
	 * @methodOf EncuestaExterna
	 * @function checkGender
	 *
	 * @description Compara el genero de la pregunta con el del encustado y si coninciden retorna true, para visualizar la pregunta
	 *
	 * */
	checkGender = (genero) => {
		const {genero_encuestado}=this.state
		if(genero > 1) return true
		if(genero === genero_encuestado) return true
		else return false
	}

	render() {

		const { encuesta, numHijos, preguntas } = this.state

		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px',
		};


		return (
			<div>
				<div className="header center">
					<div className="header-logo-s">
						<img className="logo" src={Logo} alt="Vianney" />
					</div>
				</div>
				{this.redirect()}
				<Content className="page-main">
					{
						this.state.finish === true ? <div>
							<Result
								status="success"
								title="¡Éxito! Has completado la encuesta correctamente"
								subTitle="Cierra la ventana para salir."

							/>
						</div>
							:
							<Form
								scrollToFirstError={true}
								layout="vertical"
								onFinish={this.onFinish}
								initialValues={{ edad: 18, hijos: this.state.numHijos, sexo: true, encuesta_id: this.state.encuesta._id }}
							>
								<Row>
									<Col xs={24} lg={{ span: 14, offset: 4 }} xl={{ span: 12, offset: 6 }}>
										<Card bordered={false} className="mt-2">
											<Title level={2} type="secondary" className=" text-center">{encuesta.nombre}</Title>
											
											<Row className="center m-3">
												<Col span={24} className="center">
													<Text className="text-center">Infomación Personal</Text>
												</Col>
												<Col span={24} className="center">
													<Text className="text-center" level={2} strong>{encuesta.descripcion}</Text>
												</Col>
											</Row>


											<Row className="m-3">
												<Col className="" xs={24} md={{ span: 18, offset: 3 }}>
													<Form.Item
														label="Edad"
														name="edad"
														rules={
															[{
																required: true,
																message: "Ingrese su edad",
															}]}
													>
														<InputNumber className="v-input-number" style={{ width: '100%' }} max={150} />
													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col className="" xs={24} md={{ span: 18, offset: 3 }}>
													<Form.Item
														label="Sexo"
														name="sexo"
														rules={
															[{
																required: true,
																message: "Seleccione su sexo",
															}]}
													>

														<Radio.Group className="radio-btns" onChange={e=>{
															this.setState({genero_encuestado: e.target.value ? 0 : 1})}}>
															<Radio value={true}>Hombre</Radio>
															<Radio value={false}>Mujer</Radio>
														</Radio.Group>

													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col className="" xs={24} md={{ span: 18, offset: 3 }}>
													<Form.Item
														label="Hijos"
														name="hijos"
													>
														{this.state.numHijos}

													</Form.Item>
												</Col>
											</Row>
											<Row>
												<Col xs={24} md={{ span: 18, offset: 3 }}>
													<Form.List name="hijos_info">

														{(fields, { add, remove }) => {
															return (
																<div>
																	{fields.map((field, index) => (
																		<Space className="hijo-row" key={field.key}>
																			<Text type="secondary">hijo {index + 1}</Text>
																			<Form.Item
																				{...field}
																				label="Sexo"
																				className="hijo-field"
																				name={[field.name, 'sexo']}
																				fieldKey={[field.fieldKey, 'sexo']}
																			>
																				<Radio.Group>
																					<Radio style={radioStyle} value={true}>Hombre</Radio>
																					<Radio style={radioStyle} value={false}>Mujer</Radio>
																				</Radio.Group>
																			</Form.Item>
																			<Form.Item
																				{...field}
																				className="hijo-field"
																				label="Edad"
																				name={[field.name, 'edad']}
																				fieldKey={[field.fieldKey, 'edad']}
																				rules={[{ required: true, message: '' }]}
																			>
																				<InputNumber
																					className="v-input-number" style={{ width: '100%' }}
																				/>
																			</Form.Item>

																			<MinusCircleOutlined
																				onClick={() => {
																					remove(field.name);
																					this.removeHijo();
																				}}
																			/>
																		</Space>))}

																	<Form.Item>
																		<Button type="dashed" onClick={() => { add(); this.addHijo(); }} block >
																			<PlusOutlined /> Añadir hijos
															</Button>
																	</Form.Item>
																</div>
															);
														}}
													</Form.List>
												</Col>
											</Row>
										</Card>
									</Col>
								</Row>

								{/*Despliega las preguntas con sus respuestas*/}
								<Row>
									<Col xs={24} lg={{ span: 14, offset: 4 }} xl={{ span: 12, offset: 6 }}>
										<Form.List name="resultados">
											{fields => (
												<div>
													{preguntas.map((pregunta, index) => {

														{/*preguntas tipo Texto*/ }
														if (pregunta.tipo === 'text' && this.checkGender(pregunta.genero)) {
															return <PreguntaTexto
																pregunta={pregunta}
																index={index}
																encuesta={encuesta}
															/>
														}

														/*pregunta tipo imagen y enceusta tipo general*/
														if (pregunta.tipo === 'image' && encuesta.tipo === 'general' && this.checkGender(pregunta.genero)) {
															return <PreguntaImagen
																pregunta={pregunta}
																index={index}
																encuesta={encuesta}
															/>
														}
													})}
												</div>
											)}
										</Form.List>
									</Col>
								</Row>
								<Form.Item>
									<Row>
										<Col className="center" xs={24} lg={{ span: 14, offset: 4 }} xl={{ span: 12, offset: 6 }}>
											<Button type="primary" htmlType="submit" className="v-button-primary mt-3 mb-3">
												Guardar
								</Button>
										</Col>
									</Row>
								</Form.Item>
							</Form>

					}
				</Content>
			</div>
		)
	}
}

/*
*
* Component: PreguntaTexto
* descripcion: Componente que renderiza la pregunta de tipo texto
*
**/
class PreguntaTexto extends Component{
	constructor(props){
		super(props);
		this.state = {
			pregunta: this.props.pregunta,
			index: this.props.index,
			encuesta: this.props.encuesta,
		}
	}

	render(){
		const {pregunta, index, encuesta} = this.state;
		return(
			<Card className="card-respuesta mt-2">
				{
					pregunta.show ? <div className="modal-question">
							<div>{pregunta.condicional.texto}</div>
							<div>
								<Radio.Group className="radio-btns" defaultValue={true} onChange={(e)=>{

									if(e.target.value === false){
										this.setState({pregunta: {...this.state.pregunta,show:false}})
									}
								}}>
									<Radio value={false}>{pregunta.condicional.opcion_si}</Radio>
									<Radio value={true}>{pregunta.condicional.opcion_no}</Radio>
								</Radio.Group>
							</div>
					</div>
					:null
				}
				<Row>
					<Col className={`${pregunta.show ? 'filler' : ''}`} xs={24} md={{ span: 18, offset: 3 }}>
						<h4 className="">{pregunta.numero + 1} - {pregunta.pregunta}</h4>
						<h4 className="mb-2">{pregunta.nombre}</h4>
						<Form.Item
							className="opc-radio"
							name={[index]}
							rules={
								[{
									required: !pregunta.show,
									message: "Seleccione una opcion",
								}]}
						>
							<Radio.Group className="radio-group">
								{pregunta.respuestas.map(respuesta => {
									return <Radio
										className="radio-encuesta"
										value={{
											encuesta_id: encuesta._id,
											pregunta: { _id: pregunta._id },
											respuesta: { _id: respuesta._id },
										}}
									>
										{respuesta.descripcion}
									</Radio>
								})
								}
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
			</Card>
		)
	}
}


/*
*
* Component: PreguntaImagen
* descripcion: Componente que renderiza la pregunta de tipo Imagen
*
**/
class PreguntaImagen extends Component{
	constructor(props){
		super(props)
		this.state = {
			pregunta: this.props.pregunta,
			index: this.props.index,
			encuesta: this.props.encuesta,
		}
	}
	render(){
		const {pregunta, index, encuesta} = this.state;
		return(
			<Card className="card-respuesta mt-2">
				{
					pregunta.show ? <div className="modal-question">
							<div>{pregunta.condicional.texto}</div>
							<div>
								<Radio.Group className="radio-btns" defaultValue={true} onChange={(e)=>{

									if(e.target.value === false){
										this.setState({pregunta: {...this.state.pregunta,show:false}})
									}
								}}>
									<Radio value={false}>{pregunta.condicional.opcion_si}</Radio>
									<Radio value={true}>{pregunta.condicional.opcion_no}</Radio>
								</Radio.Group>
							</div>
					</div>
					:null
				}
				<Row>
					<Col className={`${pregunta.show ? 'filler' : ''}`} xs={24} md={{ span: 18, offset: 3 }}>
						<h4 className="mb-2">{pregunta.numero + 1} - {pregunta.nombre}</h4>
						<Form.Item
							className="opc-radio"
							name={[index]}
							rules={
								[{
									required: 	!pregunta.show,
									message: "Seleccione una opcion",
								}]}
						>
							<Radio.Group className="radio-group">
								{pregunta.respuestas.map((respuesta, index) => {
									return (
										<Row className="mb-1">
											<Col xl={{ span: 16, offset: 2 }}>
												<Carousel autoplay className="carousel-encuesta">
													{respuesta.imagen.map(imagen => {
														let urlImage = `${axios.defaults.baseURL}/upload/${imagen.imagen}`
														return <div>
															<h1
																className="carousel-image-encuesta"
																style={{
																	backgroundImage: `url(${urlImage})`
																}}></h1>
														</div>
													})}
												</Carousel>
											</Col>
											<Col xl={{ span: 3, offset: 1 }} className="center">
												<Radio
													className="radio-images"
													value={{
														encuesta_id: encuesta._id,
														pregunta: { _id: pregunta._id },
														respuesta: { _id: respuesta._id }
													}}
												>
													{index + 1} )
												</Radio>
											</Col>
										</Row>
									)
								})}
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
			</Card>
		)
	}
}
