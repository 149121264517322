import React, { Component } from "react";
import { Row, Col, Button, Form, Input, message, Modal, Select, Spin, Switch,  Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const axios = require('axios').default;

const { Option } = Select;


/**
 * @class ModalCDN
 * @description  Clase que contiene el modal para crear/editat un centro de Negocio
 *
 **/
class ModalCDN extends Component {

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,
            accionModal: '',
            estados: [],
            paises: [],
            datosConsultados: false,

            disabled: true
        }
    }

    //Referencias 
    modalCN = React.createRef();
    countryRef = React.createRef();
    stateRef = React.createRef();


    /**
     * @memberof ModalCDN
     *
     * @method componentDidMount
     * Carga de paises al abrir el formulario
     **/
    componentDidMount() {
        this.getCountries();
    }


    /**
    * @memberof modalCDN
    *
    * @method LoadDataDep
    * @description Metodo que envia una solicitud al servidor para cargar los datos de un CDMN
    */
    LoadDataCN = () => {
        const { registroId } = this.props;
        if (!this.state.datosConsultados) {
            axios({
                method: 'post',
                url: '/centroNegocio/id',
                headers: { Authorization: sessionStorage.getItem('token') },
                data: { id: registroId }
            })
                .then((res) => {
                    if (res.status === 200) {
                        var data = res.data.data;
                        if (this.modalCN.current !== undefined) {
                            this.modalCN.current.setFieldsValue({
                                nombre: data.nombre,
                                numero: data.numero,
                                pais: data.pais_id,
                                estatus: data.activo
                            });

                            this.GetStateByCountry(data.pais_id);

                            this.modalCN.current.setFieldsValue({
                                estado: data.estado_id
                            });
                        }
                    }
                    this.setState({
                        spinning: false,
                        datosConsultados: true
                    })
                })
                .catch((error) => {
                    console.log(error.response);
                    message.error("¡Ooops! NO se pudo cargar la informacion del Centro de Negocio");
                });
        }
    }

    /**
    * @memberof ModalCDN
    *
    * @method GetCountries
    * @description Metodo que manda a llamar a los paises y guardarlos en el state.countries
    *
    **/
    getCountries = (search) => {
        axios.get('/countries', {
            params: { search: search }
        })
            .then((res) => {
                this.setState({ paises: res.data.data });
            })
            .catch((error) => {
                console.log(error.response);
            });
    }

    /**
  * @memberof modalCDN
  *
  * @method GetStateByCountry
  * @description  Al cambiar el pais seleccionado, se actualizan el state.states para el  select estados
  *
  **/
    GetStateByCountry = value => {

        this.modalCN.current.setFieldsValue({ estado: "" });

        axios.post('/statesByCountry', { pais_id: value },
            { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                var pais_estados = res.data.data;
                this.setState({ estados: pais_estados, disabled: false });
            })
            .catch((error) => {
                console.log(error.response)
            });
    }


    /**
   * @memberof ModalCDN
   *
   * @method handleChangeCountry
   * @description  Al cambiar el pais seleccionado se manda a l lamar al GetStateByCountry para la asignacion de los estados
   *
   **/
    handleChangeCountry = (country) => {
        this.modalCN.current.setFieldsValue({ estado: "" });
        this.GetStateByCountry(country);
    }
    /**
     * @memberof ModalCDN
     *
     * @method searchState
     * @description  Filtro de los estados en el input search
     *
     **/
    searchState = (state) => {
        //console.log('search:', state);
    }


    /**
  * @memberof FormDepartamentos
  *
  * @method AddState
  * @description Metodo que añade un nuevo pais a las opciones del select y en la bd
  *
  **/
    AddCountry = () => {
        var countrytoSave = this.countryRef.current.state.value;

        axios.post('/countries/add', {
            name: countrytoSave,
        }).then(res => {
            this.getCountries();
        }).catch(error => {
            console.log('error', error.response)
        }).finally(
            this.countryRef.current.state.value = null
        );


    }


    /**
  * @memberof FormDepartamentos
  *
  * @method AddState
  * @description Metodo que añade un nuevo estado a las opciones del select y en la bd
  *
  **/
    AddState = () => {
        var countrytoSave = this.modalCN.current.getFieldValue('pais');
        var statetoSave = this.stateRef.current.state.value;

        axios.post('/states/add', {
            name: statetoSave,
            country_id: countrytoSave
        }).then(res => {
            this.handleChangeCountry(countrytoSave);
        }).catch(error => {
            console.log('error', error.response)
        }).finally(
            this.stateRef.current.state.value = null

        );


    }



    /**
     * @memberof ModalCDN
     *
     * @method Update
     * @description  Envia los datos del formulario al Servidor para actualizar registro
     *
     * @param values (array)
     * Contiene los campos del formulario para registrar al departamento 
     *
     * @returns response (array)
     **/
    UpdateData = (values) => {
        const { registroId } = this.props;

        axios({
            method: 'put',
            url: '/centroNegocio/update',
            headers: { Authorization: sessionStorage.getItem('token') },
            data: {
                id: registroId,
                nombre: values.nombre,
                numero: values.numero,
                pais_id: values.pais,
                estado_id: values.estado,
                activo: values.estatus
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success("¡Centro de Negocio actualizado con éxito!");
                    setTimeout(() => {
                        this.hideModal()
                    }, 250);
                }
                else {
                    message.error("¡Ooops! No se ha podido actualizar el Centro de Negocio");
                }
            })
            .catch((error) => {
                console.log(error);
                message.error("¡Ooops! No se ha podido actualizar el Centro de Negocio");
            });

    }


    /**
     * @memberof ModalCDN
     *
     * @method NewData
     * @description  Envia los datos del formulario al Servidor para un nuevo registro
     *
     * @param values (array)
     * Contiene los campos del formulario para registrar al departamento 
     *
     * @returns response (array)
     **/
    NewData = (values) => {

        axios({
            method: 'post',
            url: '/centroNegocio/add',
            headers: { Authorization: sessionStorage.getItem('token') },
            data: {
                nombre: values.nombre,
                numero: values.numero,
                pais_id: values.pais,
                estado_id: values.estado,
                activo: values.estatus
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success("!Centro de Negocio creado exitosamente!");
                    this.limpiarCamposModal();

                    setTimeout(() => {
                        this.hideModal()
                    }, 250);
                }
                else {
                    message.error("¡Ooops! No se ha podido crear Centro de Negocio");

                }
            })
            .catch((error) => {
                console.log(error.response);
                message.error("¡Ooops! No se ha podido crear Centro de Negocio");
            });
    }


    /**
    * @memberof ModalCDN
    *
    * @method hideModal
    * @description  Cierra el modal
    * 
    */
    hideModal = () => {
        this.setState({ datosConsultados: false })
        this.props.closeMethod();
    }

    /**
     * @memberof ModalCDN
     *
     * @method limpiarCamposModal
     * @description  Limpiar campos del modal
     *
     **/
    limpiarCamposModal = () => {
        if (this.modalCN.current !== undefined) {
            this.modalCN.current.setFieldsValue({
                nombre: '',
                numero: '',
                pais: '',
                estado: '',
                estatus: true
            });
        }

    }

    /**
     * @memberof modalCN
     *
     * @method handleSubmit
     * @description  Envia los datos del formulario al Servidor
     * @if hay un usuario cargado ( es una edicion) manda a llamar al metodo UpdateData
     * @else Es un nuevo registro, manda a llamar al metodo NewData
     *
     * @param values (array)
     * Contiene los campos del formulario para registrar al centro de Negocios
     **/
    handleSubmit = values => {

        const { accionModal } = this.props;

        if (accionModal === "crear") {
            this.NewData(values);
        } else if (accionModal === "editar") {
            this.UpdateData(values);

        }
        this.hideModal();
    }
    render() {
        const { modalVisible } = this.props;
        const { accionModal } = this.props;
        const { registroId } = this.props;

        if (accionModal === "editar" && registroId !== undefined && registroId !== "" && (!this.state.datosConsultados)) {

            this.LoadDataCN();
        }


        return (
            <Modal
                visible={modalVisible}
                onCancel={this.hideModal}
                title={null}
                footer={null}
                className="modal-form"
                destroyOnClose={true}
                closeIcon={<Button type="ghost" className="closable-button" onClick={this.hideModal}>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
                            fill="#E1E4ED" />
                        <path d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
                            fill="#E1E4ED" />
                    </svg>
                </Button>}
            >
                <h3 className="modal-title modal-title-sm"> {(accionModal === "crear") ? "Crear" : "Editar"} Centro de Negocios </h3>


                <Spin spinning={this.state.spinning}>
                    <Form
                        name="formulario-centroNegocios"
                        onFinish={this.handleSubmit}
                        layout="vertical"
                        ref={this.modalCN}
                        className="form-modal">

                        <Row>
                            <Col xs={{ span: 20, offset: 2 }} sm={{ span: 16, offset: 4 }}>
                                <Form.Item label="Nombre de Centro de Negocios" name="nombre" className="v-label-gray" rules={[{ required: true, message: "Por favor, ingrese el nombre de Centro de Negocios" }]} >
                                    <Input type="text" className="v-input" />
                                </Form.Item>

                                <Form.Item label="Número de Centro de Negocios" name="numero" className="v-label-gray" rules={[{ required: true, message: "Por favor, ingrese el número de Centro de Negocios" }]}>
                                    <Input className="v-input-number" />
                                </Form.Item>

                                <Form.Item label="País" name="pais" className="v-label-gray" rules={[{ required: true, message: "Por favor, seleccione el país" }]}>

                                    <Select
                                        placeholder="Buscar.."
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={(search) => this.getCountries(search)}
                                        onSelect={(e) => this.GetStateByCountry(e)}
                                        notFoundContent={<p>No se encontro el País</p>}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} ref={this.countryRef} />
                                                    <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.AddCountry} >
                                                        <PlusOutlined /> Añadir
                                                </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {this.state.paises.map(function (pais, index) {
                                            return <Option value={pais._id}>{pais.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Estado" name="estado" rules={[{ required: true, message: "Por favor, seleccione el estado" }]} className="v-label-gray" >
                                    <Select
                                        disabled={this.state.disabled}
                                        placeholder="Buscar..."
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        notFoundContent={<p>No se ha encontrado el Estado</p>}
                                        onSearch={this.searchState}
                                        onSelect={() => this.stateRef.current.state.value = null}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} ref={this.stateRef} />
                                                    <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }} onClick={this.AddState} >
                                                        <PlusOutlined /> Añadir
                                                </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {this.state.estados.map(function (edo, index) {
                                            return <Option value={edo._id}>{edo.name}</Option>
                                        })}
                                    </Select>
                                </Form.Item>

                                <Form.Item label="Estatus" name="estatus" rules={[{ required: true, message: "Por favor, ingrese el estado" }]} valuePropName="checked" initialValue={true} className="v-label-gray">
                                    <Switch />
                                </Form.Item>

                                <Form.Item>
                                    <Button htmlType="submit" type="primary" className="v-button-primary">
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}




export default ModalCDN;
