import React, {Component} from 'react'
import {Route, Switch, Redirect} from "react-router-dom";
import {Layout} from 'antd';
import Cookies from 'universal-cookie';


// import Layout from "../components/Admin/Layout";
import Navbar from "../components/Admin/Header/Header";
import SideBar from "../components/Admin/Sidebar/SideBar";

import CentroDeNegocio from "../components/Admin/CentroNegocios/CentroDeNegocios"


// componentes
import Dashboard from "../components/Admin/Dashboard";
import Perfiles from "../components/Admin/Perfiles/Perfiles"
import Usuarios from "../components/Admin/Usuarios/Usuarios"
import Encuestas from "../components/Admin/Encuestas/Encuestas"
import Analytics from "../components/Admin/Analytics/Analytics"
import Reportes from "../components/Admin/Analytics/Reportes"

import Preguntas from "../components/Admin/Encuestas/FormularioEncuesta/Preguntas"
import Targets from "../components/Admin/Targets/Targets";
import Departamentos from "../components/Admin/Departamentos/Departamentos";
import AnalyticEncuesta from "../components/Admin/Encuestas/AnalyticsEncuestas/AnalyticsEncuestas"

import PreguntaImagen from "../components/Admin/Encuestas/FormularioEncuesta/PreguntaImagen";


//css
import '../styles/admin.css'

const {Content} = Layout;
export default class AdminRoutes extends Component{

	state = {
		title: "Dashboard",
		back: false
	};

    

	/**
	 * Actualiza la información de la barra de Navegación
	 * */
	updateNavigationInformation = (pageTitle, back = false, onBackPressed = () => {}) => {
		this.onBack = onBackPressed;
		this.setState({
			title: pageTitle,
			back: back
		})
    };
    
	onBack = () => {};

	render() {

		const cookies = new Cookies();
		const username = cookies.get('username');
		const userNombre = cookies.get('userNombre');

		const token = cookies.get('token');

		const {title, back} = this.state;
		const {updateNavigationInformation} = this;

		//si no hay datos en las coookies redirige al login
		if(!username && !token){
			return <Redirect to="/login" />;
		}else{
			sessionStorage.setItem('token', token) // para las peticiones al server por axios
		}

		return (
			<Layout className="layout">
				<Navbar
					title={title}
					back={back}
					onBack={this.onBack}
					username={userNombre}
				/>
				<Layout>
					<SideBar/>
					<Content className="content">
						<Switch>
							{/*

								El metodo render debe de retornar un parametro con el componente.
								Lo que hago aqui, las propiedades que retonra el componente "Route", las combino con las propiedades del componente
								dashboard, y despues agrego el updateNavbarInfomration metod, para que pueda ser llamado desde donde sea.
							*/}
							<Route exact path="/admin/dashboard"
								   render={(props)=> <Dashboard  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

							<Route exact path="/admin/centronegocio"
								   render={(props)=> <CentroDeNegocio  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

							<Route exact path="/admin/perfiles"
								   render={(props)=> <Perfiles  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

							<Route exact path="/admin/usuarios"
								   render={(props)=> <Usuarios  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

							<Route exact path="/admin/targets"
								   render={(props)=> <Targets  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

							<Route  path="/admin/encuestas/pregunta"
								   render={(props)=> <PreguntaImagen  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

							{/*	Como parametro, tiene que enviarse el ID o el tipo de encuesta. De no enviar ningun, se tomará como nueva */}
							<Route path="/admin/encuestas/:var"
								   render={(props)=> <Preguntas  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

							<Route path="/admin/ver-encuesta/:id"
								   render={(props)=> <AnalyticEncuesta  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

							<Route exact path="/admin/encuestas"
								   render={(props)=> <Encuestas  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

							<Route exact path="/admin/departamentos"
								   render={(props)=> <Departamentos  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

							<Route exact path="/admin/analytics"
								   render={(props)=> <Analytics  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

							<Route exact path="/admin/analytics/reportes"
								   render={(props)=> <Reportes  {...props} updateNavigationInformation={updateNavigationInformation}  />}/>

						</Switch>
					</Content>
				</Layout>
			</Layout>
		)
	}
}

