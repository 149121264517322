import React, { Component } from "react";
import { Row, Col, Radio, InputNumber, Button, Form, Input, Spin, message } from 'antd';
import '../../../styles/Modals/inputs.css'

const axios = require("axios").default;

/**
 * @class FormPerfil
 * @description  Clase que contiene el formulario (form) de creacion-edicion de Perfil
 *
 **/
class FormPerfil extends Component {


    constructor(props) {
        super(props)
        this.state = {
            nombreVista: 'Editar Perfil',
            loading: false,

            /**Mensajes del modal de success y error */
            info: null,
            message: '',

            /**Datos del perfil */
            profile: [],
            id_profile: ''
        }

        this.ProfileForm = React.createRef();

    }

    /**
     * @memberof FormUsuarios
     *
     * @method componentDidMount
     * @description actualiza los datos de la vista segun si existe o no un perfil previamente seleccionado
     * @if la ruta tiene un parametro (id) manda a llamar a loadData para cargar los datos en el state.profiles
     * @else Asigna a la vista el nombre de Crear perfil
     *
     **/
    componentDidMount() {

        axios.defaults.headers.get['Authorization'] = sessionStorage.getItem('token');

        var pid = this.props.id_profile
        if (pid !== undefined && pid !== null && pid !== '') {
            this.LoadData(pid);
        }
        else {
            this.setState({ nombreVista: this.props.title, loading: false });
        }
    }



    /**
     * @memberof FormPerfil
     *
     * @method LoadData
     * @description  Si es una edicion de perfil se manda una peticion para traer la informacion del servidor
     *
     **/
    LoadData(id) {
        this.setState({ loading: true });
        axios.post('/perfiles/id', { id: id },
            { headers: { Authorization: sessionStorage.getItem('token') } })
            .then((response) => {
                this.setState({ profile: response.data.data, id_profile: id, loading: false });

                this.ProfileForm.current.setFieldsValue({
                    nombre: response.data.data.nombre,
                    edad_min: response.data.data.edad_minima,
                    edad_max: response.data.data.edad_maxima,
                    adi: response.data.data.adi,
                    sexo: response.data.data.sexo,
                    hijos: response.data.data.hijo,
                });

            })
            .catch((error) => {
                console.log("error", error.response);
                if (error.status >= 300) {
                    message.error("¡Ooops! NO se pudo cargar la informacion del usuario");
                }
            });
        this.setState({ nombreVista: 'Editar Perfil' });

    }

    /**
     * @memberof FormPerfil
     *
     * @method UpdateData
     * @description  Envia los datos del formulario al Servidor para actualizar un registro
     *
     * @param values (array)
     * Contiene los campos del formulario para registrar al perfil 
     *
     * @returns response (array)
     **/
    UpdateData = (values) => {
        axios.put("/perfiles/update", {
            id: this.state.id_profile,
            nombre: values.nombre,
            edad_minima: parseInt(values.edad_min),
            edad_maxima: parseInt(values.edad_max),
            sexo: values.sexo,
            hijo: values.hijos,
            adi: values.adi,

        },
            { headers: { Authorization: sessionStorage.getItem('token') } })
            .then(res => {
                if (res.data.success) {
                    this.setState({ loading: false, info: true, message: "¡Perfil actualizado con éxito!" });
                    this.props.UpdateTable();
                    message.success(this.state.message);
                }
                else {
                    this.setState({ loading: false, info: false, message: "" });
                    message.error("¡Ooops! No se ha podido actualizar el perfil");
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, info: false, message: "¡Ooops! No se ha podido actualizar el perfil" });
                message.error(this.state.message);
            })

    }

    /**
     * @memberof FormPerfil
     *
     * @method NewData
     * @description  Envia los datos del formulario al Servidor para un nuevo registro
     *
     * @param values (array)
     * Contiene los campos del formulario para registrar al usuario 
     *
     * @returns response (array)
     **/
    NewData = (values) => {
        axios.post("/perfiles/crear", {
            nombre: values.nombre,
            // id_encuesta         : null,
            edad_minima: values.edad_min,
            edad_maxima: values.edad_max,
            sexo: values.sexo,
            hijo: values.hijos,
            adi: values.adi,
            // perfil_categoria_id : null
        },
            { headers: { Authorization: sessionStorage.getItem('token') } })
            .then(res => {

                if (res.data.success) {
                    this.setState({ loading: false, info: true, message: "Perfil creado ¡exitosamente!" });
                    message.success(this.state.message);
                    this.props.UpdateTable();
                }
                else {
                    this.setState({ loading: false, info: false, message: "" });
                    message.error("¡Ooops! No se ha podido crear el Perfil");
                }
            })
            .catch(error => {
                this.setState({ loading: false, info: false, message: "¡Ooops! No se ha podido crear el Perfil" });
                message.error(this.state.message);
            })

    }




    /**
    * @memberof FormPerfil
    *
    * @method closeForm
    * @description  Cierra el formulario
    * 
    */
    closeForm = () => {
        this.props.close();
    }

    /**
    * @memberof FormPerfil
    *
    * @method handleSubmit
    * @description  Envia los datos del formulario al Servidor
    * @if hay un usuario cargado ( es una edicion) manda a llamar al metodo UpdateData
    * @else Es un nuevo registro, manda a llamar al metodo NewData
    *
    * @param values (array)
    * Contiene los campos del formulario para registrar al usuario
    **/
    handleSubmit = values => {
        this.setState({ loading: true });

        if (this.state.id_profile.length > 0) {
            this.UpdateData(values);
        }
        else {
            this.NewData(values);
        }
        this.closeForm();
    }

    render() {
        return (
            <Form layout={"vertical"} onFinish={this.handleSubmit} className="form-modal" ref={this.ProfileForm}>
                <Row>
                    <Col span={24}>
                        <h3 className="modal-title" style={{ textAlign: 'center' }}>{this.props.title}</h3>
                    </Col>
                </Row>
                <Spin tip="Espere un momento por favor..." spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col span={24} >
                            <Form.Item name="nombre" rules={[{ required: true, message: 'Por favor escribe un nombre para el perfil' }]} >
                                <Input type="text" className="v-input" ></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row >
                        <Col span={12} offset={6} >
                            <Form.Item label="Rango de Edad" className="v-label-gray" style={{ padding: 0, margin: 0 }}></Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item name="edad_min" >
                                <InputNumber min={0} max={100} className="v-input-number" />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item label="Hasta" className="v-input-label "></Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="edad_max" >
                                <InputNumber min={0} max={100} className="v-input-number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Sexo" name="sexo" className="v-label-gray">
                                <Radio.Group buttonStyle="solid" className="v-input-checkbox flex-column-sm">
                                    <Radio.Button value={true}>Masculino</Radio.Button>
                                    <Radio.Button value={false}>Femenino</Radio.Button>
                                    <Radio.Button value={null}>No Aplica</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Contempla Hijos" name="hijos" className="v-label-gray">
                                <Radio.Group buttonStyle="solid" className="v-input-checkbox">
                                    <Radio.Button value={true}>Sí</Radio.Button>
                                    <Radio.Button value={false}>No</Radio.Button>
                                    <Radio.Button value={null}>No Aplica</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="ADI" name="adi" className="v-label-gray">
                                <Radio.Group buttonStyle="solid" className="v-input-checkbox">
                                    <Radio.Button value={true}>Sí</Radio.Button>
                                    <Radio.Button value={false}>No</Radio.Button>
                                    <Radio.Button value={null}>No Aplica</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="submit" className="v-button-primary" htmlType="submit" >Guardar</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>

        )
    }
}
export { FormPerfil }
