import React, { Component } from 'react'
import { Button, Descriptions, Col, Form,  Row, Select, Spin, Typography, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const axios = require("axios").default;
const { Option } = Select;
const { Text } = Typography;

export default class ModalSendReport extends Component {

	constructor(props) {
		super(props)

		this.state = {
			loading: false,
			reports: [],
			users: {
				data: [],
				page: 1,
				quantity: 0,
				limit: 8,
				search: "",
				loading: false,
			},

		}
	}

	/**
	* @memberof ModalSendReport
	*
	* @method componentDidMount
	* @description Carga los primeros 10 usuarios en el select del modal
	**/
	componentDidMount() {
		const { users } = this.state;
		console.log('informacion de usuarios', users);

		var id = this.props.encuesta;
		console.log('props', id);
		this.LoadData(id);
		this.getDataPagination("/usuarios", "users", users.page, users.quantity, users.limit, users.search)
	}


	/**
	* @memberof ModalSendReport
	*
	* @method LoadData
	* @description Carga los usuarios que estan asignados a la encuesta
	**/
	LoadData = (encuesta_id) => {

		axios.post('/encuestas/usuarios', { id: encuesta_id })
			.then(res => {
				console.log('res enc usuarios', res);
				this.setState({ reports: res.data.data.reportes });

			})
			.catch(error => {
				console.log('error', error);
			})

	}

	/**
	* @memberOf ModalSendReport
	* @function getDataPagination
	*
	* URL, type, page, quantity, limit, search
	* @param URL
	* @description URL a la cual se obtendrá la paginación.
	*
	* @param type
	* @description Es el nombre que tiene el objeto que almacena la paginacion, aqui es users, targets o profiles..
	*
	* @param page
	* @description Numero de pagina de la paginacíon.
	*
	* @param quantity
	* @description Total de registros.
	*
	* @param limit
	* @description Limite de Elementos en la paginación.
	*
	* @param search
	* @description Si hay algo que buscar, que lo busque.
	*
	**/
	getDataPagination = async (URL, type, page, quantity, limit, search) => {
		/* Para que funcione, necesito declarado un objeto en el estado con la siguiente estructura */
		//Obtengo el objeto del estado
		let before = this.state[type];
		//Activo el LOADING
		before.loading = true;
		this.state[type] = before;
		//Renderizo con lo nuevos valores
		this.setState({ update: !this.state.update })
		//hago la solicitud
		return axios.get(URL, { params: { page, quantity, limit, search } })
			.then(response => {
				//Actualizo los valores con la nueva paginación. Debe ser un AGGREGATAE en el servidor.
				var reponseData = [];
				if (URL === "/departamentos" || URL === "/usuarios") {
					reponseData = response.data.data.itemsList;
				} else {
					reponseData = response.data.data;
				}
				const { page, limit, search } = this.state[type];
				//Actualizo el objeto con los nuevos valores en la paginacion
				this.state[type] = {
					data: reponseData,
					page: page,
					quantity: reponseData.itemCount,
					limit: limit,
					search: search,
					loading: false
				};
				//Renderizo
				this.setState({ update: !this.state.update })
			})
			.catch(error => {
				console.log('error', error)
			})
	}

	/*
	* @memberof ModalSendReport
	*
	* @method selectOptions
	* @description Metodo que despliega las opciones del select del modal
	*/
	selectOptions = (options, index) => {
		//const { targetToEditData } = this.state;
		let arr = []
		let arrOptions = options.data.map((item, index) => {
			arr.push(item._id)
			return <Option value={item._id} key={index}>
				{<span>{item.nombre} </span>}
			</Option>
		})


		return arrOptions;
	}

	/*
	* @memberof ModalSendReport
	*
	* @method sendReport
	* @description Envia la peticion al servidor para el envio de reportes
	*/
	sendReport = (values) => {
		console.log(values);

		axios.post('/encuestas/enviar', {
			id: this.props.encuesta,
			usuarios_id: values.usuarios,
		},
			{
				headers: {
					Authorization: sessionStorage.getItem('token')
				}
			})
			.then(res => {
				if(res.data.success) {
					this.setState({ loading: false, message: "!Reporte enviado exitosamente!" });
					message.success(this.state.message);
				}
				else{
					this.setState({ loading: false, message: "No se ha podido enviar los reportes correctamente" });
					message.error("Error.");
				}

			})
			.catch(error => {
				this.setState({ loading: false, message: "No se ha podido enviar los reportes correctamente" });
				console.log(error)
				message.error(this.state.message);
			})
		this.props.close();
	}

	render() {

		const { users } = this.state;

		var reports = this.state.reports;
		return (
			<div>
				<h3 className="modal-title">Envio de Reporte</h3>

				<Text type="secondary" className="">Reporte enviado a Usuarios:</Text>

				<Descriptions layout={'vertical'} >
					<Descriptions.Item span={24}>
						{reports.map(function (report, i) {
							return <p> {report.nombre} </p>
						})}

					</Descriptions.Item>

				</Descriptions>

				<Form
					name="fomulario-usuario"
					layout="vertical"
					className="form-modal"
					onFinish={this.sendReport}
				>
					<Spin tip="Espere un momento por favor..." spinning={this.state.loading}>
						<Row>
							<Col span={16} offset={4}>

								<Form.Item
									label="Enviar a Usuarios"
									name="usuarios"
									rules={
										[{
											required: false,
											message: "Ingrese el nombre de usuario",
										}]}
								>
									<Select
										mode="multiple"
										filterOption={false}
										onSearch={(search) => this.getDataPagination("/usuarios", "users", users.page, users.quantity, users.limit, search)}
										notFoundContent={users.loading ? <Spin size="large" indicator={<LoadingOutlined
											style={{ fontSize: 34 }} spin />} /> : null}
									>
										{this.selectOptions(users)}
									</Select>

								</Form.Item>
							</Col>
						</Row>
						<Form.Item>
							<Button htmlType="submit" className="v-button-primary" >Enviar</Button>
						</Form.Item>
					</Spin>
				</Form>
			</div>

		)
	}
}