import React from 'react';


import { BrowserRouter, Route, Redirect } from 'react-router-dom';

import {Layout} from 'antd';
import 'antd/dist/antd.css'


import './styles/Globals.css';

import PublicRoutes from "./Routes/PublicRoutes";
import AdminRoutes from "./Routes/AdminRoutes";
import Auth from "./Routes/Middleware/Auth"

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        Auth === true
            ? <Component {...props} />
            : <Redirect to='/login' />
    )} />
)

function App() {
    return (
        <BrowserRouter>
            <Layout className="layout main-container">
                <PrivateRoute path='/admin' >
                    <AdminRoutes />
                </PrivateRoute>
                 <PublicRoutes />
            </Layout>
            {/* <div className="banner-testing">
                Esta es una versión de Prueba. <br/>
                Dirigase a <a href="https://vianneyvoc.com">vianneyvoc.com</a> para acceder al sistema real.
                <span style={{ position: 'absolute', bottom: 5, left: 5,  }}>v 2.0.0</span>
            </div> */}
        </BrowserRouter>
    );
}

export default App;
