import React, { Component } from "react";
import { Button, Card, Form, Input, Layout, List, Modal, Checkbox, Typography, Switch, Radio, Tooltip, message, Spin } from 'antd';
import { DeleteOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Redirect } from "react-router-dom";
//compoenentes
import DropzoneAntDesign from "../../../ExternalComponent/AntdDropzone/DropzoneAntDesign";
//css
import '../../../../styles/PreguntaImagen.css';

const axios = require('axios').default;
const { Content } = Layout;
const { Title } = Typography;


/**
 * @class Encuestas
 * @component
 *
 * @description Esta se refiere a la parte Agregar o Editar una pregunta de imagen
 * */
export default class PreguntaImagen extends Component {


    constructor(props) {
        super(props);
        this.state = {
            form: {},

            previewVisible: false,
            previewImage: '',
            previewTitle: '',

            redirect: false,

            isEditing: false,
            question: {
                id: '',
                nombre: '',
                pregunta: '',
                respuestas: [{
                    _id: undefined,
                    ninguno: false,
                    imagenes: []
                }]
            },
            index: undefined,
            uploadStatus: [false, false],
            switch_condicional: false,
            loading: false,
        }
    }


    componentDidMount = async () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        const { survey, item, index } = this.props.location.state;
        this.props.updateNavigationInformation('Crear Pregunta', true, () => this.setRedirect({
            pathname: "/admin/encuestas/" + this.state.form.tipo.value,
            state: this.state.form
        }));
        //Si el item ID esta definido, significa que la pregunta existe.
        if (item !== undefined && index !== undefined) {
            //Si la encuesta está guardada en la base de datos, la obtenemos.
            if (item._id !== undefined) {
                this.getQuestion(item._id, index);
            } else {
                this.setQuestion(item, index)
            }
        }
        
        
        this.formReference.current.setFieldsValue({ genero: 3 })
        
        
        this.setState({ form: survey })

        this.formReference.current.setFieldsValue({
            genero: 3,
        })
    };

    /**
     *
     * @memberof PreguntaImagen
     *
     * @method getQuestion
     * @description Obtenemos la informacipon del pregunta
     *
     * @param id (string)
     * ID de la pregunta/linea
     *
     * @param index (string)
     * index de la pregunta
     */
    getQuestion = (id, index) => {
        this.props.updateNavigationInformation('Editar Linea');
        this.setState({loading: true})
        axios.get("/preguntas/id", {
            params: { id }
        })
        .then(async ({ data }) => {
            this.setQuestion(data, index)
            this.setState({isEditing: true})
        })
        .catch(res => {
            console.log("no obtenida", res);
            message.error('Error ala cargar las pregunta')
            this.setState({loading: false})
        })
    };

    /**
     *
     * @memberof PreguntaImagen
     *
     * @method setQuestion
     * @description Establecemos los valores de los elementos aqui.
     *
     * @param data (string)
     * Info de la pregunta
     *
     * @param index (string)
     * index de la pregunta
     */
    setQuestion = async (data, index) => {
        let arrayForm = [];
        if (data._id !== undefined) {
            for (const respuesta of data.respuestas) {
                arrayForm.push({
                    _id: respuesta._id,
                    ninguno: respuesta.ninguno,
                    imagenes: respuesta.imagen.map(imagen => {
                        return {
                            name: imagen.imagen,
                            status: 'done',
                            url: axios.defaults.baseURL + '/upload/' + imagen.imagen,
                            _id: imagen._id,
                            disponible: (imagen.disponible !== undefined) ? imagen.disponible : false
                        }
                    })

                })
            }
        }
        else if (Array.isArray(data.respuestas)) {
            for (const respuesta of data.respuestas) {
               arrayForm.push({
                    _id: respuesta._id,
                    ninguno: (respuesta.ninguno !== undefined) ? respuesta.ninguno : false,
                    imagenes: (Array.isArray(respuesta.imagenes) && respuesta.imagenes.length === 0) ? [] : respuesta.imagenes.map(imagen => {
                        return {
                            name: imagen.name,
                            status: 'done',
                            url: axios.defaults.baseURL + '/upload/' + imagen.name,
                            disponible: imagen.disponible
                        }
                    })
                });
            }
        }

        await this.setState({
            question: {
                id: data._id,
                nombre: data.nombre,
                respuestas: arrayForm,
                activada: data.condicional?.activada,
                texto: data.condicional?.texto,
                opcion_si: data.condicional?.opcion_si,
                opcion_no: data.condicional?.opcion_no,
                genero: data.genero,
                pregunta: data.pregunta,
            },
            uploadStatus: arrayForm.map(() => false),
            index: index,
            switch_condicional: data.condicional?.activada,
            loading: false,
        });

        //Ponemos la info en el formulario
        await this.formReference.current.resetFields();

    };


    /**
     *
     * @memberof PreguntaImagen
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };


    /**
     *
     * @memberof PreguntaImagen
     *
     * @method removeImage
     * @description Elimnamos una imagen del webservice junto con sus respectivos registros.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeImage = (image, index) => {
        let values = this.formReference.current.getFieldsValue();
        axios.post("/preguntas/image/delete", {
            pregunta_id: this.state.question.id,
            respuesta_id: values.respuestas[index]._id,
            imagen_id: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };


    /**
     *
     * @memberof PreguntaImagen
     *
     * @method deleteRespuesta
     * @description Elimnamos un set/respuesta completo dedl webservice. Le enviamos la pregunta_id y la resuesta a eliminar. Se elminan imagenes y todo
     *
     * @param index (string)
     * INDEX del elemento donde se encuentra localizado en el array.
     *
     * @param remove (function)
     * Este metodo lo genera el elemento Form.List de ANTD, sirve para elininar un registro del arreglo de elementos
     */
    deleteRespuesta = (index, remove) => {
        let values = this.formReference.current.getFieldsValue();
        if (values.respuestas[index]._id !== undefined && values.respuestas[index]._id !== null) {
            axios.post('/respuesta/delete', {
                id: values.respuestas[index]._id,
                pregunta_id: this.state.question.id
            })
            .then( response => {
                remove(index)
            })
            .catch(e => {
                console.log(e)
            })
        }
        else {
            axios.post('/upload/multiple-delete', {
                multi: (Array.isArray(values.respuestas[index].imagenes)) ? values.respuestas[index].imagenes.map(imagen => imagen.name) : []
            })
            .then(({ data }) => {
                remove(index)
            })
            .catch(() => {})
        }
    };


    /**
     *
     * @memberof PreguntaImagen
     *
     * @method handlePreview
     * @description Para visualizar la imagen agregada
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    handlePreview = async file => {
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };



    /**
     *
     *
     * @param {*} values
     * @returns
     * @memberof PreguntaImagen
     * @description Formatea los valores para mostrarlos en la lista de preguntas y enviarlos al WS.
     */
    formatValues(values) {
        let pregunta = {
            tipo: values.tipo,
            numero: values.numero,
            nombre: values.nombre,
            pregunta: values.pregunta,
            respuestas: values.respuestas,
            condicional: {
                activada: values.activada,
                texto: values.texto,
                opcion_si: values.opcion_si,
                opcion_no: values.opcion_no,
            },
            genero: values.genero
        };

        return pregunta;
    }
    /**
     *
     * @memberof PreguntaImagen
     *
     * @method handleSubmit
     * @description Guardamos la pregunta de imagenes que se realizó
     *
     */
    handleSubmit = async (values) => {
        console.log(values)
        let errors = [];
        let respuestas = [];


        //Validamos que todas las opciones tengan al  menos una imagen
        for (let x = 0; x < values.respuestas.length; x++) {
            let res = values.respuestas[x];
            if (res === undefined || !(Array.isArray(res.imagenes)) || res.imagenes.length < 1) {
                errors.push("La opción " + (x + 1) + " no tiene ninguna imagen.");
            }

            else {

                if (res.ninguno === undefined) {
                    var res_ninguno = document.getElementById("chkb-" + x);
                    res_ninguno = (res_ninguno === undefined) ? false : res_ninguno.checked;
                    res.ninguno = res_ninguno;
                }

                respuestas.push(res);
            }
        }


        if (errors.length > 0) {
            return Modal.error({
                title: "",
                content: <List
                    dataSource={errors}
                    renderItem={item => <List.Item>{item}</List.Item>}
                />
            });
        }

        else {
            values.tipo = "image";
            values.respuestas = respuestas;
        }

        if (this.state.isEditing) {
            const { survey, index } = this.props.location.state;
            if (survey._id !== undefined) {
                axios.put("/preguntas/update", {
                    pregunta_id: this.state.question.id,
                    encuesta_id: this.state.question.id,
                    data: this.formatValues(values)
                })
                    .then(({ data }) => {
                        this.state.question.respuestas = respuestas;

                        let preguntas = this.state.form.preguntas;
                        for (let index = 0; index < preguntas.length; index++) {
                            const _p = preguntas[index];
                            if (_p._id === this.state.question.id) {
                                this.state.form.preguntas[index].respuestas = respuestas;
                                break;
                            }
                        }
                        let v = this.formatValues(values);
                        this.state.form.preguntas[index] ={ ...v, _id: this.state.question.id}
                        setTimeout(() => {
                            this.redirectOnSave(this.state.form._id)
                        }, 250);

                    })

                    .catch(() => {

                    })
            }else {
                //Caso contrario
                //Se actualiza la pregunta y se guarda
                this.state.form.preguntas[index] =  this.formatValues(values);
                this.redirectOnSave(this.state.form.tipo.value)
            }
        }
        else {
            const { index } = this.props.location.state;

            if(index !== undefined )
                this.state.form.preguntas.[index] = this.formatValues(values);
            else
                this.state.form.preguntas.push(this.formatValues(values))
            this.redirectOnSave(this.state.form.tipo.value)
        }
    }

    /**
     *
     * @memberof PreguntaImagen
     *
     * @method handleSubmit
     * @description Guardamos la pregunta de imagenes que se realizó
     *
     */
    redirectOnSave = (value) => {
        this.setRedirect({
            pathname: "/admin/encuestas/" + value,
            state: this.state.form,
            updateNavigationInformation: true
        })
    }

    /**
     *
     * @memberof PreguntaImagen
     *
     * @method setRedirect
     * @description Al guardar redirigimos y listo.
     *
     */
    to = null;
    setRedirect = (to) => {
        this.to = to;
        this.setState({ redirect: true })
    };


    formReference = React.createRef();
    arrayReference = React.createRef();


    onChangeCheckBox = (e, key) => {
        var nuevoEstatus = e.target.checked;
        //state.uploadStatus[index] = false;
        //document.getElementById("chk-global-"+key).click()
        let fieldsValue = this.formReference.current.getFieldsValue().respuestas;
        let set = fieldsValue[key];
        set.ninguno = nuevoEstatus
        if (this.state.question.respuestas[key] !== undefined) {
            this.state.question.respuestas[key].ninguno = nuevoEstatus;
        }
    }


    actualizarEstatusSets = async () => {

        let sets = this.formReference.current.getFieldsValue().respuestas;
        for (let index = 0; index < sets.length; index++) {
            var nuevoEstatus = document.getElementById("chkb-" + index);
            if (nuevoEstatus === undefined) { nuevoEstatus = false }
            else { nuevoEstatus = nuevoEstatus.checked }
            const set = sets[index];
            if (set.ninguno === undefined) {
                set.ninguno = nuevoEstatus;
            }
            if (this.state.question.respuestas[index] !== undefined) {
                this.state.question.respuestas[index].ninguno = nuevoEstatus;
            }
        }
    }



    render = () => {
        const { formReference, removeImage, actualizarEstatusSets } = this;
        const { previewVisible, previewImage, previewTitle, question, form } = this.state;


        let optionTitle;
        try {
            optionTitle = (form.tipo.value === "voc") ? 'Set' : 'Opción'
        } catch (e) {
            optionTitle = 'Opción';
        }


        let active = true;
        for (let i = 0; i < this.state.uploadStatus.length; i++) {
            if (this.state.uploadStatus[i])
                active = false;
        }

        return <Content className="page-main" style={{ padding: '30px' }}>

            {(this.state.redirect) ? <Redirect to={this.to} /> : null}

            <Card className="card-question-content">
                <Spin spinning={this.state.loading} >
                <Form
                    className="form-question"
                    layout="vertical"
                    labelCol={{ span: 8 }}
                    onFinish={this.handleSubmit}
                    scrollToFirstError={true}
                    ref={formReference}
                    initialValues={question}
                >
                    <Form.Item
                        label="Nombre de la Pregunta:"
                        name="nombre"//---------------------------------------------------------nombre
                        rules={[{
                            required: true,
                            message: "Es necesario el titulo de la pregunta. "
                        }]}
                    >
                        <Input.TextArea className="text-area" />
                    </Form.Item>

                    <Form.Item
                        label="Pregunta:"
                        name="pregunta"//---------------------------------------------------------pregunta
                        rules={[{
                            required: true,
                            message: "Es necesario el nombre de la pregunta. "
                        }]}
                    >
                        <Input.TextArea className="text-area" />
                    </Form.Item>

                    <Form.Item
                        label="Condicional:"
                        name="activada"//---------------------------------------------------------activada
                        tooltip="This is a required field"
                        valuePropName="checked"
                    >
                        <Switch onChange={chk => { this.setState({ switch_condicional: chk }) }} />
                    </Form.Item>

                    {this.state.switch_condicional ? <div className="condicional-fields">
                        <Form.Item
                            name="texto"//---------------------------------------------------------texto
                            label="Pregunta Condicional"
                            className="form-item"
                            rules={[{
                                message: "Es necesario la Pregunta Condicional",
                                required: true
                            }]}
                        >
                            <Input className="input" />
                        </Form.Item>

                        <Form.Item
                            name="opcion_si"//---------------------------------------------------------activada
                            label="Opción Si"
                            labelCol={{ span: 3 }}
                            className="form-item condicional-item"
                            rules={[{
                                message: "Es necesario la opción",
                                required: true
                            }]}
                        >
                            <Input className="input" />
                        </Form.Item>

                        <Form.Item
                            name="opcion_no"//---------------------------------------------------------activada
                            label="Opción No"
                            labelCol={{ span: 3 }}
                            className="form-item condicional-item"
                            rules={[{
                                message: "Es necesario la opción",
                                required: true
                            }]}
                        >
                            <Input className="input" />
                        </Form.Item>
                    </div> : null}

                    <Form.Item
                        label="Genero:"
                        name="genero"//---------------------------------------------------------genero
                        rules={[{
                            required: true,
                            message: "Es necesario seleccionar una opción"
                        }]}
                    >
                        <Radio.Group className="radio-btns">
                            <Radio value={0}>Masculino</Radio>
                            <Radio value={1}>Femenino</Radio>
                            <Radio value={2}>Ambos</Radio>
                            <Radio value={3}>No Aplica</Radio>

                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Posibles Respuestas:"
                    >
                        <Form.List
                            name="respuestas"
                            className="form-item-questions"
                        >
                            {(fields, { add, remove }) => {

                                return (
                                    <Content className="questions-content">
                                        {fields.map((field, index) => {
                                            var ninguno = (question.respuestas[field.fieldKey] !== undefined && question.respuestas[field.fieldKey].ninguno) ? question.respuestas[field.fieldKey].ninguno : false;
                                            return <Form.Item
                                                className={"question-ask-content question-" + index}
                                                required={false}
                                                key={field.key}
                                                extra={<div className="question-title-content">
                                                    <Title
                                                        className="question-title">
                                                        {optionTitle}: {index + 1} {fields.length > 1 ?
                                                            (
                                                                <Button className="dynamic-delete-button" onClick={() => {
                                                                    const componente = this
                                                                    Modal.confirm({
                                                                        icon: <ExclamationCircleOutlined />,
                                                                        title: '¿Estas seguro de eliminar este set?',
                                                                        onOk() {
                                                                            componente.deleteRespuesta(field.name, remove)
                                                                        },
                                                                    });
                                                                }} style={{ backgroundColor: "#ff4d4f" }} type="primary"
                                                                    shape="circle" icon={<DeleteOutlined />}
                                                                    size="large" />
                                                            ) : null}

                                                        <Tooltip
                                                            placement="topLeft" title='Al indicar esta sección, se agregará una opción de "Ninguno" por defecto al Set.'>
                                                            {(ninguno === true) ?
                                                                <Checkbox
                                                                    key={"chv-" + index}
                                                                    id={"chkb-" + index}
                                                                    style={{ marginLeft: "10px" }}
                                                                    defaultChecked
                                                                    onClick={e => this.onChangeCheckBox(e, index)} />
                                                                :

                                                                <Checkbox
                                                                    key={"chf-" + index}
                                                                    id={"chkb-" + index}
                                                                    style={{ marginLeft: "10px" }}
                                                                    onClick={e => this.onChangeCheckBox(e, index)} />
                                                            }
                                                        </Tooltip>
                                                    </Title>
                                                </div>
                                                }
                                            >
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, '_id']}
                                                    fieldKey={[field.fieldKey, '_id']}
                                                >
                                                    <Input type="hidden" />
                                                </Form.Item>

                                                <Form.Item

                                                    {...field}
                                                    name={[field.name, 'imagenes']}
                                                    fieldKey={[field.fieldKey, 'imagenes']}
                                                    noStyle
                                                    valuePropName="fileList"
                                                    ref={this.arrayReference}


                                                    className={"question-ask-content question-" + index}
                                                    required={false}
                                                    key={field.key}
                                                    extra={<div className="question-title-content">
                                                        <Title
                                                            className="question-title">
                                                            {optionTitle}: {index + 1} {fields.length > 1 ?
                                                                (
                                                                    <Button className="dynamic-delete-button border" onClick={() => {
                                                                        this.deleteRespuesta(field.name, remove)
                                                                    }} style={{ backgroundColor: "#ff4d4f" }} type="primary"
                                                                        shape="circle" icon={<DeleteOutlined />}
                                                                        size="large" />
                                                                ) : null}

                                                            {(optionTitle === "Set") ?
                                                                <Tooltip
                                                                    placement="topLeft" title='Al indicar esta sección, se agregará una opción de "Ninguno" por defecto al Set.'>
                                                                    <Checkbox
                                                                        style={{ marginLeft: "10px" }}
                                                                        defaultChecked={false}
                                                                        onClick={e => this.onChangeCheckBox(e, index)} />
                                                                </Tooltip> : null}
                                                        </Title>
                                                    </div>
                                                    }

                                                >
                                                    <DropzoneAntDesign
                                                        indexSet={index}
                                                        onFinishUpload={() => this.setState(state => {
                                                            state.uploadStatus[index] = false;
                                                            return state;
                                                        })}

                                                        onStartUpload={() => this.setState(state => {
                                                            state.uploadStatus[index] = true;
                                                            return state;
                                                        })}

                                                        deleteFileEvent={(_id) => {
                                                            removeImage(_id, index)
                                                        }}
                                                    />


                                                </Form.Item>
                                            </Form.Item>

                                        }
                                        )}
                                        <Form.Item
                                            className="question-content question-button"
                                        >
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    add();
                                                    setTimeout(() => {
                                                        actualizarEstatusSets();
                                                    }, 300);
                                                }}
                                                className="add-question-button"
                                            >
                                                <PlusOutlined /> Agregar {optionTitle}
                                            </Button>
                                        </Form.Item>
                                    </Content>
                                );
                            }}
                        </Form.List>
                    </Form.Item>
                    <Button
                        type="ghost"
                        htmlType="submit"
                        style={{
                            paddingTop: '5px',
                            width: (!active) ? "270px" : undefined
                        }}
                        disabled={!active}
                        loading={!active}

                        className="v-button-primary m-3">{(active) ? "Continuar" : "Cargando Imagenes"}</Button>
                </Form>
                </Spin>
            </Card>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={e => this.setState({ previewVisible: false })}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </Content>
    }
}
