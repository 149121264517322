import React, { Component } from 'react';
import { Row, Col, Menu, Dropdown, Input, Button, Table, Tag, message, Modal,Checkbox } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

//componentes
import ModalTargetForm from './modals/ModalTargetForm';
import ModalViewTarget from './modals/ModalViewTarget';

const { Search } = Input;
const { confirm } = Modal;
const axios = require("axios").default;

/**
* @method ArrowIcon
* @description  Icono Dropdown Barra fillter
*
**/
function ArrowIcon() {
	return (
		<svg style={{ marginLeft: '10px' }} width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M3.98817 0.00230214C5.10632 0.00230214 6.22447 0.00528392 7.34261 3.03092e-05C7.60672 -0.0012476 7.84092 0.0366636 7.95916 0.347053C8.08234 0.670505 7.90441 0.87 7.72407 1.07092C6.64496 2.27328 5.56699 3.47693 4.48787 4.6793C4.10679 5.10399 3.89515 5.10598 3.52244 4.69066C2.43623 3.47991 1.35129 2.26775 0.264064 1.05814C0.0803047 0.853814 -0.0783622 0.648497 0.0419053 0.32902C0.16268 0.00783972 0.40727 3.03092e-05 0.664787 0.000740257C1.77254 0.00414801 2.88029 0.00230214 3.98817 0.00230214Z"
				fill="white" />
		</svg>
	)
}



/**
* @class Targets
* @description  Clase que contiene la tabla de targets, junto con el modal de creacion y edicion de los
* mismos, ruta: /admin/targets
*
**/
export default class Targets extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loding: false,
			modalTargetVisible: false,
			modalViewTargetVisible: false,
			targets: [],
			modalTitle: 'Crear Target',
			targetToEdit: [],
			targetToView: [],

			/*Datos para los dropdowns*/
			departamentosAll: [],
			CDNAll: [],
			usuariosAll: [],
			CDNSelected: [],
			DptosSelected: [],
			UsersSelected: [],

			/**paginador */
			currentPage: null,
			itemCount: null,
			perPage: null,
			pageCount: null,

			/*sorted - serach data*/
			search  : null,
			field   : null,
			order   : null,
		}
	}

	/**
	* @memberof Targets
	*
	* @method componentDidMount
	* @description Cambia el titulo del Navbar al entrar a este componente, carga los primeros 10 targets
	**/
	componentDidMount() {
		this.props.updateNavigationInformation('Targets');
		this.LoadData(1);
		this.LoadDepartamentos();
		this.LoadCentros();
		this.LoadUsuarios();
	}

	/**
	* @memberof Targets
	*
	* @method showModalTarget
	* @description Despliega el modal para CREAR un Target
	**/
	showModalTarget = () => { 
		this.setState({ modalTargetVisible: true, modalTitle: 'Crear Target', targetToEdit: [] }); 
	}

	/**
	* @memberof Targets
	*
	* @method handleViewTarget
	* @description Metodo que se encarga de mostar el modal con la infomacion completa de un Target
	**/
	handleViewTarget = (record) => {
		this.setState({ modalViewTargetVisible: true, targetToView: record });
	}

	/**
	* @memberof Targets
	*
	* @method hideModalTarget
	* @description Cierra el modal de CREAR / EDITAR Targets
	**/
	hideModalTarget = () => { this.setState({ modalTargetVisible: false }); }

	/**
	* @memberof Targets
	*
	* @method hideModalViewTarget
	* @description Cierra el modal de VER un Target
	**/
	hideModalViewTarget = () => { this.setState({ modalViewTargetVisible: false }); }

	/**
	* @memberof Targets
	*
	* @method   handleEditTarget
	* @description Metodo handle que manda a buscar el target deseado, al hacer click en editar
	*
	**/
	handleEditTarget(record) {
		console.log('se presiono para editar')
		this.setState({loading: true})
		this.loadTarget(record._id);
	}

	/**
	* @memberof Targets
	*
	* @method   loadTarget
	* @description  Metodo que permite la busqueda de un Target en la BD, para despues abrir el modal de Edicion con los
	* datos cargados
	*
	* 
	**/
	loadTarget = (id) => {
		axios.post('/targets/get', { id: id })
			.then((response) => {
				const data = response.data.data;
				this.setState({loading: false })
				this.showModalEditUser(data)
			})
			.catch((response) => {
				console.log('error', response.message);
				this.setState({loading: false, message: "¡Ooops! Ha ocurrido un error"});
				message.error(`${this.state.message}`);
			})
	}

	/**
	* @memberof Targets
	*
	* @method showModalEditUser
	* @description Cambia el state para que el modal de edicion sea visible y se cambie el titulo del modal, crea nuevas
	* propiededes del objeto data (informacion de target a ediatar) para añadir items a los selects de edicion
	**/
	showModalEditUser = (data) => {
		//si tiene centros
		if(data.centros_negocios.length > 0){
			let arr = []
			data.CDNOptions = data.centros_negocios.map((cdn,index) => {
				arr.push(cdn._id)
				return { id: cdn._id, nombre: cdn.nombre}
			})
			data.centros_negocios = arr;	
		}

		//si tiene departamentos
		if(data.departamentos.length > 0){
			let arr = []
			data.DeptOptions = data.departamentos.map((dpt,index) => {
				arr.push(dpt._id)
				return { id: dpt._id, nombre: dpt.nombre}
			})
			data.departamentos = arr;
		}

		//si tiene usuarios
		if(data.usuarios.length > 0){
			let arr = []
			data.UserOptions = data.usuarios.map((user,index) => {
				arr.push(user._id)
				return { id: user._id, nombre: user.nombre}
			})
			data.usuarios = arr;	
		}
		this.setState({ modalTitle: 'Editar Target', modalTargetVisible: true, targetToEdit: data })
	}

	/**
	* @memberof Targets
	*
	* @method   handlePageClick
	* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	*
	**/
	handlePageClick = (page) => {
		console.log("pagina", page);
		this.LoadData(page);
	}

	/**
	* @memberof Targets
	*
	* @method LoadData
	* @description  Metodo para paginar los datos de la tabla Targets
	*
	**/
	LoadData = (page) => {
		this.setState({ loading: true })
		axios.get('/targets', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				page: page,
				limit: 10,
				search  : this.state.search,
        		field   : this.state.field,
        		order   : this.state.order,
        		CDN 	: this.state.CDNSelected,
        		Depts 	: this.state.DptosSelected,
        		Users 	: this.state.UsersSelected,
			}
		})
		.then((response) => {
			const data = response.data.data.itemsList;
			this.setState({
				targets: data,
				currentPage: response.data.data.currentPage,
				itemCount: response.data.data.itemCount,
				pageCount: response.data.data.pageCount,
				loading: false,
			});
		})
		.then(() => {
			//Si se elimina el utlimo registro de una pagina, te manda a la primera pagina
			if(this.state.currentPage > this.state.pageCount){
				this.LoadData(1)
			}
		})
		.catch((response) => {
			console.log('error', response.message);
			this.setState({loading: false})
        	message.error("Ocurrio un error al cargar los Targets");
		})
	}

	/**
	* @memberof Targets
	*
	* @method ReloadList
	* @description  Refrescar la tabla de targets, con la pagina en la que se encuantra
	*
	**/
	ReloadList = () => {
		this.LoadData(this.state.currentPage)
	}

	/**
	* @memberof Targets
	*
	* @method showDeleteConfirm
	* @description  Para cada row mostrar un modal de confirmacion para eliminar el registro,
	* la eliminacion se realiza mediante una peticion al servidor, enviando el id del Target
	*
	* @params item (row)
	**/
	showDeleteConfirm = (record) => {

		const nombre = record.nombre;
		const user_id = record._id;
		const esto = this;

		confirm({
			title: 'Eliminar Target',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estás seguro que deseas eliminar el Target ' + nombre + ' ?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',

			onOk() {
				axios({
					method: 'delete',
					url: '/targets/delete',
					headers: { Authorization: sessionStorage.getItem('token') },
					data: { id: user_id, status: 0 }
				})
					.then((response) => {
						if(response.data.success) {
							//actualiza la lista de targets
							esto.LoadData(esto.state.currentPage);
							message.success("!Target eliminado exitosamente!");
						}
						else{
							message.error('¡Ooops! NO se ha eliminado el Target')

						}
					})
					.catch((error) => {
						console.log("error al borrar el target", error.message);
						if (error.response.status === 403)
							message.error(`${error.response.data.message}`)
						else
							message.error('¡Ooops! NO se ha eliminado el Target')
					})
			},
		});
	}


	/**
	* @memberof Targets
	*
	* @method RenderTags
	* @description  Para cada row se recibe un array de objetos, este metodo lo convierte en un array sencillo
	* @params departamentos (object array)
	**/
	RenderTags = (object_list, type) => {

		let array_names = []
		let color = '';
		object_list.map(dep => {
			array_names.push(dep.nombre)
			return 0
		});
		switch (type){
			case 'departamentos':
				color = 'cyan'
				break;
			case 'usuarios':
				color = 'purple'
				break;
			case 'centros':
				color = 'green'
				break;
			default:
				break;
		}
		
		return array_names.map((dep,index) => {
			if(index <= 2 ){
				return <Tag color={color} style={{marginBottom: '0.3rem'}}>{dep}</Tag>
			} else if (index === 3){
				return <Tag color="blue">...</Tag>
			}
		});
	}

	/**
	* @memberof Targets
	*
	* @method   handlefilterSort
	* @description ordena los datos de la tabla dependiendo del campo
	*
	**/
	handlefilterSort(field,order){
		this.setState({field: field, order: order},() => {
			this.ReloadList();
		})
	}

	/**
	* @memberof Targets
	*
	* @method   handleSearch
	* @description busca los registros que conicidan con la busqueda
	*
	**/
	handleSearch(value){
		this.setState({search: value},() => {
			this.ReloadList();
		})
	}

	/**
	* @memberof Targets
	*
	* @method   LoadDepartamentos
	* @description Trae los deprtamentos para deplegarlos en el dropdown
	*
	**/
	LoadCentros = (search) => {
		axios.post('/centrosNegocioPaginado', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				page: 1,
				limit: 10,
				search: search,
			}
		})
		.then((response) => {
			const data = response.data.data.itemsList;
			this.setState({
				CDNAll: data,
			});
		})
		.catch((response) => {
			message.error("Ocurrio un error al cargar los Centros de Negocio");
			console.log('error', response);
		})
	}


	/**
	* @memberof Targets
	*
	* @method   LoadDepartamentos
	* @description Trae los deprtamentos para deplegarlos en el dropdown
	*
	**/
	LoadDepartamentos = (search) => {
		axios.get('/departamentos', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				page: 1,
				limit: 10,
				search: search,
			}
		})
		.then((response) => {
			const data = response.data.data.itemsList;
			this.setState({
				departamentosAll: data,
			});
		})
		.catch((response) => {
			message.error("Ocurrio un error al cargar los Departamentos");
			console.log('error', response);
		})
	}

	/**
	* @memberof Targets
	*
	* @method   LoadUsuarios
	* @description Trae los usuarios para deplegarlos en el dropdown
	*
	**/
	LoadUsuarios = (search) => {
		axios.get('/usuarios', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				page: 1,
				limit: 10,
				search: search,
			}
		})
		.then((response) => {
			const data = response.data.data.itemsList;
			this.setState({
				usuariosAll: data,
			});
		})
		.catch((response) => {
			message.error("Ocurrio un error al cargar los usuarios");
			console.log('error', response);
		})
	}

	/*
	* @method   handleVisibleChange
	* @description Metodo handle que despliega el menu del dropdown seleccionado
	*
	**/
	handleVisibleChange = (flag,index) => {
		if(index === 1) this.setState({ visible: flag });  //CDN
		if(index === 2) this.setState({ visible2: flag }); //Departamentos
		if(index === 3) this.setState({ visible3: flag }); //Usuarios
	};


	/*
	* @method   handleMenuClick
	* @description Si se hace click en Buscar por medio de los drops este se cierra y 
	* refresca la tabla con los nuevos datos filtrados
	*
	**/
	handleMenuClick = (e,index) => {
		if (e.key === '3') {
			this.setState({ visible: false , visible2 : false, visible3: false });
			this.ReloadList();
		}
	};

	/**
	* @memberof Targets
	*
	* @method   handleSearchDropdowns
	* @description despliega los datos en el dropdown que conicidan con lo que se introdujo en el input
	*
	* @param value
    * @description Valor a buscar
    *
    * @param index
    * @description nuemero que hace referencia al dropdown que se esta usando
	**/
	handleSearchDropdowns(value,index){
		if(index === 1) this.LoadCentros(value.target.value);
		if(index === 2) this.LoadDepartamentos(value.target.value);
		if(index === 3) this.LoadUsuarios(value.target.value)
	}


	/**
     * @methodOf  Targets
     *
     * @function onCDNChange
     * @description Se actualiza el arreglo de CDN seleccionadas jutno con los arreglos IDs
     *
     * @param _id
     * @description El ID de la cuenta a agregar al arreglo de CDN seleccionados
     *
     * @param status
     * @description True, si se va a agregar, false si no
     * */
    onCDNChange = async (_id, status) => {

        let {CDNSelected} = this.state;
        let filter = CDNSelected.findIndex(item => item.toString() === _id.toString())
        if (status) {
            if (filter === -1) CDNSelected.push(_id)
        } else {
            if (filter !== -1) CDNSelected.splice(filter, 1)
        }
        await this.setState({CDNSelected})
        //this.receivedData();
    };

    /**
     * @methodOf  Targets
     *
     * @function onDptosChange
     * @description Se actualiza el arreglo de CDN seleccionadas jutno con los arreglos IDs
     *
     * @param _id
     * @description El ID de la cuenta a agregar al arreglo de CDN seleccionados
     *
     * @param status
     * @description True, si se va a agregar, false si no
     * */
    onDptosChange = async (_id, status) => {

        let {DptosSelected} = this.state;
        let filter = DptosSelected.findIndex(item => item.toString() === _id.toString())
        if (status) {
            if (filter === -1) DptosSelected.push(_id)
        } else {
            if (filter !== -1) DptosSelected.splice(filter, 1)
        }
        await this.setState({DptosSelected})
        //this.receivedData();
    };

    /**
     * @methodOf  Targets
     *
     * @function onUsersChange
     * @description Se actualiza el arreglo de CDN seleccionadas jutno con los arreglos IDs
     *
     * @param _id
     * @description El ID de la cuenta a agregar al arreglo de CDN seleccionados
     *
     * @param status
     * @description True, si se va a agregar, false si no
     * */
    onUsersChange = async (_id, status) => {

        let {UsersSelected} = this.state;
        let filter = UsersSelected.findIndex(item => item.toString() === _id.toString())
        if (status) {
            if (filter === -1) UsersSelected.push(_id)
        } else {
            if (filter !== -1) UsersSelected.splice(filter, 1)
        }
        await this.setState({UsersSelected})
        //this.receivedData();
    };

	render() {

		const menuCDN = (
			<Menu onClick={(e) => this.handleMenuClick(e,1)}>
        		<Menu.Item key="1">
        			<Input.Search onChange={value => this.handleSearchDropdowns(value,1)}/>
        		</Menu.Item>
				<Menu.Item key="2">
					<Checkbox.Group style={{ width: '100%' }}>
						{
							this.state.CDNAll !== null ? 
							this.state.CDNAll.map( cdn => {
								return <Row>
									<Checkbox 
										value={cdn._id} 
										onChange={(status) => this.onCDNChange(cdn._id, status.target.checked)}>
										{cdn.nombre}
									</Checkbox>
								</Row>
							}) : ''
						}
					</Checkbox.Group>
        		</Menu.Item>
        		<Menu.Item key="3">Buscar</Menu.Item>
			</Menu>
		);


		const menuDepts = (
			<Menu onClick={(e) => this.handleMenuClick(e,2)}>
				<Menu.Item key="1"> 
					<Input.Search onChange={value => this.handleSearchDropdowns(value,2)}/>
				</Menu.Item>
				<Menu.Item key="2">
					<Checkbox.Group style={{ width: '100%' }} >
						{
							this.state.departamentosAll !== null ? 
							this.state.departamentosAll.map( dpt => {
								return <Row>
									<Checkbox 
										value={dpt._id}
										onChange={(status) => this.onDptosChange(dpt._id, status.target.checked)} 
									>
										{dpt.nombre}
									</Checkbox>
								</Row>
							}) : ''
						}
					</Checkbox.Group>
        		</Menu.Item>
        		<Menu.Item key="3">Buscar</Menu.Item>
			</Menu>
		);


		const menuUsers = (
			<Menu onClick={(e) => this.handleMenuClick(e,3)}>
				<Menu.Item key="1"> 
					<Input.Search onChange={value => this.handleSearchDropdowns(value,3)}/>
				</Menu.Item>
				<Menu.Item key="2">
					<Checkbox.Group style={{ width: '100%' }}>
						{
							this.state.usuariosAll !== null ? 
							this.state.usuariosAll.map( usr => {
								return <Row>
									<Checkbox 
										value={usr._id}
										onChange={(status) => this.onUsersChange(usr._id, status.target.checked)}
									>
										{usr.nombre}
									</Checkbox>
								</Row>
							}) : ''
						}
					</Checkbox.Group>
        		</Menu.Item>
        		<Menu.Item key="3">Buscar</Menu.Item>
			</Menu>
		);


		const columns = [
			{
				title: '',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'NOMBRE',
				dataIndex: 'nombre',
				key: 'nombre',
			},
			{
				title: 'DESCRIPCIÓN',
				dataIndex: 'descripcion',
				key: 'descripcion',
				width: '30%',
			},
			{
				title: 'CENTROS DE NEGOCIO',
				dataIndex: 'centros_negocios',
				key: 'centros_negocios',
				render: (text, row) => {
					if (row.centros_negocios.length > 0) {
						return this.RenderTags(row.centros_negocios,'centros')
					}
					else return 'Sin Centros de negocio'
				}
			},
			{
				title: 'DEPARTAMENTOS',
				dataIndex: 'departamentos',
				key: 'departamentos',
				render: (text, row) => {
					if (row.departamentos.length > 0) {
						return this.RenderTags(row.departamentos,'departamentos')
					}
					else return 'Sin Departamentos'
				}
			},
			{
				title: 'USUARIOS',
				dataIndex: 'usuarios',
				key: 'usuarios',
				render: (text, row) => {
					if (row.usuarios.length > 0) {
						return this.RenderTags(row.usuarios, 'usuarios')
					}
					else return 'Sin Usuarios'
				}
			},
			{
				title: 'ACCIONES',
				dataIndex: '_id',
				key: 'actions',
				width: '15%',
				render: (text, record) => (
					<div>
						<Button className="btn-view" onClick={() => this.handleViewTarget(record)}>
							<img src="/iconos/view.svg" alt="Ver" />
						</Button>
						<Button className="btn-edit" onClick={() => this.handleEditTarget(record)}>
							<img src="/iconos/edit.svg" alt="Editar" />
						</Button>
						<Button className="btn-delete" onClick={() => { this.showDeleteConfirm(record) }}>
							<img src="/iconos/trash.svg" alt="Eliminar" />
						</Button>
					</div>
				)
			},
		];

		return (
			<div className="page-main" style={{ padding: '30px' }}>
				<Row className="controls">
					<Col xs={24} md={20} className="filters">
						<Dropdown className="filter-control" trigger={['click']}
							overlay={
								<Menu>
									<Menu.Item onClick={()=>{this.handlefilterSort('nombre',1)}}>A-Z</Menu.Item>
									<Menu.Item onClick={()=>{this.handlefilterSort('nombre',-1)}}>Z-A</Menu.Item>
								</Menu>}
							placement="bottomLeft">
							<Button type="link">Nombre <ArrowIcon /> </Button>
						</Dropdown>
						<Dropdown
							overlay={menuCDN}
							onVisibleChange={(flag)=>{this.handleVisibleChange(flag,1)}}
							visible={this.state.visible}
							className="filter-control"
							trigger={['click']}
						>
							<Button type="link" onClick={e => e.preventDefault()}>	
								Centro de Negocios<ArrowIcon />
							</Button>
      					</Dropdown>

						<Dropdown
							overlay={menuDepts}
							onVisibleChange={(flag)=>{this.handleVisibleChange(flag,2)}}
							visible={this.state.visible2}
							className="filter-control"
							trigger={['click']}
						>
							<Button type="link" onClick={e => e.preventDefault()}>	
								Departamentos<ArrowIcon />
							</Button>
      					</Dropdown>


						<Dropdown
							overlay={menuUsers}
							onVisibleChange={(flag)=>{this.handleVisibleChange(flag,3)}}
							visible={this.state.visible3}
							className="filter-control"
							trigger={['click']}
						>
							<Button type="link" onClick={e => e.preventDefault()}>	
								Usuarios<ArrowIcon />
							</Button>
      					</Dropdown>
						<Search
							className="search-control"
							placeholder="Buscar"
							size="normal"
							bordered={false}
							enterButton
							onSearch={value => this.handleSearch(value)}
						/>
					</Col>
					<Col span={4}>
						<Button className="add-control" onClick={this.showModalTarget}>
							<svg style={{ marginTop: '0.3em' }} width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6.4298 11.5702C6.31805 11.5702 6.24069 11.5702 6.16046 11.5702C4.35817 11.5702 2.55587 11.5702 0.753582 11.5702C0.246418 11.5702 0 11.3266 0 10.8223C0 9.60172 0 8.38395 0 7.16332C0 6.68195 0.249284 6.4298 0.727794 6.4298C2.54441 6.4298 4.35817 6.4298 6.17478 6.4298C6.25215 6.4298 6.33238 6.4298 6.43266 6.4298C6.43266 6.33238 6.43266 6.25215 6.43266 6.17478C6.43266 4.36676 6.43266 2.55874 6.43266 0.747851C6.43266 0.243553 6.67622 0 7.18338 0C8.39542 0 9.60745 0 10.8195 0C11.3266 0 11.5673 0.243553 11.5673 0.753582C11.5673 2.55587 11.5673 4.35817 11.5673 6.16046C11.5673 6.24069 11.5673 6.32092 11.5673 6.43266C11.6533 6.43266 11.7307 6.43266 11.808 6.43266C13.616 6.43266 15.4241 6.43266 17.235 6.43266C17.7593 6.43266 18 6.67049 18 7.18911C18 8.40115 18 9.61318 18 10.8252C18 11.3209 17.7536 11.5673 17.2607 11.5673C15.4527 11.5673 13.6447 11.5673 11.8338 11.5673C11.7536 11.5673 11.6762 11.5673 11.5673 11.5673C11.5673 11.6562 11.5673 11.7335 11.5673 11.8138C11.5673 13.6218 11.5673 15.4298 11.5673 17.2407C11.5673 17.7622 11.3266 18 10.8052 18C9.59312 18 8.38109 18 7.16905 18C6.67622 18 6.4298 17.7507 6.4298 17.255C6.4298 15.447 6.4298 13.639 6.4298 11.8281C6.4298 11.7507 6.4298 11.6734 6.4298 11.5702Z" fill="white" />
							</svg>
						</Button>
					</Col>
				</Row>

				<div className="page-content">

					<div>
						<h5 className="items-number">Targets {this.state.itemCount}</h5>
						<Table
							className="admin-table"
							columns={columns}
							dataSource={this.state.targets}
							loading={this.state.loading}
							scroll={{ x: 1200 }}
							pagination={{
								onChange: this.handlePageClick,
								total: this.state.itemCount,
								current: this.state.currentPage
							}}
						/>
					</div>


					<ModalTargetForm
						modalTargetVisible={this.state.modalTargetVisible}
						hideModalTarget={this.hideModalTarget}
						modalTitle={this.state.modalTitle}
						ReloadList={this.ReloadList}
						targetToEdit={this.state.targetToEdit}
					/>

					<ModalViewTarget
						modalViewTargetVisible={this.state.modalViewTargetVisible}
						hideModalViewTarget={this.hideModalViewTarget}
						targetToView={this.state.targetToView}
					/>
				</div>
			</div>
		)
	}
}