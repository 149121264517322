import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';


import { Modal } from 'antd';
import { Redirect } from 'react-router-dom';
//import Cookies from 'universal-cookie';

const axios = require("axios").default;

axios.defaults.baseURL = "https://vianneyvoc.com:4000"
axios.defaults.baseURL_Prod = "https://vianneyvoc.com" //link de las encustas externas (quitar 4000)


// axios.defaults.baseURL = "https://bigcodders.com:4030"  
// axios.defaults.baseURL_Prod = "https://vianney-system.bigcodders.com" //link de las encustas externas (quitar 4000)


// axios.defaults.baseURL = "http://localhost:4030"
// axios.defaults.baseURL_Prod = "http://localhost:3000"

// axios.defaults.baseURL = "http://192.168.100.13:4000"
// axios.defaults.baseURL_Prod = "http://192.168.100.13:3000"


// axios.defaults.baseURL = "https://testing.vianneyvoc.com:4030"  
// axios.defaults.baseURL_Prod = "https://testing.vianneyvoc.com:4030" //link de las encustas externas (quitar 4000)

//


axios.interceptors.response.use(undefined, error => {
    const { status /*, data, config */} = error.response;
    if (status === 401) {
        errorToken();
        setTimeout(() => {
            window.location.replace('/login')
            return <Redirect to="/login" />;
        }, 2000);

    }
});


function errorToken() {
    Modal.error({
        zIndex:1000,
        title: 'Error',
        content: 'El token de inicio de sesión ha expirado.',
        okButtonProps: {
            disabled: true,
            className:"nView"
        }
    });
}




ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
