import React, { Component } from "react";
import { Layout, Modal, Button, Row, Col, Dropdown, Menu,  Table, Input, message, Radio, InputNumber } from 'antd';
import { FormPerfil } from './FormPerfil'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Content } = Layout;
const axios = require('axios').default;
const { Search } = Input;
const { confirm } = Modal;

/**
 * @method ArrowIcon
 * @description  Icono para añadir-crear registro
 *
 **/
function ArrowIcon() {
	return <svg style={{ marginLeft: '10px' }} width="8" height="5" viewBox="0 0 8 5" fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.98817 0.00230214C5.10632 0.00230214 6.22447 0.00528392 7.34261 3.03092e-05C7.60672 -0.0012476 7.84092 0.0366636 7.95916 0.347053C8.08234 0.670505 7.90441 0.87 7.72407 1.07092C6.64496 2.27328 5.56699 3.47693 4.48787 4.6793C4.10679 5.10399 3.89515 5.10598 3.52244 4.69066C2.43623 3.47991 1.35129 2.26775 0.264064 1.05814C0.0803047 0.853814 -0.0783622 0.648497 0.0419053 0.32902C0.16268 0.00783972 0.40727 3.03092e-05 0.664787 0.000740257C1.77254 0.00414801 2.88029 0.00230214 3.98817 0.00230214Z"
			fill="white" />
	</svg>
}




/**
 * @method renderContent
 * @description  Funcion para acomodar colSpan de la tabla
 *
 
const renderContent = (value, row, index) => {
	const obj = {
		children: value,
		props: {},
	};
	return obj;
};
*/

/**
 * @class Perfiles
 * @description  Clase que contiene la tabla de perfiles con toda la funcionalidad para ser mostradas en una vista.
 * Esta es la vista que se manda a llamar al ingresar a la ruta principal de perfiles
 *
 **/
class Perfiles extends Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			loading: false,
			profiles: [],
			modalShowToggle: false,
			id_selected: null,

			/**paginador */
			currentPage: null,
			itemCount: null,
			perPage: null,
			pageCount: null,

			/*filters*/
			search: null,
			field: null,
			order: null,
			searchSexo: null,
			searchHijos: null,
			searchEdadMin: null,
			searchEdadMax: null,

			titleModal: 'Crear Perfil',
		}
	}
	/**
   * @memberof Perfiles
   *
   * @method componentDidMount
   * @description 
   *
   **/
	componentDidMount() {
		this.props.updateNavigationInformation('Perfiles');
		this.LoadData(1);
	}


	/**
	* @memberof Perfiles
	*
	* @method LoadData
	* @description  Metodo para paginar los datos de la tabla desde el WS
	* 
	* @param page pagina que se desea cargar
	* @param filter campo para realizar filtrado de datos
	* @param sorter campo para realizar ordenamiento de datos
	* @param search barra de busqueda de texto libre
	*
	**/
	LoadData = (page) => {
		this.setState({ loading: true })
		axios.get('/perfiles',
			{
				headers: { Authorization: sessionStorage.getItem("token") },
				params: {
					page: page,
					limit: 10,
					search: this.state.search,
					field: this.state.field,
					order: this.state.order,
					sexo: this.state.searchSexo,
					hijos: this.state.searchHijos,
					minima: this.state.searchEdadMin,
					maxima: this.state.searchEdadMax,
				}
			})
			.then((res) => {
				const data = res.data.data.itemsList;
				const paginator = res.data.data;
				this.setState({
					profiles: data,
					currentPage: paginator.currentPage,
					itemCount: paginator.itemCount,
					pageCount: paginator.pageCount,
					loading: false,
				});
			})
			.then(() => {
				//Si se elimina el utlimo registro de una pagina, te manda a la primera pagina
				if (this.state.currentPage > this.state.pageCount) {
					this.LoadData(1)
				}
			})
			.catch((res) => {
				console.log('error', res);

			})

	}


	SearchingData = (value) => {
		console.log('valor a buscar', value);
	}

	/**
	* @method showDeleteConfirm
	* @description  Para cada row mostrar un modal de confirmacion para eliminar el registro, la eliminacion se realiza mediante una peticion al servidor
	*
	* @params item (row)
	**/
	showDeleteConfirm = (item) => {
		const perfil_name = item.currentTarget.name;
		const perfil_id = item.currentTarget.id;
		const esto = this;

		confirm({
			title: 'Eliminar Perfil',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estás seguro que deseas eliminar el perfil ' + perfil_name + ' ?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',

			onOk() {
				axios.defaults.headers.get["Authorization"] = sessionStorage.getItem('token');

				axios({
					method: 'delete',
					url: '/perfiles/delete',
					headers: { Authorization: sessionStorage.getItem('token') },
					data: { id: perfil_id, status: 0 }
				})
					.then((res) => {

						if (res.data.success) {
							esto.LoadData(esto.state.currentPage);
							message.success("Perfil eliminado, ¡exitosamente!");
						}
						else {
							message.error('¡Ooops! NO se ha eliminado el Perfil');

						}
					})
					.catch((error) => {
						console.log("error al borrar el perfil", error);
						message.error('¡Ooops! NO se ha eliminado el Perfil');
					})
			},
		});
	}


	/**
	* @memberof Perfiles
	*
	* @method ModalPopUpHandler
	* @description  cambia el state para mostrar u ocultar el modal
	*
	**/
	ModalPopUpHandler = (item) => {
		this.GetUser(item);
		const changeModal = this.state.modalShowToggle;
		this.setState({ modalShowToggle: !changeModal, titleModal: 'Editar Perfil' });
	}


	/**
	* @memberof perfiles
	*
	* @method   GetUser
	* @description  Metodo que asigna al state.id_selected el id del usuario seleccionado para mostrar el modal con los datos de ese usuario
	*
	**/
	GetUser(item) {
		const user_id = item.currentTarget.id;
		this.setState({ id_selected: user_id })

	}

	/**
	* @memberof Perfiles
	*
	* @method   handlePageClick
	* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	*
	**/
	handlePageClick = page => {
		//console.log("pagina", page);//2
		this.LoadData(page);
	};

	/**
	* @memberof Targets
	*
	* @method   handlefilterSort
	* @description ordena los datos de la tabla en orden asc o desc dependiendo del campo
	*
	**/
	handlefilterSort(field, order) {
		this.setState({ field: field, order: order }, () => {
			this.ReloadTable();
		})
	}

	/**
	* @memberof Targets
	*
	* @method   handleFilterSexo
	* @description filtra los datos de la tabla dependiendo del sexo
	*
	**/
	handleFilterSexo(value) {
		if (value === this.state.searchSexo) {
			this.setState({ searchSexo: null, loading: true }, () => {
				this.ReloadTable();
			})
		} else {
			this.setState({ searchSexo: value, loading: true }, () => {
				this.ReloadTable();
			})
		}
	}

	/**
	* @memberof Targets
	*
	* @method   handleFilterHijos
	* @description filtra los datos de la tabla dependiendo del sexo
	*
	**/
	handleFilterHijos(value) {
		if (value === this.state.searchHijos) {
			this.setState({ searchHijos: null, loading: true }, () => {
				this.ReloadTable();
			})
		} else {
			this.setState({ searchHijos: value, loading: true }, () => {
				this.ReloadTable();
			})
		}
	}


	/**
	* @memberof Targets
	*
	* @method   handleSearch
	* @description busca los registros que conicidan con la busqueda
	*
	**/
	handleSearch(value) {
		this.setState({ search: value }, () => {
			this.ReloadTable();
		})
	}

	/**
	* @memberof Perfiles
	*
	* @method ReloadList
	* @description  Refrescar la tabla de Perfiles, con la pagina en la que se encuentra
	*
	**/
	ReloadTable = () => {
		this.LoadData(this.state.currentPage)
	}

	/*
	* @method   handleMenuClick
	* @description Si se hace click en Buscar por medio de los drops este se cierra y 
	* refresca la tabla con los nuevos datos filtrados
	*
	**/
	handleMenuClick = (e,) => {
		if (e.key === '2') {
			this.setState({ visible: false });
			this.ReloadTable();
		}
	};

	/**
	* @memberof Targets
	*
	* @method   handleSearchEdad
	* @description establece las edades a buscar en el state
	*
	* @param value
	* @description edad a buscar
	*
	* @param limit
	* @description si es edad maxima o minima
	**/
	handleSearchEdad(value, limit) {
		if (limit === 'min') this.setState({ searchEdadMin: value });
		if (limit === 'max') this.setState({ searchEdadMax: value });
	}

	render() {
		var profiles = this.state.profiles;
		var loading = this.state.loading;

		const columns = [
			{
				title: '#',
				dataIndex: 'id',
				width: '5%',
			},
			{
				title: 'Nombre',
				dataIndex: 'nombre',
				key: 'nombre',
				width: '25%',
			},
			{

				title: 'Rango Edad ',
				render: (data, row) => (<p style={{ margin: 0, padding: 0 }}>De <strong>{row.edad_minima}</strong> a <strong>{row.edad_maxima}</strong> años </p>),
			},

			{
				title: 'Sexo',
				dataIndex: 'sexo',
				key: 'sexo',

				render: (value, row) => {
					if (row.sexo === null || row.sexo === undefined)
						return <p><strong>No aplica</strong></p>
					else
						return <p>{row.sexo ? 'Masculino' : 'Femenino'}</p>
				}
			},
			{
				title: 'Hijo',
				dataIndex: 'hijo',
				key: 'hijo',

				render: (value, row) => {
					if (row.hijo === null || row.hijo === undefined)
						return <p><strong>No aplica</strong></p>
					else
						return <p>{value ? 'Si' : 'No'}</p>
				}
			},
			{
				title: 'Adi',
				dataIndex: 'adi',
				key: 'hijo',
				render: (value, row) => {
					console.log(row);
					if (row.adi === null || row.adi === undefined)
						return <p><strong>No aplica</strong></p>
					else
						return <p>{row.adi ? 'Si' : 'No'}</p>
				}
			},

			{
				title: 'Acciones',
				key: 'actions',
				dataIndex: '_id',
				width: '10%',
				render: (text, record) => (
					<div>
						<Button className="btn-edit" name={record.nombre} id={record._id} onClick={this.ModalPopUpHandler}>
							<img src="/iconos/edit.svg" alt="edit" />
						</Button>
						<Button className="btn-delete" id={record._id} onClick={this.showDeleteConfirm}>
							<img src="/iconos/trash.svg" alt="delete" />
						</Button>
					</div>
				),
			},
		];
		return (
			<Content className="page-main" style={{ padding: '30px' }}>
				<Row className="controls">
					<Col xs={24} md={20} className="filters">
						<Dropdown className="filter-control" trigger={['click']} overlay={<Menu>
							<Menu.Item onClick={() => { this.handlefilterSort('nombre', 1) }}>A-Z</Menu.Item>
							<Menu.Item onClick={() => { this.handlefilterSort('nombre', -1) }}>Z-A</Menu.Item>
						</Menu>} placement="bottomLeft">
							<Button type="link">Nombre <ArrowIcon /> </Button>
						</Dropdown>
						<Dropdown
							className="filter-control"
							trigger={['click']}
							placement="bottomLeft"
							onVisibleChange={(flag) => this.setState({ visible: flag })}
							visible={this.state.visible}
							overlay={
								<Menu onClick={(e) => this.handleMenuClick(e)}>
									<Menu.Item key="1">
										<Row>
											<InputNumber
												style={{ width: '100%' }}
												min={0} max={100}
												placeholder="Edad Mínima"
												onChange={value => this.handleSearchEdad(value, 'min')} />
										</Row>
										<Row className="mt-1">
											<InputNumber
												style={{ width: '100%' }}
												min={0} max={100}
												placeholder="Edad Máxima"
												onChange={value => this.handleSearchEdad(value, 'max')} />
										</Row>
									</Menu.Item>
									<Menu.Item key="2">Buscar</Menu.Item>
								</Menu>
							}>
							<Button type="link" onClick={e => e.preventDefault()}>
								Rango de Edad<ArrowIcon />
							</Button>
						</Dropdown>
						<Dropdown className="filter-control" trigger={['click']} placement="bottomLeft"
							overlay={
								<Menu>
									<Menu.Item>
										<Radio
											value={false}
											onClick={(e) => this.handleFilterSexo(e.target.value)}
											checked={this.state.searchSexo === 'false'}
										>
											Femenino
									</Radio>
									</Menu.Item>
									<Menu.Item>
										<Radio
											value={true}
											onClick={(e) => this.handleFilterSexo(e.target.value)}
											checked={this.state.searchSexo === 'true'}
										>
											Masculino
									</Radio>

									</Menu.Item>
									<Menu.Item>
										<Radio
											value={'null'}
											onClick={(e) => this.handleFilterSexo(e.target.value)}
											checked={this.state.searchSexo === 'null'}
										>
											No Aplica
									</Radio>

									</Menu.Item>
								</Menu>}>
							<Button type="link">Sexo <ArrowIcon /> </Button>
						</Dropdown>
						<Dropdown className="filter-control" trigger={['click']} placement="bottomLeft"
							overlay={
								<Menu>
									<Menu.Item>
										<Radio
											value={true}
											onClick={(e) => this.handleFilterHijos(e.target.value)}
											checked={this.state.searchHijos === 'true'}
										>
											Si
									</Radio>
									</Menu.Item>
									<Menu.Item>
										<Radio
											value={false}
											onClick={(e) => this.handleFilterHijos(e.target.value)}
											checked={this.state.searchHijos === 'false'}
										>
											No
									</Radio>
									</Menu.Item>
								</Menu>}>
							<Button type="link">Hijos <ArrowIcon /> </Button>
						</Dropdown>
						<Search
							className="search-control"
							placeholder="Buscar"
							size="normal"
							bordered={false}
							enterButton
							onSearch={value => this.handleSearch(value)}
						/>
					</Col>
					<Col span={4}>
						<Button className="add-control" onClick={() => this.setState({ modalShowToggle: true, titleModal: 'Crear Perfil', id_selected: null })}>
							<svg style={{ marginTop: '0.3em' }} width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6.4298 11.5702C6.31805 11.5702 6.24069 11.5702 6.16046 11.5702C4.35817 11.5702 2.55587 11.5702 0.753582 11.5702C0.246418 11.5702 0 11.3266 0 10.8223C0 9.60172 0 8.38395 0 7.16332C0 6.68195 0.249284 6.4298 0.727794 6.4298C2.54441 6.4298 4.35817 6.4298 6.17478 6.4298C6.25215 6.4298 6.33238 6.4298 6.43266 6.4298C6.43266 6.33238 6.43266 6.25215 6.43266 6.17478C6.43266 4.36676 6.43266 2.55874 6.43266 0.747851C6.43266 0.243553 6.67622 0 7.18338 0C8.39542 0 9.60745 0 10.8195 0C11.3266 0 11.5673 0.243553 11.5673 0.753582C11.5673 2.55587 11.5673 4.35817 11.5673 6.16046C11.5673 6.24069 11.5673 6.32092 11.5673 6.43266C11.6533 6.43266 11.7307 6.43266 11.808 6.43266C13.616 6.43266 15.4241 6.43266 17.235 6.43266C17.7593 6.43266 18 6.67049 18 7.18911C18 8.40115 18 9.61318 18 10.8252C18 11.3209 17.7536 11.5673 17.2607 11.5673C15.4527 11.5673 13.6447 11.5673 11.8338 11.5673C11.7536 11.5673 11.6762 11.5673 11.5673 11.5673C11.5673 11.6562 11.5673 11.7335 11.5673 11.8138C11.5673 13.6218 11.5673 15.4298 11.5673 17.2407C11.5673 17.7622 11.3266 18 10.8052 18C9.59312 18 8.38109 18 7.16905 18C6.67622 18 6.4298 17.7507 6.4298 17.255C6.4298 15.447 6.4298 13.639 6.4298 11.8281C6.4298 11.7507 6.4298 11.6734 6.4298 11.5702Z" fill="white" />
							</svg>
						</Button>
					</Col>
				</Row>

				<div className="page-content">
					<h5 className="items-number">Perfiles {this.state.itemCount}</h5>
					<Table
						columns={columns}
						dataSource={profiles}
						className="admin-table"
						loading={loading}
						scroll={{ x: 1200 }}
						/**Paginacion de la tabla controlada desde el WebService */
						pagination={{
							onChange: this.handlePageClick,
							total: this.state.itemCount,
							current: this.state.currentPage
						}}
					/>

					<Modal
						visible={this.state.modalShowToggle}
						footer={null}
						title={null}
						destroyOnClose={true}
						closeIcon={<Button type="ghost" className="closable-button" onClick={this.ModalPopUpHandler}>
							<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
									fill="#E1E4ED" />
								<path d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
									fill="#E1E4ED" />
							</svg>
						</Button>}
						className="modal-form"

					>
						<Row>
							<Col xs={{ span: 20, offset: 2 }} sm={{ span: 16, offset: 4 }} className="box-table">
								<FormPerfil title={this.state.titleModal} id_profile={this.state.id_selected} UpdateTable={this.ReloadTable} close={() => this.setState({ modalShowToggle: false })} />
							</Col>

						</Row>
					</Modal>
				</div>
			</Content>

		)
	}
}

export default Perfiles;
