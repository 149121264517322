import React, { Component } from 'react';
import { Row, Col, Spin, Button, Switch, Descriptions } from 'antd'
//import { EyeInvisibleOutlined } from '@ant-design/icons';


const axios = require("axios").default;


/**
 * @class DepartamentosView
 * @description  Clase que contiene el modal para visualizar un departamento
 *
 **/
export default class DepartamentosView extends Component {


    constructor(props) {
        super(props)
        this.state = {
            nombreVista: '',
            countries: [],
            states: [],
            department: [],

            id_dep: '',
            loading: false,
        }
    }



    /**
    * @memberof DepartamentosView
    *
    * @method componentDidMount
    * @description Si se recibe un ID Cargar los datos del Departamento,
    * En caso contrario mostrar formulario vacio
    * Carga de paises al abrir el formulario
    **/
    componentDidMount() {
        this.GetCountries();
        axios.defaults.headers.get['Authorization'] = sessionStorage.getItem('token');

        var id = this.props.id_dep;
        this.LoadDataDep(id);
        this.setState({ loading: false });
    }

    /**
    * @memberof DepartamentosView
    *
    * @method LoadDataDep
    * @description Metodo que envia una solicitud al servidor para cargar los datos de un departamento
    */
    LoadDataDep = (id_dep) => {

        this.setState({ loading: true })
        axios.post('/departamentos/get', { id: id_dep },
            {  headers: { Authorization: sessionStorage.getItem('token') }})
            .then((response) => {
                this.GetStateByCountry(response.data.data.pais_id);
                this.setState({
                    id_dep: response.data.data._id,
                    department: response.data.data
                });
            })
            .catch((response) => {
                console.log('error', response);
            })
            .finally(res => {
                this.setState({ loading: false })
            })
    }

    /**
     * @memberof DepartamentosView
     *
     * @method GetCountries
     * @description Metodo que manda a llamar a los paises y guardarlos en el state.countries
     *
     **/
    GetCountries = (country) => {

        axios.get('/countries', {
            params: { search: country }
        })
            .then((response) => {
                this.setState({ countries: response.data.data });
            })
            .catch((response) => {
                console.log('error', response);
            })
    }
    /**
     * @memberof DepartamentosView
     *
     * @method GetStateByCountry
     * @description  Al cambiar el pais seleccionado, se actualizan el state.states para el  select estados
     *
     **/
    GetStateByCountry = value => {
        axios.post('/statesByCountry', { pais_id: value },
            { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                var pais_estados = res.data.data;
                this.setState({ states: pais_estados });
            })
            .catch((error) => {
                console.log(error.response)
            });
    }

    /**
     * @memberof DepartamentosView
     *
     * @method closeModal
     * @description  Cierra el card
     * 
     */
    closeModal = () => {
        this.props.close();
    }

    /**
     * @memberof DepartamentosView
     *
     * @method ShowCountry
     * @description  Busca el pais asignado al id de los datos del departamento
     * 
     */
    ShowCountry = (country_id) => {
        var countries = this.state.countries;
        var name = ''
        countries.some(country => {
            if (country._id === country_id) {
                name = country.name
            }
        });

        return name
    }


    /**
     * @memberof DepartamentosView
     *
     * @method ShowState
     * @description  Busca el estado asignado al id de los datos del departamento
     * 
     */
    ShowState = (state_id) => {
        var states = this.state.states;
        var name = ''
        states.some(state => {
            if (state._id === state_id) {
                name = state.name
            }
        });

        return name
    }

    render() {
        var dep = this.state.department;
        return (
            <Row>
                <Col span={24}>
                    <h3 className="modal-title"> Departamento: {dep.nombre} </h3>
                    <Spin tip="Espere un momento por favor..." spinning={this.state.loading}>
                        <Descriptions column={1} className="description-modal-content">
                            <Descriptions.Item label="Nombre" >{dep.nombre}</Descriptions.Item>
                            <Descriptions.Item label="Número">{dep.number}</Descriptions.Item>
                            <Descriptions.Item label="País">{this.ShowCountry(dep.pais_id)}</Descriptions.Item>
                            <Descriptions.Item label="Estado">{this.ShowState(dep.estado_id)}</Descriptions.Item>
                            <Descriptions.Item label="Activo">
                                <Switch size={'small'} checked={dep.activo} disabled />
                            </Descriptions.Item>
                        </Descriptions>,

                    </Spin>
                    <Button htmlType="submit" className="v-button-primary mt-3" onClick={this.closeModal}>Cerrar</Button>

                </Col>
            </Row>
        )
    }
}