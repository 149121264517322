import React, {Component} from 'react';
//import { DatePicker, Typography} from 'antd';
//import {Link} from "react-router-dom";
import ReactEcharts from 'echarts-for-react';

const axios = require('axios')

//const {RangePicker} = DatePicker;
//const {Title} = Typography;

export default class Respuestas_CDN_Departamentos extends Component {


    state = {
        data: []
    }

    componentDidMount() {

        let  url = '/analytics/respuestas_por_departamento';

        if (this.props.tipo === "voc")
            url = '/analytics/respuestas_por_centro_de_negocios';

        axios.get(url)
            .then(({data}) => {
                this.setState({data: data.data})
            })

    }


    render() {

        const {data} = this.state;
        return (
            <ReactEcharts
                option={{
                    title: {
                        text: (this.props.tipo === "voc")?'CDN':'Departamentos',
                        x: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    series: [{
                        name: 'Departamentos',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '40%'],
                        data: data.map(dep => {
                            let m = {
                                value: (dep.usuarios !== undefined) ? dep.usuarios.cantidad : 0,
                                name: dep.nombre
                            };
                            return m
                        }),
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                    ]
                }}
                style={{height: 350}}
            />
        )
    }
}
