import React from 'react'
import {Redirect, Route, Switch} from "react-router-dom";
import Login from "../components/Auth/Login";
// import Index from "../components/Landing/index"

// import PasswordForgot from "../components/Auth/PasswordForgot"
import PasswordRecovery from "../components/Auth/PasswordRecovery"
import EncuestaExterna from  "../components/Public/EncuestaExterna/EncuestaExterna"


function Index() {
    return <Redirect to={'/login'} />
}

function PublicRoutes(props) {
    return(
	    <Switch>
            <Route exact path="/" render={Index}/>
	        <Route exact path="/login" component={Login}/>

            <Route
                // exact
                path="/recovery/email-:email/token-:token"
                component={PasswordRecovery}
            />


            <Route
                exact
                path="/register/email-:email/token-:token"
                render={(props)=><PasswordRecovery {...props} title="Bienvenido" />}
            />

        {/*Ruta para responder encuestas*/}
            <Route
                exact
                path="/encuesta/:id"
                component={EncuestaExterna}
            />
	    </Switch>
    )

}



export default PublicRoutes;
