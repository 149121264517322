import React,{Component} from 'react';
import ReactEcharts from 'echarts-for-react';

const axios = require('axios')

export default class RespuestaPorEncuestas extends Component {



    constructor(props) {
        super(props)
        this.state = {
            data: []
        }
    }


    componentDidMount() {

        axios.get('/analytics/respuestas_por_encuestas',{
            params: {
                tipo: this.props.tipo
            }
        })
            .then(({data})=>{

                this.setState({data: data.data})

            })

    }



    render() {

        const {data} = this.state;


       /* console.log(" data.map(encuesta => encuesta.nombre),", data.map(encuesta => encuesta.nombre))
        console.log("sa,",data.map(encuesta => (encuesta.resultados !== undefined && encuesta.resultados.cantidad !== undefined)?encuesta.resultados.cantidad:0))*/

        return (
            <ReactEcharts
                option={ {
                    color: ['#3398DB'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {           
                            type: 'shadow'        
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: data.map(encuesta => encuesta.nombre),
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: 'Respuestas',
                            type: 'bar',
                            barWidth: '60%',
                            data: data.map(encuesta => (encuesta.resultados !== undefined && encuesta.resultados.cantidad !== undefined)?encuesta.resultados.cantidad:0)
                        }
                    ]
                }}
                style={{ height: '400px', width: '100%' }}
                opts={{ renderer: 'svg' }}
                className='react_for_echarts' />
        )
    }
}
