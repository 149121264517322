import React, { Component } from 'react';
import { Row, Col, Input, Button, Table, Modal, message, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';


//css
import '../../../styles/analytics.css';

//componentes
import ModalSendReport from './Modals/ModalSendReport';

const { RangePicker } = DatePicker;
const { Search } = Input;
const axios = require('axios').default;
const moment = require("moment")


export default class Reportes extends Component {

	constructor(props) {
		super(props)
		this.state = {
			visible: false,

			reports: [],
			loading: false,


			/**paginador */
			currentPage: null,
			itemCount: null,
			perPage: null,
			pageCount: null,

			/*filters*/
			search: null,
			field: null,

			id_selected: null,
			createPDF: false,
			downloading: false
		}
	}

	/**
	* @memberof Analytics
	*
	* @method componentDidMount
	* @description Cambia el titulo del Navbar al entrar a este componente,
	**/
	componentDidMount() {
		this.props.updateNavigationInformation('Reportes');
		this.LoadData()
	}



	/**
	* @memberof Analytics
	*
	* @method   handleSearch
	* @description busca los registros que conicidan con la busqueda
	*
	**/

	LoadData(page) {

		console.log('obteniendo los datos de la vista', this.state);
		this.setState({ loading: true })
		axios.get('/reportes',
			{
				headers: { Authorization: sessionStorage.getItem("token") },
				params: {
					page: page,
					limit: 10,
					search: this.state.search,
				}
			})
			.then((res) => {
				const data = res.data.data.itemsList;
				const paginator = res.data.data;
				console.log('res', res)
				this.setState({
					reports: data,
					currentPage: paginator.currentPage,
					itemCount: paginator.itemCount,
					pageCount: paginator.pageCount,
					loading: false,
				});
			})
			.catch((res) => {
				console.log('error', res);

			})

	}

	/**
	* @memberof Analytics
	*
	* @method   handlePageClick
	* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	*
	**/
	handlePageClick = page => {
		console.log("pagina", page);//2
		this.LoadData(page);
	};

	/**
	* @memberof Analytics
	*
	* @method   handleSearch
	* @description busca los registros que conicidan con la busqueda
	*
	**/
	handleSearch = (value) => {
		this.setState({ search: value });
		this.LoadData();
	}

	/**
		* @memberof Analytics
		*
		* @method ModalPopUpHandler
		* @description  cambia el state para mostrar u ocultar el modal
		*
		**/
	ModalPopUpHandler = (item) => {
		this.GetEncuesta(item);
		const changeModal = this.state.visible;
		this.setState({ visible: !changeModal });
	}

	/**
	* @memberof Analytics
	*
	* @method   GetEncuesta
	* @description  Metodo que asigna al state.id_selected el id del usuario seleccionado para mostrar el modal con los datos de ese usuario
	*
	**/
	GetEncuesta(item) {
		const encuesta_id = item;
		this.setState({ id_selected: encuesta_id })

	}

	/**
	* @memberof Reportes
	*
	* @method handlePrintReport
	* @description  Metodo handlePrintReport, descarga el pdf del reporte
	*
	* @params record (ID)
	**/
	handlePrintReport = (record) => {

		console.log('IMPRIMIENDO ')
		this.setState({ createPDF: true })
		message.loading('Generando documento, por favor espere....', () => { return this.state.createPDF })
		axios.post('/reporte/stream',
			{ id: record },
			{
				headers: { Authorization: sessionStorage.getItem("token") }
			})
			.then(res => {

				console.log(res.data);
				

				this.DownloadReport(res.data.filename);
			}).catch(error => {
				message.error(error.data.message);
			}).finally(f => {
				this.setState({ createPDF: false })
			})

	}

	/**
	* @memberof Reportes
	*
	* @method handlePrintReport
	* @description  Metodo DownloadReport, descarga el pdf del reporte
	* 
	**/

	DownloadReport = (filename) => {
		console.log('filename',filename);
		this.setState({ downloading: true });
		message.loading('Descargando documento', () => { return this.state.downloading });

		window.open(`${axios.defaults.baseURL}/docs/${filename}`, "_blank") 		 
	}






	render() {


		var data = this.state.reports;
		const columns = [
			{
				title: '',
				dataIndex: 'id',
				width: '5%',
				key: 'id',
			}, {
				title: 'Encuesta',
				dataIndex: 'nombre',
				width: '55%',
				key: 'nombre',
				render: (value, i) => {
					return <p style={{ color: '#000' }}>{value}</p>
				}
			}, {
				title: 'Fecha',
				dataIndex: 'fecha_inicio',
				width: '20%',
				key: 'fecha_inicio',
				render: (value, i) => {
					return <p>{moment(value).format("DD-MMM-YYYY")}</p>
				}
			}, {
				title: 'Acciones',
				dataIndex: '_id',
				width: '20%',
				key: 'actions',
				render: (record, i) => (
					<div>
						<Button className={`btn-send`} onClick={() => { this.ModalPopUpHandler(record) }}   >
							<img src={"/iconos/mail.svg"} alt="send" style={{ width: '20px' }} />
						</Button>
						<Button className={`btn-print-pdf`} onClick={() => { this.handlePrintReport(record) }}>
							<img src={'/iconos/pdf-white.svg'} alt="send" style={{ width: '20px' }} />
						</Button>
					</div>
				)
			},
		]


		return (
			<div className="page-main" style={{ padding: '30px' }}>
				<Row className="controls">
					<Col xs={24} xl={20} className="filters filters-encuestas">
						<RangePicker
							locale={locale}
							className="filter-control ranger-picker-control"
						/>
						<Search
							className="search-control filter-control"
							placeholder="Buscar"
							size="normal"
							bordered={false}
							enterButton
							onSearch={value => this.handleSearch(value)}
						/>


					</Col>
				</Row>
				<div className="page-content">
					<div>
						<h5 className="items-number">Reportes {this.state.itemCount} </h5>
						<Table
							className="admin-table"
							loading={this.state.loading}
							dataSource={data}
							scroll={{ x: 920 }}
							columns={columns}
							pagination={{
								onChange: this.handlePageClick,
								total: this.state.itemCount,
								current: this.state.currentPage
							}}
						/>
					</div>
				</div>

				<Modal
					visible={this.state.visible}
					footer={null}
					title={null}
					className="modal-form text-center"
					destroyOnClose={true}
					closeIcon={
						<Button type="ghost" className="closable-button" onClick={() => this.ModalPopUpHandler()}>
							<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z" fill="#E1E4ED" />
								<path d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z" fill="#E1E4ED" />
							</svg>
						</Button>}
				>
					<ModalSendReport encuesta={this.state.id_selected} close={this.ModalPopUpHandler} />


				</Modal>
			</div>
		)
	}
}

