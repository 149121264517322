import React, {Component} from 'react';
import {Button, Col, Modal, Radio, Row, Typography} from 'antd'
import PropTypes from 'prop-types';

import '../../../styles/Modals/ModalEncuesta.css';

import {Link} from "react-router-dom";

const {Title, Paragraph} = Typography;

/**
 * @function NuevaEncuestaVozDelCliente
 * @description Radio button de nueva encuesta.
 * */
function NuevaEncuestaVozDelCliente(props) {
    /**
     * @const checked
     * @description Obtiene si RadioButton debe de estar Activado. En caso de que si, solamente debe de activarse.
     * */
    const checked = props.checked;

    /**
     * @const getColor
     * @description Obtenemos el color de fondo y de algunos elementos, si checked esta true es blanco, si esta fuera, es el rosa*/
    const getColor = () => (checked) ? 'white' : 'rgb(153, 78, 144)'

    /*
    EL SVG Lo saque de figma, y sustitui el atributo de el color de llenado "FILL", con el metodo getColor, para obtener el color correspondiente segun si esta checked o no este radio button
    * */
    return <Radio.Button value={'voc'} className="button-select" style={{
        borderColor: getColor(),
        background: ((checked) ? 'linear-gradient(90deg, #9A4F91 0%, #76236C 102.04%)' : 'white')
    }}>
        <svg className="image-content" width="75" height="85" viewBox="0 0 75 85" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M52.1911 30.8593C52.2031 31.088 52.3001 31.2889 52.4197 31.475C53.5269 33.1946 53.2013 35.865 51.5558 37.4329C50.1336 38.7881 47.5165 39.2203 45.7234 37.9888C45.5812 37.8917 45.4058 37.8425 45.245 37.772C45.0482 37.5433 44.8887 37.2707 44.5538 37.2148C44.4727 36.8863 44.2747 36.6204 44.0979 36.3411C42.3819 33.6135 44.0886 29.7169 47.256 29.1344C47.4288 29.1025 47.6082 29.1079 47.7836 29.0972C48.088 29.0959 48.3937 29.0932 48.6981 29.0919C49.8 29.0826 50.6334 29.7582 51.5531 30.213C51.6342 30.5574 51.8495 30.7702 52.1911 30.8593Z"
                fill={getColor()}/>
            <path
                d="M16.4076 43.4996C12.8109 43.1193 10.9142 39.3251 12.7604 36.1493C12.8481 35.9991 12.9239 35.8421 13.0063 35.6879C13.2402 35.5004 13.5047 35.3368 13.5685 35.0083C14.3115 34.6944 14.9322 34.1186 15.7629 33.9936C15.9783 33.9617 16.1936 33.9258 16.4102 33.8912C16.7106 33.8899 17.0097 33.8885 17.3101 33.8872C19.4221 34.1612 20.8722 35.3049 21.481 37.3329C22.0392 39.1934 21.5541 40.8744 20.1518 42.2362C19.3729 42.9929 18.4213 43.4344 17.3181 43.5036C17.015 43.5023 16.712 43.5009 16.4076 43.4996Z"
                fill={getColor()}/>
            <path
                d="M39.4499 70.8796C39.4473 75.0674 39.4566 79.2552 39.4273 83.443C39.4233 84.0414 39.6985 84.064 40.1504 84.0627C46.8387 84.0547 53.527 84.056 60.214 84.0574C60.4134 84.0574 60.6128 84.0521 60.8108 84.0693C61.0793 84.0919 61.1046 84.3007 61.1019 84.5082C61.1006 84.7117 61.0913 84.9258 60.8241 84.9617C60.6712 84.9816 60.5144 84.9737 60.3602 84.9737C46.0984 84.9737 31.8365 84.975 17.5746 84.9657C17.3407 84.9657 17.023 85.1226 16.8821 84.7675C16.7147 84.346 16.918 84.06 17.4032 84.06C19.6614 84.0547 21.921 84.0574 24.1792 84.0574C28.7196 84.0574 33.2587 84.0467 37.7991 84.0707C38.3866 84.0733 38.5421 83.9191 38.5381 83.3313C38.5075 79.1887 38.5142 75.0448 38.5089 70.9008C38.5222 70.333 38.5355 69.7651 38.5474 69.1973C38.8106 69.1986 39.0738 69.2013 39.3356 69.2026C39.3888 69.7625 39.1881 70.337 39.4499 70.8796Z"
                fill={getColor()}/>
            <path
                d="M29.4041 18.24C27.7493 19.5778 25.9337 19.6789 24.0822 18.7879C22.325 17.9421 21.3361 15.9672 21.5328 13.9923C21.7535 11.7634 23.7924 9.79655 25.9789 9.70345C28.5136 9.59573 30.6176 11.1677 31.0868 13.5136C31.346 14.8089 31.1094 16.0058 30.4103 17.1202C30.3053 17.2864 30.2322 17.4726 30.1445 17.6495C29.4932 17.1934 29.0054 16.5656 28.4378 16.0231C28.8499 15.3009 29.0054 14.5229 28.7661 13.7263C28.4086 12.5374 27.0608 11.7661 25.9005 12.0294C24.5952 12.3247 23.6661 13.5082 23.8084 14.6972C23.9745 16.0975 25.0245 17.0125 26.3962 17.0444C26.9611 17.0577 27.3944 16.7266 27.9115 16.6454C28.3767 17.2067 28.9868 17.6335 29.4041 18.24Z"
                fill={getColor()}/>
            <path
                d="M39.4233 22.8638C40.3324 23.1883 41.211 23.5167 41.8424 24.3439C43.3922 26.3747 42.3554 29.6648 39.7104 30.0824C39.6041 30.0997 39.5017 30.1529 39.398 30.1888C39.099 30.1888 38.8013 30.1888 38.5022 30.1888C37.2554 29.9002 36.2307 29.3483 35.6724 28.0915C35.2431 27.1247 35.2697 26.1885 35.5794 25.2203C35.6458 25.0129 35.711 24.8054 35.7774 24.5979C36.0433 24.4384 36.2466 24.2269 36.337 23.921C37.0056 23.4529 37.7167 23.0739 38.5142 22.8731C38.8172 22.8691 39.1203 22.8664 39.4233 22.8638Z"
                fill={getColor()}/>
            <path
                d="M64.0193 50.8103C64.0446 51.1308 64.2413 51.3822 64.3636 51.6628C65.0707 53.2852 64.6028 55.05 63.1966 56.0714C61.9471 56.9783 60.0624 57.0994 58.6442 55.812C58.5126 55.6923 58.385 55.5726 58.2042 55.5341C58.1577 55.2189 58.0089 54.9569 57.7882 54.7322C57.1236 53.3637 57.4865 52.0777 58.1046 50.8117C58.4501 50.7279 58.6668 50.5138 58.7545 50.168C59.4005 50.0151 59.9388 49.5789 60.602 49.4685C60.9117 49.4672 61.2214 49.4672 61.5311 49.4658C62.2037 49.5589 62.7526 49.9459 63.3428 50.2372C63.5275 50.4779 63.6857 50.7478 64.0193 50.8103Z"
                fill={getColor()}/>
            <path
                d="M55.1672 26.5862C54.2554 25.0848 54.0853 23.578 55.1592 22.0753C56.0179 20.873 57.6806 20.361 59.1746 20.8199C60.529 21.2361 61.5764 22.6484 61.6761 24.0036C61.801 25.7205 60.6141 27.206 59.362 27.6581C58.3465 28.0252 57.3776 27.9813 56.4206 27.4945C56.2066 27.3855 56.0086 27.2259 55.7441 27.2605C55.655 26.948 55.441 26.7418 55.1672 26.5862Z"
                fill={getColor()}/>
            <path
                d="M67.9071 31.4721C67.511 30.6901 67.1123 29.944 67.091 29.0012C67.0405 26.8082 69.3147 25.078 71.2699 25.4863C72.6788 25.7802 73.6863 26.5103 74.1223 27.8388C74.5941 29.2764 74.3403 30.6356 73.1959 31.7381C72.1817 32.7142 70.5282 32.9868 69.2522 32.3817C69.0329 32.278 68.8163 32.1689 68.597 32.0612C68.3604 31.871 68.2142 31.5771 67.9071 31.4721Z"
                fill={getColor()}/>
            <path
                d="M33.4235 51.6001C32.6393 52.011 31.8484 52.3195 30.9193 52.2863C29.0625 52.2185 27.5566 50.7024 27.4914 48.8485C27.4276 47.0173 28.5508 45.7871 29.759 45.3004C31.1546 44.7379 32.7695 45.1488 33.8621 46.3231C34.7194 47.2447 34.96 48.9789 34.4044 50.1771C34.2754 50.455 34.0654 50.6984 34.0282 51.0189C33.7039 51.0854 33.5298 51.3061 33.4235 51.6001Z"
                fill={getColor()}/>
            <path
                d="M45.4496 7.40779C44.9525 6.56597 44.5857 5.70687 44.6349 4.67754C44.7173 2.93273 46.2737 1.40603 48.0282 1.31293C50.1708 1.19989 51.7233 2.90879 51.8429 4.57913C51.9678 6.31462 51.1929 7.5288 49.6963 8.26822C48.8416 8.68979 47.8953 8.63261 46.9875 8.34003C46.6964 8.24561 46.4212 8.10065 46.1381 7.9783C45.968 7.71499 45.7819 7.47295 45.4496 7.40779Z"
                fill={getColor()}/>
            <path
                d="M7.1407 44.0213C6.04814 44.7913 4.8612 45.04 3.55995 44.6769C2.12047 44.2753 0.913598 42.5278 1.0731 41.0663C1.27646 39.1898 2.76644 37.7283 4.43454 37.6179C7.45704 37.4171 9.27799 40.6247 7.86908 43.0438C7.81459 43.1369 7.79997 43.2526 7.76674 43.359C7.45438 43.4813 7.2909 43.7446 7.1407 44.0213Z"
                fill={getColor()}/>
            <path
                d="M16.4103 23.1566C14.3886 22.9332 13.1432 21.3028 13.2828 19.0632C13.3891 17.369 15.0306 15.9526 16.8901 15.9526C18.747 15.9526 20.318 17.4288 20.4735 19.1843C20.6769 21.4743 18.8347 23.0595 17.3154 23.1593C17.0137 23.1593 16.712 23.1579 16.4103 23.1566Z"
                fill={getColor()}/>
            <path
                d="M13.72 49.3419C14.556 48.822 15.4439 48.431 16.4209 48.2607C16.5498 49.0068 16.4674 49.7582 16.4687 50.5069C16.2707 50.6027 16.0766 50.7051 15.8759 50.7915C14.5308 51.3687 13.9234 53.0231 14.5959 54.2838C15.311 55.6243 16.9552 56.0579 18.2298 55.2559C18.4292 55.1309 18.5794 54.9035 18.8545 54.9115C19.3769 55.3756 20.0973 55.5844 20.5133 56.1868C18.9662 58.2788 15.743 58.574 13.7679 56.8066C11.8472 55.0884 11.4937 52.6733 12.8348 50.4723C12.9252 50.3234 12.9876 50.1558 13.0621 49.9962C13.3558 49.8513 13.5884 49.6465 13.72 49.3419Z"
                fill={getColor()}/>
            <path
                d="M60.5902 35.9099C58.7333 35.6944 57.3071 33.9549 57.4228 32.0466C57.5304 30.2632 59.152 28.7817 60.9357 28.7019C62.5852 28.6288 64.5152 29.819 64.7119 32.1915C64.8554 33.9164 63.4957 35.5229 61.5365 35.9152C61.2215 35.9125 60.9052 35.9112 60.5902 35.9099ZM61.0819 34.2063C62.1187 34.1996 62.9747 33.3512 62.9826 32.3232C62.9906 31.2965 62.1293 30.4414 61.0846 30.4387C60.0279 30.4361 59.1653 31.2619 59.1507 32.2886C59.136 33.3352 60.0199 34.2129 61.0819 34.2063Z"
                fill={getColor()}/>
            <path
                d="M26.1503 33.3781C24.4782 33.0084 23.4215 32.0229 23.0573 30.3207C22.6386 28.3631 24.3373 26.2898 26.3377 26.1635C28.3779 26.0358 29.9091 27.4162 30.2069 29.1238C30.5179 30.9112 29.4227 32.7557 27.6708 33.2371C27.4794 33.2903 27.2854 33.3329 27.0926 33.3807C26.7776 33.3794 26.464 33.3781 26.1503 33.3781ZM26.6381 27.8976C25.5894 27.899 24.7374 28.7288 24.7267 29.7595C24.7174 30.7689 25.604 31.6639 26.6168 31.6692C27.6775 31.6745 28.5813 30.7808 28.5614 29.7462C28.5428 28.7115 27.6934 27.8963 26.6381 27.8976Z"
                fill={getColor()}/>
            <path
                d="M67.8048 45.7178C67.4831 44.9518 67.0671 44.239 67.0963 43.34C67.1588 41.4024 68.7764 39.76 70.7156 39.7666C72.7187 39.7733 74.2884 41.3359 74.303 43.3254C74.3136 44.8614 73.6783 45.9998 72.2814 46.682C70.9615 47.327 69.7227 47.1408 68.5504 46.3057C68.8242 45.8282 69.3014 45.537 69.6323 45.1114C69.8224 45.1553 70.0165 45.1899 70.2039 45.2431C71.0944 45.4971 72.0727 45.0463 72.4595 44.1978C72.821 43.4039 72.5219 42.3626 71.7843 41.8439C71.0187 41.3053 70.0391 41.4077 69.3718 42.0819C68.6355 42.8267 68.7764 43.6698 69.0754 44.5356C68.6355 44.9119 68.3032 45.404 67.8048 45.7178Z"
                fill={getColor()}/>
            <path
                d="M47.8076 27.9599C45.6836 27.686 44.5339 26.0409 44.5618 24.3081C44.5937 22.3611 46.1994 20.7799 47.9884 20.7413C50.0366 20.6961 51.6635 22.0792 51.823 24.0022C51.9878 25.9731 50.6946 27.5982 48.6796 27.9533C48.5799 27.42 48.641 26.8814 48.6423 26.3454C48.7367 26.1393 48.956 26.118 49.1235 26.0223C49.8771 25.5941 50.2785 24.6804 50.0566 23.8812C49.8173 23.0221 49.0039 22.4249 48.128 22.4635C47.1883 22.5061 46.4439 23.1923 46.2911 24.1578C46.1488 25.0488 46.7656 25.8826 47.8449 26.2577C47.8316 26.8269 47.8196 27.3934 47.8076 27.9599Z"
                fill={getColor()}/>
            <path
                d="M53.4964 46.2843C52.9115 46.9293 52.1194 47.0609 51.3272 47.0503C49.5687 47.0277 48.0575 45.6712 47.8461 44.0048C47.5949 42.0207 48.637 40.3982 50.5031 39.9115C52.0675 39.5019 53.3488 40.0232 54.3098 41.2613C55.1977 42.4077 55.2681 43.6897 54.6594 45.0062C54.5491 45.2456 54.4414 45.4877 54.3324 45.7284C53.9111 45.3361 53.4897 44.9424 53.0684 44.5501C53.0205 44.3333 53.1707 44.1751 53.2305 43.9902C53.507 43.1338 53.1016 42.1497 52.3015 41.7334C51.4295 41.2799 50.4353 41.5299 49.8452 42.3518C49.3521 43.0367 49.4797 44.1551 50.1017 44.7722C50.8194 45.4837 51.6422 45.3813 52.4929 45.1086C52.8398 45.489 53.2452 45.8201 53.4964 46.2843Z"
                fill={getColor()}/>
            <path
                d="M38.5128 7.22186C36.3277 6.93328 35.2643 4.89723 35.3707 3.47824C35.5235 1.43687 37.1212 -0.0339799 39.079 0.000597051C40.9664 0.0325142 42.5747 1.60577 42.6518 3.49287C42.7143 5.01558 41.5964 7.05562 39.3595 7.22452C39.3528 6.68592 39.3462 6.14864 39.3395 5.61137C39.5721 5.48769 39.8087 5.37199 40.036 5.23901C40.8574 4.75493 41.1538 3.7123 40.7325 2.80931C40.3165 1.9143 39.2172 1.5007 38.2642 1.87839C37.4083 2.21751 36.9524 3.20428 37.1664 4.0993C37.3511 4.87595 37.9559 5.21374 38.5434 5.59142C38.5327 6.13402 38.5234 6.67794 38.5128 7.22186Z"
                fill={getColor()}/>
            <path
                d="M5.74506 28.5664C4.15007 30.036 1.097 29.2247 0.275579 27.1129C-0.40495 25.3641 0.207792 23.469 1.75094 22.5447C3.25422 21.6444 5.21872 21.9822 6.42294 23.3919C7.48626 24.6366 7.5062 26.7325 6.48009 27.8297C5.979 27.6448 5.74108 27.1448 5.32638 26.8562C5.24264 26.5769 5.41676 26.3429 5.46993 26.0902C5.68525 25.0649 5.08314 24.0927 4.04507 23.8374C3.06415 23.5967 2.00348 24.2496 1.7536 25.2484C1.50638 26.2352 2.159 27.2605 3.15188 27.5384C3.72474 27.698 4.22451 27.4015 4.76415 27.3961C5.0539 27.8177 5.56829 28.0504 5.74506 28.5664Z"
                fill={getColor()}/>
            <path
                d="M38.5075 48.197C35.8 47.9257 34.1984 45.7261 34.1678 43.4227C34.1359 40.9372 35.9449 38.9703 38.5049 38.6392C38.5182 39.3892 38.5315 40.1393 38.5447 40.8893C37.7898 41.0928 37.1877 41.5104 36.7956 42.1913C35.9436 43.6728 36.7172 45.3192 38.5447 45.9415C38.5328 46.6956 38.5195 47.4456 38.5075 48.197Z"
                fill={getColor()}/>
            <path
                d="M38.5128 7.22196C38.5235 6.67804 38.5328 6.13412 38.5434 5.58887C38.8092 5.59552 39.0751 5.60217 39.3396 5.60882C39.3462 6.14609 39.3528 6.68469 39.3595 7.22196C39.5349 8.03319 39.4193 8.8524 39.4353 9.66762C39.4565 10.8392 39.4406 12.0109 39.4406 13.3727C41.518 11.31 43.4839 9.35775 45.4497 7.40682C45.7807 7.47331 45.9681 7.71402 46.1369 7.97601C44.0634 10.0573 41.9965 12.1439 39.9084 14.2118C39.5682 14.5496 39.4259 14.8901 39.4299 15.3728C39.4472 17.869 39.4286 20.3665 39.4233 22.8627C39.1202 22.8654 38.8172 22.868 38.5141 22.8707C38.5141 17.6549 38.5141 12.4391 38.5128 7.22196Z"
                fill={getColor()}/>
            <path
                d="M38.5074 48.197C38.5207 47.4456 38.5327 46.6943 38.546 45.9429C38.8118 45.9695 39.0776 45.9961 39.3421 46.024C39.5375 46.7235 39.4073 47.4377 39.4272 48.1438C39.4059 48.2289 39.3674 48.3141 39.3674 48.3992C39.3714 53.486 39.378 58.5714 39.3847 63.6582C39.289 64.2341 39.2876 64.8086 39.4312 65.3778C39.1361 65.3751 38.8424 65.3711 38.5473 65.3671C38.5367 64.7993 38.5247 64.2314 38.5141 63.6622C38.5181 61.5357 38.4981 59.4079 38.538 57.2828C38.5553 56.3984 37.8083 56.1125 37.359 55.6231C36.0897 54.2413 34.7406 52.9354 33.4234 51.5989C33.5297 51.3063 33.7052 51.0842 34.0282 51.0164C35.4769 52.45 36.9244 53.8849 38.5074 55.4529C38.5074 52.9008 38.5074 50.5496 38.5074 48.197Z"
                fill={getColor()}/>
            <path
                d="M60.5901 35.9101C60.9064 35.9115 61.2214 35.9128 61.5378 35.9154C61.5378 36.4886 61.5378 37.0605 61.5378 37.8185C63.7256 35.6388 65.8163 33.5562 67.9071 31.4736C68.2155 31.5787 68.3617 31.8726 68.5969 32.0628C66.3786 34.2863 64.1629 36.5139 61.9352 38.7281C61.6481 39.0141 61.5139 39.2987 61.5165 39.7123C61.5351 42.9651 61.5298 46.218 61.5311 49.4709C61.2214 49.4723 60.9117 49.4723 60.602 49.4736C60.5981 44.9507 60.5941 40.4304 60.5901 35.9101Z"
                fill={getColor()}/>
            <path
                d="M20.5133 56.1866C20.0973 55.5828 19.3769 55.3754 18.8545 54.9112C18.8944 54.6226 19.0419 54.4059 19.2865 54.2529C19.9736 54.495 20.4747 55.0549 21.134 55.3434C25.4192 58.1442 29.7004 60.9502 33.9909 63.743C34.6901 64.1991 35.3467 64.7271 36.1242 65.0582C36.4751 65.4931 36.9696 65.7258 37.4414 65.9905C37.3059 66.2445 37.1716 66.4998 37.036 66.7538C36.5629 66.4759 36.0884 66.1979 35.6152 65.92C30.8222 62.7828 26.0293 59.6443 21.2337 56.5097C21.013 56.3661 20.7884 56.2172 20.5133 56.1866Z"
                fill={getColor()}/>
            <path
                d="M57.7882 54.7334C58.0089 54.9581 58.1577 55.2201 58.2043 55.5353C52.9302 58.9704 47.6574 62.4042 42.3833 65.8392C41.8742 66.1438 41.3652 66.4497 40.8561 66.7542C40.7484 66.5175 40.6421 66.2808 40.5358 66.0427C40.8986 65.6544 41.4595 65.5161 41.7666 65.0586C42.2025 64.9389 42.5468 64.6517 42.915 64.4123C47.874 61.1887 52.8305 57.9597 57.7882 54.7334Z"
                fill={getColor()}/>
            <path
                d="M39.4259 48.1449C39.4073 47.4374 39.5376 46.7245 39.3409 46.0237C39.41 45.9772 39.4725 45.908 39.5482 45.8894C40.7431 45.5928 41.3705 44.8109 41.538 43.6113C41.6257 42.9863 41.2828 42.4636 41.1725 41.8878C41.6616 41.3093 42.1773 40.76 42.7781 40.2959C43.424 41.3186 43.9039 42.3838 43.8534 43.6406C43.759 46.0556 41.728 48.1395 39.4259 48.1449Z"
                fill={getColor()}/>
            <path
                d="M26.1503 33.3779C26.464 33.3793 26.7777 33.3793 27.0914 33.3806C27.0901 36.567 27.0821 39.7534 27.0967 42.9385C27.0994 43.372 26.9704 43.6779 26.6634 43.9824C24.6524 45.9759 22.66 47.9867 20.6622 49.9922C20.3047 49.9137 20.1186 49.6623 20.0016 49.3392C21.921 47.4162 23.8403 45.4932 25.7609 43.5715C25.9789 43.3534 26.1796 43.1539 26.1769 42.7895C26.157 39.6523 26.157 36.5151 26.1503 33.3779Z"
                fill={getColor()}/>
            <path
                d="M20.003 49.3391C20.1199 49.661 20.306 49.9137 20.6635 49.9921C21.1513 50.9922 21.7269 51.9484 21.6817 53.1386C21.6524 53.9259 21.4823 54.648 21.1354 55.3422C20.4748 55.0537 19.975 54.4938 19.2879 54.2517C19.2825 54.1427 19.2586 54.031 19.2772 53.9259C19.5989 52.1 19.026 51.1438 17.2702 50.5946C17.2822 49.8166 17.2941 49.0386 17.3048 48.2593C18.2923 48.3976 19.155 48.8484 20.003 49.3391Z"
                fill={getColor()}/>
            <path
                d="M5.32769 26.8564C5.74239 27.145 5.97898 27.6437 6.4814 27.8299C6.55317 27.9403 6.60767 28.0666 6.69805 28.1571C8.98686 30.4431 11.2783 32.7252 13.5698 35.0086C13.506 35.3384 13.2415 35.5007 13.0076 35.6882C10.7905 33.4766 8.57482 31.2637 6.35513 29.0547C6.1717 28.8725 6.02683 28.6318 5.74637 28.5667C5.57093 28.0507 5.05654 27.818 4.76678 27.3964C4.8678 27.1277 5.03129 26.9229 5.32769 26.8564Z"
                fill={getColor()}/>
            <path
                d="M36.337 23.9211C36.2466 24.227 36.0433 24.4384 35.7774 24.598C33.6521 22.4782 31.5281 20.3597 29.4028 18.2399C28.9854 17.6334 28.3754 17.2065 27.9102 16.644C28.0856 16.4365 28.2611 16.2304 28.4365 16.0229C29.0041 16.5655 29.4919 17.1932 30.1431 17.6494C32.0399 19.5644 33.9366 21.4781 35.8333 23.3945C36.0047 23.566 36.1695 23.7456 36.337 23.9211Z"
                fill={getColor()}/>
            <path
                d="M16.4102 23.1562C16.7119 23.1576 17.0136 23.1589 17.3153 23.1602C17.314 26.7363 17.3113 30.311 17.31 33.8871C17.0109 33.8884 16.7105 33.8897 16.4115 33.8911C16.4234 31.3058 16.4407 28.7218 16.4474 26.1365C16.4474 25.1431 16.4234 24.1497 16.4102 23.1562Z"
                fill={getColor()}/>
            <path
                d="M38.5447 40.8905C38.5314 40.1405 38.5181 39.3904 38.5048 38.6404C38.5035 35.8237 38.5035 33.0057 38.5021 30.189C38.8012 30.189 39.0989 30.189 39.398 30.189C39.3913 33.003 39.3847 35.8184 39.3781 38.6337C39.3634 39.3851 39.3475 40.1365 39.3329 40.8865C39.0697 40.8879 38.8078 40.8892 38.5447 40.8905Z"
                fill={getColor()}/>
            <path
                d="M40.8574 66.7543C41.3665 66.4484 41.8756 66.1439 42.3846 65.8394C42.5069 66.7064 42.6996 67.5789 42.3873 68.4419C41.8875 69.8237 40.8946 70.6283 39.4512 70.881C39.1893 70.3384 39.39 69.7639 39.3382 69.2066C39.9948 68.9513 40.5717 68.5922 40.7564 67.8621C40.8455 67.507 40.8269 67.124 40.8574 66.7543Z"
                fill={getColor()}/>
            <path
                d="M35.6153 65.9199C36.0885 66.1979 36.563 66.4758 37.0361 66.7538C36.983 67.9134 37.3804 68.7951 38.5474 69.1994C38.5341 69.7673 38.5221 70.3351 38.5089 70.9017C35.8665 70.4987 34.7606 67.8416 35.6153 65.9199Z"
                fill={getColor()}/>
            <path
                d="M13.72 49.3419C13.5871 49.6464 13.3545 49.8512 13.0634 49.9962C11.0896 48.004 9.11449 46.0132 7.14069 44.021C7.29088 43.7444 7.45437 43.4811 7.76672 43.3574C9.74982 45.3522 11.7356 47.3471 13.72 49.3419Z"
                fill={getColor()}/>
            <path
                d="M53.4964 46.2845C53.2452 45.8203 52.8398 45.4892 52.4929 45.1075C52.6843 44.9213 52.8757 44.7365 53.0684 44.5503C53.4897 44.9426 53.9111 45.3363 54.3324 45.7286C55.8064 47.2087 57.2805 48.6889 58.7558 50.169C58.6695 50.5148 58.4515 50.7289 58.1059 50.8127C56.6624 49.3591 55.219 47.9043 53.7728 46.4547C53.6971 46.3802 53.5881 46.3403 53.4964 46.2845Z"
                fill={getColor()}/>
            <path
                d="M69.6323 45.1116C69.3014 45.5372 68.8242 45.8284 68.5504 46.3059C67.0405 47.8073 65.5292 49.3087 64.0193 50.8102C63.6857 50.7477 63.5262 50.4777 63.3414 50.2396C64.8288 48.7329 66.3161 47.2248 67.8047 45.718C68.3032 45.4055 68.6355 44.9121 69.0754 44.5371C69.2615 44.7286 69.4476 44.9201 69.6323 45.1116Z"
                fill={getColor()}/>
            <path
                d="M17.3034 48.2608C17.2914 49.0388 17.2795 49.8168 17.2688 50.5961C17.003 50.5669 16.7358 50.5363 16.47 50.507C16.4687 49.7583 16.5511 49.0069 16.4222 48.2608C16.4594 46.673 16.482 45.0864 16.4089 43.4985C16.7119 43.4999 17.0163 43.5012 17.3207 43.5025C17.314 45.0891 17.3087 46.6756 17.3034 48.2608Z"
                fill={getColor()}/>
            <path
                d="M42.7794 40.2962C42.1786 40.7616 41.6616 41.3109 41.1738 41.8881C40.888 41.7418 40.67 41.5277 40.5212 41.2431C41.1166 40.7723 41.651 40.2417 42.1135 39.6392C42.927 38.8307 43.7404 38.0234 44.5539 37.2148C44.8888 37.2707 45.0496 37.5433 45.245 37.7721C44.4236 38.6139 43.6008 39.4544 42.7794 40.2962Z"
                fill={getColor()}/>
            <path
                d="M42.1135 39.6392C41.6509 40.2416 41.1166 40.7722 40.5212 41.243C40.1251 41.1233 39.729 41.005 39.3329 40.8866C39.3475 40.1352 39.3621 39.3852 39.3781 38.6338C40.4095 38.6418 41.2748 39.1046 42.1135 39.6392Z"
                fill={getColor()}/>
            <path
                d="M55.1671 26.5864C55.4409 26.742 55.6549 26.9482 55.7426 27.262C54.5583 28.4616 53.3754 29.6598 52.1911 30.8593C51.8495 30.7689 51.6342 30.5561 51.5544 30.2117C52.7587 29.0028 53.9629 27.7953 55.1671 26.5864Z"
                fill={getColor()}/>
            <path
                d="M41.7665 65.0582C41.4595 65.5157 40.8973 65.6553 40.5357 66.0437C40.1676 65.8216 39.7994 65.6008 39.4299 65.3787C39.2863 64.8095 39.2877 64.235 39.3834 63.6592C40.3457 63.8414 41.195 64.2137 41.7665 65.0582Z"
                fill={getColor()}/>
            <path
                d="M37.4415 65.991C36.9697 65.7264 36.4766 65.4923 36.1243 65.0588C36.6892 64.197 37.5598 63.8605 38.5142 63.665C38.5248 64.2329 38.5368 64.8008 38.5474 65.3699C38.1779 65.5761 37.8097 65.7835 37.4415 65.991Z"
                fill={getColor()}/>
            <path
                d="M47.8076 27.9602C47.8196 27.3937 47.8315 26.8271 47.8435 26.2593C48.1093 26.2885 48.3765 26.3165 48.6423 26.3457C48.6423 26.8817 48.5799 27.4189 48.6795 27.9535C48.6862 28.3326 48.6915 28.7116 48.6982 29.0906C48.3938 29.0919 48.0894 29.0946 47.7837 29.0959C47.7917 28.7169 47.7996 28.3392 47.8076 27.9602Z"
                fill={getColor()}/>
            <path
                d="M37.4415 65.9905C37.8097 65.7831 38.1779 65.5756 38.5474 65.3682C38.8424 65.3708 39.1375 65.3748 39.4313 65.3788C39.7994 65.6009 40.1676 65.823 40.5358 66.0437C40.6421 66.2805 40.7498 66.5172 40.8561 66.7539C40.8255 67.1236 40.8455 67.5053 40.7551 67.8604C40.5703 68.5905 39.9935 68.9495 39.3369 69.2049C39.0737 69.2035 38.8105 69.2009 38.5487 69.1995C37.3817 68.7953 36.9829 67.9149 37.0374 66.7539C37.1717 66.4999 37.3059 66.2446 37.4415 65.9905Z"
                fill={getColor()}/>
            <path
                d="M28.4378 16.0231C28.2624 16.2305 28.0869 16.4367 27.9115 16.6441C27.3944 16.7253 26.9611 17.0564 26.3962 17.0431C25.0246 17.0112 23.9759 16.0962 23.8084 14.6959C23.6662 13.5069 24.5952 12.3233 25.9005 12.0281C27.0608 11.7648 28.4086 12.5375 28.7661 13.725C29.0054 14.523 28.8499 15.301 28.4378 16.0231Z"
                fill={getColor()}/>
            <path
                d="M16.4687 50.5068C16.7345 50.5361 17.0017 50.5667 17.2675 50.5959C19.0233 51.1452 19.5975 52.1 19.2746 53.926C19.2559 54.031 19.2812 54.1427 19.2852 54.2518C19.0406 54.4047 18.8931 54.6215 18.8532 54.9101C18.5781 54.9021 18.4279 55.1295 18.2285 55.2545C16.9525 56.0564 15.3097 55.6229 14.5946 54.2824C13.922 53.0216 14.5295 51.3673 15.8746 50.7901C16.0766 50.705 16.2707 50.6013 16.4687 50.5068Z"
                fill={getColor()}/>
            <path
                d="M61.082 34.2065C60.02 34.2132 59.1361 33.3355 59.1507 32.2888C59.1653 31.2608 60.0279 30.4363 61.0846 30.439C62.1293 30.4416 62.9906 31.2967 62.9827 32.3234C62.9747 33.3514 62.1187 34.1986 61.082 34.2065Z"
                fill={getColor()}/>
            <path
                d="M26.6381 27.8975C27.6935 27.8948 28.5428 28.71 28.5627 29.7447C28.5827 30.7793 27.6789 31.673 26.6182 31.6677C25.604 31.6624 24.7175 30.7674 24.7281 29.758C24.7374 28.73 25.5894 27.9001 26.6381 27.8975Z"
                fill={getColor()}/>
            <path
                d="M69.6324 45.1118C69.4463 44.9203 69.2615 44.7288 69.0754 44.5359C68.7764 43.6702 68.6355 42.827 69.3718 42.0823C70.0391 41.408 71.0173 41.3056 71.7843 41.8442C72.5219 42.3629 72.821 43.4042 72.4595 44.1981C72.074 45.0466 71.0944 45.4974 70.2039 45.2434C70.0165 45.1902 69.8238 45.1556 69.6324 45.1118Z"
                fill={getColor()}/>
            <path
                d="M48.6436 26.3454C48.3778 26.3161 48.1106 26.2882 47.8448 26.2589C46.7669 25.8839 46.1501 25.0501 46.291 24.159C46.4452 23.1935 47.1882 22.5073 48.1279 22.4648C49.0038 22.4249 49.8173 23.0233 50.0565 23.8824C50.2798 24.6804 49.8771 25.5953 49.1234 26.0235C48.9573 26.118 48.7367 26.1392 48.6436 26.3454Z"
                fill={getColor()}/>
            <path
                d="M53.0684 44.5489C52.877 44.7351 52.6856 44.9199 52.4929 45.1061C51.6422 45.3788 50.8208 45.4825 50.1017 44.7697C49.4797 44.1526 49.3521 43.0355 49.8452 42.3493C50.4354 41.5288 51.4296 41.2774 52.3015 41.7309C53.1016 42.1471 53.507 43.1313 53.2306 43.9877C53.1708 44.1752 53.0206 44.3335 53.0684 44.5489Z"
                fill={getColor()}/>
            <path
                d="M39.3396 5.60856C39.0737 5.60192 38.8079 5.59527 38.5434 5.58862C37.9559 5.21093 37.3525 4.87447 37.1664 4.09649C36.9524 3.20015 37.4083 2.2147 38.2643 1.87558C39.2173 1.4979 40.3165 1.91149 40.7325 2.8065C41.1525 3.70949 40.8561 4.75345 40.036 5.2362C39.8087 5.37052 39.5722 5.48489 39.3396 5.60856Z"
                fill={getColor()}/>
            <path
                d="M5.3277 26.856C5.03263 26.9225 4.86914 27.1273 4.76546 27.3959C4.22583 27.4012 3.72473 27.6978 3.1532 27.5382C2.15898 27.2603 1.50637 26.2349 1.75492 25.2482C2.0048 24.2494 3.06547 23.5965 4.04639 23.8372C5.08446 24.0925 5.68657 25.0633 5.47125 26.09C5.41675 26.3427 5.24263 26.5767 5.3277 26.856Z"
                fill={getColor()}/>
            <path
                d="M39.3329 40.8867C39.729 41.0051 40.125 41.1248 40.5211 41.2431C40.6687 41.5291 40.888 41.7418 41.1724 41.8881C41.2827 42.4653 41.6243 42.9866 41.5379 43.6116C41.3705 44.8112 40.7444 45.5932 39.5482 45.8897C39.4724 45.9084 39.41 45.9775 39.3408 46.0241C39.075 45.9975 38.8105 45.9709 38.5447 45.9443C36.7171 45.3219 35.9435 43.6755 36.7955 42.194C37.1876 41.5118 37.7897 41.0955 38.5447 40.892C38.8078 40.8894 39.0697 40.888 39.3329 40.8867Z"
                fill={getColor()}/>
        </svg>

        <Paragraph className="text-content" style={{color: getColor()}}>VOZ DEL CLIENTE</Paragraph>
    </Radio.Button>
}

/**
 * @function NuevaEncuestaVozDelCliente
 * @description Radio button de nueva encuesta.
 * */
function NuevaEncuestaVozGenerica(props) {

    /**
     * @const checked
     * @description Obtiene si RadioButton debe de estar Activado. En caso de que si, solamente debe de activarse.
     * */
    const checked = props.checked;

    /**
     * @const getColor
     * @description Obtenemos el color de fondo y de algunos elementos, si checked esta true es blanco, si esta fuera, es el rosa*/
    const getColor = () => (checked) ? 'white' : 'rgb(153, 78, 144)'

    /*
    EL SVG Lo saque de figma, y sustitui el atributo de el color de llenado "FILL", con el metodo getColor, para obtener el color correspondiente segun si esta checked o no este radio button
    * */
    return <Radio.Button value={'generica'} className="button-select" style={{
        borderColor: getColor(),
        background: ((checked) ? 'linear-gradient(90deg, #9A4F91 0%, #76236C 102.04%)' : 'white')
    }}>
        <svg className="image-content" width="68" height="96" viewBox="0 0 68 96" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M68 7.76792C67.9841 8.14711 67.9524 8.5263 67.9524 8.90373C67.9506 37.5027 67.9506 66.1017 67.9506 94.7025C67.9506 95.9454 67.9506 95.9436 66.7306 95.9436C45.0271 95.9436 23.3237 95.9436 1.62026 95.9471C1.15129 95.9471 0.684073 95.9822 0.215095 95.9998C-0.072286 95.7101 0.02292 95.3397 0.02292 95.0009C0.0176307 66.2 0.02292 37.4009 0 8.60179C0 7.72052 0.287381 7.55024 1.10368 7.5555C6.41759 7.59061 11.7333 7.57481 17.0489 7.5713C17.4297 7.5713 17.8106 7.55024 18.1932 7.53795C18.1896 8.84405 18.1879 10.1519 18.1843 11.458C17.8635 11.4422 17.5408 11.4124 17.2199 11.4124C12.8704 11.4124 8.5227 11.4352 4.17672 11.393C3.36923 11.386 3.16648 11.5914 3.16648 12.3954C3.1894 38.6315 3.18764 64.8676 3.16824 91.1054C3.16824 91.8761 3.32692 92.1254 4.1538 92.1236C24.0413 92.0991 43.927 92.0991 63.8145 92.1236C64.6308 92.1254 64.8106 91.8937 64.8088 91.1089C64.7877 64.9887 64.7912 38.8703 64.7894 12.7501C64.7894 12.5447 64.7648 12.3375 64.793 12.1356C64.8741 11.5581 64.6343 11.3913 64.0613 11.3983C62.0355 11.4282 60.008 11.4053 57.9804 11.4124C55.2494 11.4211 52.5202 11.4404 49.7892 11.4562C49.7804 10.3784 49.7857 9.30048 49.7557 8.22435C49.7416 7.72579 49.9479 7.56077 50.4292 7.56603C51.2173 7.57481 52.0054 7.53092 52.7935 7.50986C54.1123 7.53092 55.4328 7.5713 56.7516 7.56779C60.3324 7.55901 63.915 7.53444 67.4958 7.50635C67.7338 7.50635 67.896 7.55901 68 7.76792Z"
                fill={getColor()}/>
            <path
                d="M52.7935 7.51178C52.0054 7.53285 51.2173 7.57498 50.4292 7.56796C49.9462 7.56269 49.7416 7.72771 49.7557 8.22627C49.7875 9.3024 49.7804 10.3803 49.7892 11.4582C49.7804 11.7004 49.7734 11.9409 49.7646 12.1832C49.6482 12.199 49.5318 12.2271 49.4155 12.2271C39.2901 12.2306 29.1648 12.2306 19.0412 12.2341C18.7415 12.2341 18.4541 12.2113 18.2126 12.0094C18.202 11.8268 18.1914 11.6442 18.1826 11.4617C18.1861 10.1556 18.1879 8.84948 18.1914 7.54163C18.1932 6.75165 18.2302 5.95991 18.1879 5.17344C18.1526 4.51688 18.3219 4.24302 19.0589 4.2518C22.1125 4.29393 25.1679 4.27462 28.2233 4.27814C31.6666 4.27814 35.1099 4.24654 38.5514 4.29745C39.4136 4.30973 39.7856 4.13594 39.5123 3.24414C40.4908 4.45369 41.8501 4.29042 43.183 4.27462C45.1153 4.2518 47.0494 4.27989 48.9818 4.25882C49.456 4.25356 49.8192 4.37118 50.1436 4.7293C51.004 5.67728 52.0389 6.46374 52.7935 7.51178Z"
                fill={getColor()}/>
            <path
                d="M39.5139 3.24211C39.7872 4.13391 39.417 4.30771 38.5531 4.29542C35.1115 4.24451 31.6683 4.27611 28.225 4.27611C29.8135 -0.298749 35.1662 -1.4609 38.4878 2.05363C38.8475 2.43282 39.1737 2.84537 39.5139 3.24211Z"
                fill={getColor()}/>
            <path
                d="M49.7664 12.1829C49.6782 12.7762 49.2057 12.4848 48.9342 12.4866C43.1566 12.5059 37.379 12.5006 31.6014 12.5006C27.3788 12.5006 23.1545 12.5076 18.932 12.4936C18.6358 12.4918 18.0557 12.7446 18.2161 12.0073C18.4577 12.2092 18.7433 12.232 19.0448 12.232C29.1701 12.2285 39.2955 12.2285 49.419 12.225C49.5336 12.225 49.65 12.1969 49.7664 12.1829Z"
                fill={getColor()}/>
            <path
                d="M12.2481 33.5021C12.2481 32.0415 12.264 30.5827 12.2393 29.1221C12.2322 28.6674 12.368 28.5112 12.8334 28.5147C15.8236 28.5323 18.8155 28.5358 21.8057 28.5147C22.3276 28.5112 22.4404 28.7008 22.4369 29.1783C22.4193 32.1275 22.4175 35.0768 22.4387 38.0261C22.4422 38.5194 22.2976 38.6791 21.7951 38.6774C18.805 38.658 15.813 38.658 12.8229 38.6774C12.3345 38.6809 12.2357 38.4948 12.2428 38.0594C12.2604 36.5391 12.2481 35.0206 12.2481 33.5021Z"
                fill={getColor()}/>
            <path
                d="M17.4139 78.8977C15.9153 78.8977 14.4167 78.8749 12.9198 78.91C12.3698 78.9223 12.2305 78.752 12.234 78.2148C12.2604 75.2901 12.2622 72.3654 12.234 69.4408C12.2287 68.886 12.4085 68.7544 12.9375 68.7579C15.8747 68.7807 18.812 68.7842 21.7493 68.7596C22.2994 68.7544 22.444 68.9282 22.4404 69.4566C22.4175 72.3812 22.414 75.3059 22.4404 78.2306C22.4457 78.7889 22.2518 78.9188 21.7317 78.9082C20.293 78.8784 18.8543 78.8977 17.4139 78.8977Z"
                fill={getColor()}/>
            <path
                d="M12.248 53.7253C12.248 52.2648 12.271 50.8024 12.2375 49.3418C12.2251 48.7959 12.4067 48.6519 12.9374 48.6554C15.8729 48.6765 18.8067 48.6765 21.7422 48.6519C22.2746 48.6484 22.4439 48.7941 22.4404 49.3383C22.4157 52.2612 22.4157 55.1824 22.4404 58.1053C22.4457 58.6495 22.2729 58.797 21.7387 58.7935C18.8031 58.7707 15.8694 58.7742 12.9339 58.7935C12.4138 58.797 12.2198 58.6671 12.2357 58.1071C12.2745 56.6483 12.248 55.1877 12.248 53.7253Z"
                fill={getColor()}/>
            <path
                d="M55.6356 44.7511C55.5068 44.8986 55.4011 45.0373 55.2794 45.1584C50.1524 50.2687 45.0201 55.3737 39.9019 60.4928C39.5422 60.8526 39.343 60.8316 39.001 60.4857C37.2502 58.7127 35.4871 56.9554 33.7047 55.2139C33.3697 54.8874 33.3626 54.6926 33.7082 54.3853C34.2971 53.8587 34.8066 52.9107 35.4254 52.8423C36.076 52.772 36.5644 53.8008 37.1391 54.3327C37.7404 54.8892 38.3451 55.4545 38.8758 56.0759C39.2971 56.5674 39.5369 56.5762 40.0112 56.097C44.2655 51.8135 48.5621 47.5722 52.8164 43.287C53.3665 42.7323 53.7156 42.6182 54.2216 43.28C54.5389 43.6943 54.9603 44.0314 55.3305 44.4053C55.4328 44.5089 55.5227 44.6212 55.6356 44.7511Z"
                fill={getColor()}/>
            <path
                d="M39.4629 40.7644C39.313 40.631 39.1755 40.5204 39.0521 40.3975C37.2855 38.6402 35.5383 36.8654 33.7452 35.1362C33.3186 34.7237 33.4103 34.5113 33.7875 34.183C34.357 33.6862 34.8613 32.7768 35.4484 32.7312C36.0672 32.682 36.5556 33.6511 37.1039 34.1707C37.721 34.7553 38.3398 35.3416 38.904 35.9754C39.306 36.4265 39.5528 36.4336 39.9936 35.9877C43.1072 32.8435 46.2525 29.731 49.389 26.608C50.6355 25.3668 51.8926 24.138 53.1161 22.8758C53.4458 22.5352 53.638 22.5475 53.9413 22.8845C54.4667 23.4709 55.4099 23.9817 55.4769 24.5997C55.5474 25.2475 54.5407 25.7565 53.9889 26.3078C49.3467 30.9511 44.6922 35.5804 40.0412 40.2132C39.8561 40.3975 39.6621 40.573 39.4629 40.7644Z"
                fill={getColor()}/>
            <path
                d="M39.4416 81.0115C39.29 80.8676 39.1596 80.7517 39.0361 80.6306C37.2713 78.8715 35.517 77.102 33.7328 75.364C33.3643 75.0059 33.3731 74.797 33.7416 74.474C34.2036 74.0685 34.639 73.6296 35.0463 73.1697C35.3619 72.8133 35.5699 72.8607 35.8838 73.1837C36.8817 74.2159 37.9377 75.1938 38.9145 76.2453C39.3447 76.7088 39.5651 76.6631 39.9917 76.2348C44.2725 71.9355 48.592 67.6732 52.8622 63.3634C53.4246 62.7964 53.6873 62.8438 54.2039 63.3792C55.6672 64.8995 55.6901 64.8731 54.1757 66.3829C49.4612 71.0788 44.7485 75.7748 40.034 80.4708C39.8507 80.6499 39.6532 80.8184 39.4416 81.0115Z"
                fill={getColor()}/>
        </svg>

        <Paragraph className="text-content" style={{color: getColor()}}>GENÉRICA</Paragraph>
    </Radio.Button>
}


/**
 * @class ModalFormDepartamento
 * @extends Component
 *
 * @description Modal de seleccionar el tipo de encuesta
 *
 * */
export default class ModalFormDepartamento extends Component {

    /**
     * @prop visible
     * @description Si el modal es visible o no
     *
     * @prop hideModal
     * @description Funcion para ocultar el modal. OBLIGATORIA
     *
     * @prop link
     * @description Si el boton de continuar es un link, debe de ser TRUE, si es un boton con una funcion, falso.
     *
     * @prop linkTo
     * @description Direccion a donde debe de ir elemento link. Por lo general, es encuesta nueva
     *
     * @prop accept
     * @description Si el boton de "Aceptar" no es un link, entonces debe tener alguna funcionalidad, este metodo es la funcionlaidad del boton de aceptar.
     * */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        link: PropTypes.bool,
        linkTo: PropTypes.string,
        accept: PropTypes.func,
    };

    /*Valores por defecto de las propiedades (en caso de que no esten declaradas)*/
    static defaultProps = {
        link: false,
        linkTo: "/admin/encuestas",
        accept: () => {}
    }


    constructor(props) {
        super(props);

        // axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    /**
     * @state tipo
     * @description Almacena el tipo de encuesta
     * */
    state = {
        tipo: null
    }

    /**
     * @function accept
     * @description Valida si ya esta selccionado el tipo de modal.
     * */
    accept = () => {
        if (this.state.tipo = null)
            Modal.error({
                title: "Debe seleccionar un tipo de encuesta",
                // content:""
            })
        else
            this.props.accept(this.state.tipo)
    };

    render() {
        //Button Type
        //Si es un link o un boton..
        const buttonType = (this.props.link && this.state.tipo !== null) ?
            <Link to={this.props.linkTo + '/' + this.state.tipo}>Continuar</Link> : 'Continuar';

        const {visible, hideModal, link, linkTo} = this.props;

        const {tipo} = this.state;

        const {accept} = this;
        return (
            <Modal
                visible={visible}
                onCancel={hideModal}
                title={null}
                footer={null}
                className="modal-form crear-encuesta"
                closeIcon={<Button type="ghost" className="closable-button" onClick={hideModal}>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.0019 27.6742L2.32776 0L2.66014e-05 2.32773L27.6742 30.0019L30.0019 27.6742Z"
                              fill="#E1E4ED"/>
                        <path d="M2.32915 30.0033L30.0033 2.3291L27.6756 0.00136937L0.00141366 27.6755L2.32915 30.0033Z"
                              fill="#E1E4ED"/>
                    </svg>
                </Button>}
                destroyOnClose={true}
            >
                <Title level={3} className="modal-title">Crear Encuesta </Title>
                <Paragraph className="modal-sub-title">¿Qué tipo de encuesta quieres crear?</Paragraph>
                <Radio.Group onChange={(e) => this.setState({
                    tipo: e.target.value
                })} defaultValue="a" className="buttons-selection-group">
                    <Row gutter={[24, 24]} className="buttons-selection">
                        <Col xs={{span: 18}} sm={12}>
                            <NuevaEncuestaVozDelCliente
                                //SI el tipo de encuesta es VOC, checked es TRUE; por lo que esta opcion debe de decalrarse
                                checked={tipo === 'voc'}
                            />
                        </Col>
                        <Col xs={{span: 18}} sm={12}>
                            <NuevaEncuestaVozGenerica
                                //SI el tipo de encuesta es GENERICA, checked es TRUE; por lo que esta opcion debe de decalrarse
                                checked={tipo === 'generica'}
                            />
                        </Col>
                    </Row>
                </Radio.Group>
                <button onClick={accept} type="primary" className="continue">
                    {buttonType}
                </button>
            </Modal>
        )
    }
}

