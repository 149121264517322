import React, { Component } from 'react';
import { Row, Col, Menu, Dropdown, Input, Button, Checkbox, Table, Modal,message, Radio } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

//componentes
import ModalUserForm from './Modals/ModalUserForm';
import ModalTypeUser from './Modals/ModalTypeUser';

//css
import '../../../styles/users.css';

const { confirm } = Modal;
const axios = require("axios").default;
const { Search } = Input;


/**
 * @class Usuarios
 * @description  Clase que contiene la tabla de usuarios, junto con los modales de creacion y edicion de los
 * usuarios, ruta: /admin/usuarios
 *
 **/
export default class Usuarios extends Component {


	constructor(props){
		super(props)

		this.state = {
			modalUserVisible 		: false,
			modalTypeUserVisible	: false,
			modalTitle 				: 'Crear Usuario',
			tipo 					: null,
			NewData					: false,

			loading			:false,
			users 			: [],
			userToEdit	: [],

			/**paginador */
			currentPage	: null,
			itemCount		: null,
			perPage			: null,
			pageCount		: null,

			/*datos de filtrado de la barra*/
			search      : null,   //Busqueda input
			field       : null,   //Campo a ordenar
			order       : null,   // asc o desc
			searchTipo  : null,   // Vianney o CDN
			searchRol   : null,
			visibleTipo : false,
			visibleRol  : false,
		}
	}


	/**
		* @memberof Usuarios
		*
		* @method componentDidMount
		* @description Cambia el titulo del Navbar al entrar a este componente, carga los primeros 10 usuarios en la tabla
		**/
	componentDidMount() {
		this.props.updateNavigationInformation('Usuarios');
		this.LoadData(1); //pagina 1
	}

	/**
		* @memberof Usuarios
		*
		* @method showModalTypeUser
		* @description Despliega el PRIMER modal para crear un usuario, en este se epecifica el tipo de usaurio 
		* (ModalTypeUser.js)
		**/
	showModalTypeUser = () => this.setState({ modalTypeUserVisible: true, userToEdit: [] })

	/**
		* @memberof Usuarios
		*
		* @method hideModalTypeUser
		* @description Cierra el primer modal para crear un usuario 
		* (ModalTypeUser.js)
		**/
	hideModalTypeUser = () => this.setState({ modalTypeUserVisible: false })

	/**
		* @memberof Usuarios
		*
		* @method showModalUser
		* @description Despliega el SEGUNDO modal para crear/editar un usuario
		* (ModalUserForm.js)
		*
		* @param Boolean
		* @description 
		**/
	showModalUser = (tipo) => {

		if(tipo  !== null ){
			this.setState({tipo: tipo});
		}
		this.setState({ modalUserVisible: true })
	}

	/**
		* @memberof Usuarios
		*
		* @method hideModalUser
		* @description Cierra el SEGUNDO modal para crear/editar un usuario
		* (ModalUserForm.js)
		**/
	hideModalUser = () => this.setState({ modalUserVisible: false , modalTitle: "Crear Usuario"})

	/**
		* @memberof Usuarios
		*
		* @method showModalEditUser
		* @description Abre el modal, para editar usuarios, este metodo se llama desde la tabla, boton edit,
		* crea una nueva propidad en el usuario a editar (selectOption) en este guarda el id y el nombre de el centro
		* o departamento al que pertenence, esto con el fin de al momento de editar si no esta el centro o departamneto
		* aparezaca en las opciones del select de los modales
		**/
	showModalEditUser = (data) => {
		if(data.departamento_id){
			data.selectOption = { id:data.departamento_id._id, nombre: data.departamento_id.nombre}
			data.departamento_id = data.departamento_id._id
		}else if (data.centronegocios_id){
			data.selectOption = { id:data.centronegocios_id._id, nombre: data.centronegocios_id.nombre}
			data.centronegocios_id = data.centronegocios_id._id
		}
		this.setState({modalTitle:'Editar Usuario',modalUserVisible: true, userToEdit : data })
	}

	/**
	* @memberof Usuarios
	*
	* @method LoadData
	* @description  Metodo para paginar los datos de la tabla
	*
	**/
	LoadData = (page) => {
		this.setState({loading: true})
		axios.get('/usuarios', {
			headers: { Authorization: sessionStorage.getItem("token") },
			params: {
				page    : page,
				limit   : 10,
				search  : this.state.search,
				field   : this.state.field,
				order   : this.state.order,
				tipo    : this.state.searchTipo,
				rol     : this.state.searchRol,
			}
		})
			.then((response) => {
				const data = response.data.data.itemsList;
				this.setState({
					users: data,
					currentPage: response.data.data.currentPage,
					itemCount: response.data.data.itemCount,
					pageCount: response.data.data.pageCount,
					loading: false,
				});
			})
			.then(() => {
				//Si se elimina el utlimo registro de una pagina, te manda a la primera pagina
				if(this.state.currentPage > this.state.pageCount){
					this.LoadData(1)
				}
			})
			.catch((response) => {
				this.setState({loading: false})
				message.error("Ocurrio un error al cargar los Usuarios");
				console.log('error', response.message);
			})
	}

	/**
	* @memberof Usuarios
	*
	* @method ReloadList
	* @description  refrescar la tabla de usuarios
	*
	**/
	ReloadList = () => {
		this.LoadData(this.state.currentPage)
	}

	/**
	* @memberof Usuarios
	*
	* @method showDeleteConfirm
	* @description  Para cada row mostrar un modal de confirmacion para eliminar el registro,
	* la eliminacion se realiza mediante una peticion al servidor, enviando el id del usuario
	*
	* @params item (row)
	**/
	showDeleteConfirm = (record) => {
		const nombre = record.nombre;
		const user_id = record._id;
		const esto = this;
		
		confirm({
			title: 'Eliminar Usuario',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estás seguro que deseas eliminar al usuario ' + nombre + '?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',

			onOk() {
				axios({
					method: 'delete',
					url: '/usuarios/delete',
					headers: { Authorization: sessionStorage.getItem('token') },
					data: { id: user_id, status: 0 }
				})
				.then((response) => {
					if(response.data.success) {
						//actualiza la lista de usuarios
						esto.LoadData(esto.state.currentPage);
						message.success("¡Usuario eliminado exitosamente!");
					}
					else{
						message.error(`¡Ooops! NO se ha eliminado el Usuario`);
					}
				})
				.catch((error) => {
					console.log("error al borrar el perfil", error);
					if (error.response.status === 403)
						message.error(`${error.response.data.message}`)
					else
						message.error(`¡Ooops! NO se ha eliminado el Usuario`);
				})
			},
		});
	}

	/**
	* @memberof Usuarios
	*
	* @method   handlePageClick
	* @description  Metodo que manda a llamar al paginado al hacer click en el paginado de la tabla
	*
	**/
	handlePageClick = (page) => {
		console.log("pagina", page);
		this.LoadData(page);
	}


	/**
	* @memberof Usuarios
	*
	* @method   handleEditUser
	* @description  Metodo que permite abrir el modal de edicion de usuario, 
	*
	**/
	 handleEditUser(record){
		console.log('se presiono para editar')
		this.setState({loading: true})
		this.loadUser(record._id);
	}

	/**
	* @memberof Usuarios
	*
	* @method   handlefilterSort
	* @description ordena los datos de la tabla
	*
	**/
	 handlefilterSort(field,order){
		this.setState({field: field, order: order},() => {
			this.ReloadList();
		})
	}

	/**
	* @memberof Usuarios
	*
	* @method   handleSearch
	* @description busca los registros que conicidan con la busqueda
	*
	**/
	 handleSearch(value){
		this.setState({search: value},() => {
			this.ReloadList();
		})
	}

	/**
	* @memberof Usuarios
	*
	* @method   handleSearchTipo
	* @description busca los registros que conicidan con el tipo de usuarios
	* (si pertenece a Vianney o a un CDN)
	*
	**/
	 handleSearchTipo(e){
	 	if(e.target.value !== this.state.searchTipo){
			this.setState({searchTipo: e.target.value, visibleTipo: false, visibleRol: false},() => {
				this.ReloadList();
			})
	 	}else {
	 		this.setState({searchTipo: null,visibleTipo: false, visibleRol: false},() => {
				this.ReloadList();
			})
	 	}

	}

	/*
	* @method   handleVisibleChange
	* @description Es llamado para que uno de los 2 menus  de los dropdowns sea visible
	*
	**/
	handleVisibleChange = (flag,index) => {
		if(index === 1) this.setState({ visibleTipo: flag });  //Tipo
		if(index === 2) this.setState({ visibleRol: flag }); //Roles
	};

	/**
	* @memberof Usuarios
	*
	* @method   handleSearchRol
	* @description busca los registros que conicidan con el tipo de usuarios
	* (si pertenece a Vianney o a un CDN)
	*
	**/
	 handleSearchRol(values){
		this.setState({searchRol: values},() => {
			this.ReloadList();
		})
	}

	/**
	* @memberof Usuarios
	*
	* @method   loadUser
	* @description  Metodo que permita la busqueda de un usaurio en la BD
	*
	**/
	loadUser = (id) => {
		axios.post('/usuarios/get',{
				id: id
			},{headers: {Authorization: sessionStorage.getItem("token")}})
			.then((response) => {
				const data = response.data.data;
				//convierte el unico valor del array de roles a un string, 
				//para que los radios bottom de la edicion lo puedan usar
				data.roles_id = data.roles_id[0];
				this.setState({tipo: data.tipo, loading: false})
				this.showModalEditUser(data)
			})
			.catch((error) => {
				console.log('error', error.response);
				this.setState({loading: false, message: "¡Ooops! Ha ocurrido un error"});
				message.error(`${this.state.message}`);
			})
	}


	/**
	* @memberof Usuarios
	*
	* @method   handleRecoveryPassword
	* @description  Mandar al usuario un correo de recupracion de contraseña
	*
	**/
	handleRecoveryPassword = async (record) => {
		const nombre = record.nombre;
		const email = record.email;
		//const esto = this;
		
		confirm({
			title: 'Restablecer contraseña',
			icon: <ExclamationCircleOutlined />,
			content: '¿Estás seguro que deseas enviar al usuario ' + nombre + ', un correo para restablecer su contraseña?',
			okText: 'Continuar',
			okType: 'danger',
			cancelText: 'Cancelar',

			onOk() {
				axios.put("/password/recovery", { email: email })
		            .then(res => {
		                Modal.success({
		                    title: 'Restablecer contraseña.',
		                    content: '¡El correo ha sido enviado!'
		                })
		            })
		            .catch(res => {
		                Modal.warning({
		                    title: 'Error',
		                    content: 'El correo no se ha podido enviar.'
		                });
		            })
				
			},
		});
    }

	render() {

		const columns = [
			{
				title: '',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'NOMBRE',
				dataIndex: 'nombre',
				key: 'nombre',
			},
			{
				title: 'TIPO',
				dataIndex: 'tipo',
				key: 'tipo',
				render:(text,row)=>{
					return row.tipo === true ? 'Vianney' : 'Centro de Negocio'
				}
			},
			{
				title: 'EMAIL',
				dataIndex: 'email',
				key: 'email',
			},
			{
				title: 'ROL',
				dataIndex: ['roles_id','nombre'],
				key: 'rol',
				render: (text, row) => {
					if(row.roles_id.length > 0)
						return row.roles_id[0].nombre
					else return 'Sin roles'
				}
			},
			{
				title: 'ACCIONES',
				dataIndex: '_id',
				key: 'actions',
				render: (text, record) => (
					<div>
						<Button className="btn-edit" onClick={()=>{this.handleEditUser(record)}}>
							<img src="/iconos/edit.svg" alt="edit"/>
						</Button>
						<Button className="btn-delete" onClick={()=>{this.showDeleteConfirm(record)}} >
							<img src="/iconos/trash.svg" alt="delete" />
						</Button>
						<Button className="btn-password" onClick={()=>{this.handleRecoveryPassword(record)}} >
							<img src="/iconos/password.svg" alt="reset-password" style={{height: '15px', width: '15px'}} />
						</Button>
					</div>
				)
			},
		];


		return (
			<div className="page-main" style={{ padding: '30px' }}>
				<Row className="controls">
					<Col sm={24} md={20} lg={20} className="filters">
						<Dropdown className="filter-control" trigger={['click']}
							overlay={
								<Menu>
									<Menu.Item onClick={()=>{this.handlefilterSort('nombre',1)}}>A-Z</Menu.Item>
									<Menu.Item onClick={()=>{this.handlefilterSort('nombre',-1)}}>Z-A</Menu.Item>
								</Menu>}
							placement="bottomLeft"
						>
							<Button type="link">Nombre <ArrowIcon /> </Button>
						</Dropdown>
						<Dropdown 
							className="filter-control" 
							trigger={['click']}
							onVisibleChange={(flag)=>{this.handleVisibleChange(flag,1)}}
							visible={this.state.visibleTipo}
							overlay={
								<Menu>
									<Menu.Item className="radio-vertical" >
										<Radio 
											value={false} 
											onClick={(e)=>{this.handleSearchTipo(e)}}
											checked={this.state.searchTipo === 'false'}
										>
											Centro de Negocio
										</Radio>
									</Menu.Item>
									<Menu.Item className="radio-vertical" >
										<Radio 
											value={true} 
											onClick={(e)=>{this.handleSearchTipo(e)}}
											checked={this.state.searchTipo === 'true'}
										>
											Vianney
										</Radio>
									</Menu.Item>									
								</Menu>
							} placement="bottomLeft">
							<Button type="link" onClick={e => e.preventDefault()}>Tipo <ArrowIcon /> </Button>
						</Dropdown>

						<Dropdown className="filter-control" trigger={['click']}
							overlay={
								<Menu>
									<Menu.Item onClick={()=>{this.handlefilterSort('email',1)}}>A-Z</Menu.Item>
									<Menu.Item onClick={()=>{this.handlefilterSort('email',-1)}}>Z-A</Menu.Item>
								</Menu>} placement="bottomLeft">
							<Button type="link">Email <ArrowIcon /> </Button>
						</Dropdown>

						<Dropdown className="filter-control" trigger={['click']}
							overlay={
								<Menu>
								<Menu.Item>
									<Checkbox.Group style={{ width: '100%' }} onChange={(check) => this.handleSearchRol(check)}>
										<Row>
											<Checkbox value="Consultador">Consultador</Checkbox>
										</Row>
										<Row>
											<Checkbox value="Encuestador">Encuestador</Checkbox>
										</Row>
										<Row>
											<Checkbox value="Administrador">Administrador</Checkbox>
										</Row>
									</Checkbox.Group>
								</Menu.Item>
								</Menu>}
							placement="bottomLeft"
						>
							<Button type="link">Rol <ArrowIcon /> </Button>
						</Dropdown>

						<Search
							className="search-control"
							placeholder="Buscar"
							size="normal"
							bordered={false}
							enterButton
							onSearch={value => this.handleSearch(value)}
						/>
					</Col>
					<Col sm={24} md={4}>
						<Button className="add-control" ref={this.SpaceReference} onClick={this.showModalTypeUser}>
							<svg style={{ marginTop: '0.3em' }} width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6.4298 11.5702C6.31805 11.5702 6.24069 11.5702 6.16046 11.5702C4.35817 11.5702 2.55587 11.5702 0.753582 11.5702C0.246418 11.5702 0 11.3266 0 10.8223C0 9.60172 0 8.38395 0 7.16332C0 6.68195 0.249284 6.4298 0.727794 6.4298C2.54441 6.4298 4.35817 6.4298 6.17478 6.4298C6.25215 6.4298 6.33238 6.4298 6.43266 6.4298C6.43266 6.33238 6.43266 6.25215 6.43266 6.17478C6.43266 4.36676 6.43266 2.55874 6.43266 0.747851C6.43266 0.243553 6.67622 0 7.18338 0C8.39542 0 9.60745 0 10.8195 0C11.3266 0 11.5673 0.243553 11.5673 0.753582C11.5673 2.55587 11.5673 4.35817 11.5673 6.16046C11.5673 6.24069 11.5673 6.32092 11.5673 6.43266C11.6533 6.43266 11.7307 6.43266 11.808 6.43266C13.616 6.43266 15.4241 6.43266 17.235 6.43266C17.7593 6.43266 18 6.67049 18 7.18911C18 8.40115 18 9.61318 18 10.8252C18 11.3209 17.7536 11.5673 17.2607 11.5673C15.4527 11.5673 13.6447 11.5673 11.8338 11.5673C11.7536 11.5673 11.6762 11.5673 11.5673 11.5673C11.5673 11.6562 11.5673 11.7335 11.5673 11.8138C11.5673 13.6218 11.5673 15.4298 11.5673 17.2407C11.5673 17.7622 11.3266 18 10.8052 18C9.59312 18 8.38109 18 7.16905 18C6.67622 18 6.4298 17.7507 6.4298 17.255C6.4298 15.447 6.4298 13.639 6.4298 11.8281C6.4298 11.7507 6.4298 11.6734 6.4298 11.5702Z" fill="white" />
							</svg>
						</Button>
					</Col>
				</Row>
				
				<div className="page-content">

					<div>
						<h5 className="items-number">Usuarios {this.state.itemCount} </h5>
						<Table
							className="admin-table"
							dataSource={this.state.users}
							columns={columns}
							loading={this.state.loading}
							scroll={{ x: 1200 }}
							pagination={{
								onChange: this.handlePageClick,
								total: this.state.itemCount,
								current: this.state.currentPage
						}} />
					</div>

					{/*Modals*/}
					<ModalTypeUser
						modalTypeUserVisible={this.state.modalTypeUserVisible}
						hideModalTypeUser={this.hideModalTypeUser}
						showModalUser={this.showModalUser}
					/>

					<ModalUserForm
						modalUserVisible={this.state.modalUserVisible}
						hideModalUser={this.hideModalUser}
						modalTitle={this.state.modalTitle}
						tipo = {this.state.tipo}
						userToEdit= {this.state.userToEdit}
						ReloadList = {this.ReloadList}						
					/>

				</div>
			</div>
		)
	}
}

/**
 * @method ArrowIcon
 * @description  Icono Dropdown Barra fillter
 *
 **/
function ArrowIcon() {
	return <svg style={{ marginLeft: '10px' }} width="8" height="5" viewBox="0 0 8 5" fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.98817 0.00230214C5.10632 0.00230214 6.22447 0.00528392 7.34261 3.03092e-05C7.60672 -0.0012476 7.84092 0.0366636 7.95916 0.347053C8.08234 0.670505 7.90441 0.87 7.72407 1.07092C6.64496 2.27328 5.56699 3.47693 4.48787 4.6793C4.10679 5.10399 3.89515 5.10598 3.52244 4.69066C2.43623 3.47991 1.35129 2.26775 0.264064 1.05814C0.0803047 0.853814 -0.0783622 0.648497 0.0419053 0.32902C0.16268 0.00783972 0.40727 3.03092e-05 0.664787 0.000740257C1.77254 0.00414801 2.88029 0.00230214 3.98817 0.00230214Z"
			fill="white" />
	</svg>
}