import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Carousel, Col, Form, Input, message, Modal, Row, Spin,  Layout } from 'antd';
import Cookies from 'universal-cookie';

import '../../styles/auth.css'

const axios = require('axios').default;

const { Content } = Layout;

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            log: false,
            isAdmin: false,
            isLogged: false,

            isRecovery: false,
            isRecoveryLoading: false,
            redirect: false
        }
    }

    recoveryPassword = React.createRef();


    onFinish = async values => {
        const cookies = new Cookies();

        axios.post("/login", {
            email: values.email,
            password: values.password,
            modo: 'web'
        })
            .then(response => {
                console.log(response);

                if (response.data.success) {
                    console.log("sesión iniciada", response);

                    cookies.set('token', response.headers.authorization, { path: '/', maxAge: 60 * 60 * 24 });
                    cookies.set('username', response.data.user.usuario, { path: '/', maxAge: 60 * 60 * 24 }); //username para el navbar
                    cookies.set('userNombre', response.data.user.nombre, { path: '/', maxAge: 60 * 60 * 24 }); //nombre para el navbar

                    axios.defaults.headers.post["Authorization"] = response.headers.authorization;

                    sessionStorage.setItem('token', response.headers.authorization);
                    sessionStorage.setItem('username', response.data.user.usuario);
                    sessionStorage.setItem('userNombre', response.data.user.nombre);


                    this.setState({ redirect: true, username: response.data.user.usuario })
                    this.state.redirect = true;

                    message.success(response.data.message);
                }
                else {
                    Modal.error({
                        title: response.data.message
                    });
                }
            })
            .catch(error => {
                console.log(error)
                Modal.warning({
                    title: "No es posible iniciar sesión"
                });
            });
    };


    /**
   * @memberof Login
   *
   * @method renderRedirect
   * @description El usuario sera redirigido al dashboard, si inicio sesion correctamente o si ya se habia logeado anteriiormente
   * ya que estan guadados en los cookies el username y el token
   **/
    renderRedirect = () => {
       // const cookies = new Cookies();

        const token = sessionStorage.getItem("token");
        const username = sessionStorage.getItem("username");

        if (this.state.redirect || (username && token)) {

            sessionStorage.setItem('token', token);

            // cookies.set('token' , token, { path: '/' , maxAge: 60*60*24});
            // cookies.set('username',username, { path: '/' , maxAge: 60*60*24}); //username para el navbar

            return <Redirect to="/admin/dashboard" />;
        }
    };

    /**
     *
     * @param {*} value
     * @memberof Login
     * 
     * @function handleRecoveryPassword 
     */
    handleRecoveryPassword = async value => {
        await this.setState({ isRecoveryLoading: true })
        axios.put("/password/recovery", { email: value.email })
            .then(res => {
                this.setState({
                    isRecovery: false
                })
                Modal.success({
                    title: 'Restablece tu contraseña.',
                    content: '¡El correo ha sido enviado! Revisa tu bandeja de entrada.'
                })
                this.recoveryPassword.current.resetFields();
            })
            .catch(res => {
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que sea el mismo con el que te registraste.'
                });
            })
            .finally(() => {
                this.setState({
                    isRecoveryLoading: false
                })
            })
    }


    render() {
        return (
            <Layout>
                {this.renderRedirect()}
                <Content className="login">
                    <Row className="" align="center" style={{ paddingTop: '20px', width: '100%', heigth: '100%' }}>
                        {this.renderRedirect()}
                        <Col xs={22} md={20} lg={10} xl={10} style={{ paddingTop: '30px' }}>

                            <Row style={{
                                marginBottom: '50px', maxWidth: '600px',
                                display: "block",
                                margin: "0px auto"
                            }}>
                                <Col style={{ margin: '5px', width: '100%!important' }}>
                                    <img width='100px' src="/images/logo-vianney-morado.png" alt="0" />
                                </Col>
                                <Col style={{ paddingLeft: '5px' }}>
                                    <h1 style={{ fontWeight: 'bold', fontSize: '18pt', marginBottom: '0px' }}>Voz del
                                    Cliente</h1>
                                    <label style={{ fontSize: '9pt', color: '#A4A4A4' }}> Ingresa tus credenciales para
                                    iniciar sesión.</label>
                                </Col>
                            </Row>


                            <Form onFinish={this.onFinish} className="login-form" name="basic"
                                style={{
                                    maxWidth: '600px',
                                    display: "block",
                                    margin: "0px auto"
                                }}
                            // initialValues={{ remember: true }}
                            >
                                <Form.Item
                                    label="Correo Electrónico"
                                    name="email"
                                    rules={[{
                                        required: true,
                                        message: 'Para poder iniciar sesión, debe haber ingresado su correo electrónico'
                                    }]}
                                >
                                    <Input className="input-login-text" />
                                </Form.Item>

                                <Form.Item
                                    label="Contraseña"
                                    name="password"

                                    rules={[{
                                        required: true,
                                        message: 'Para poder iniciar sesión, debe haber ingresado su contraseña'
                                    }]}
                                >
                                    <Input.Password className="input-login-password" />
                                </Form.Item>

                                <Link style={{ color: '#76236C' }} className=""
                                    onClick={() => this.setState({ isRecovery: true })}> ¿Olvidaste tu contraseña?</Link>

                                <Form.Item style={{ marginTop: '50px' }}>
                                    <Button type="primary" htmlType="submit" className="btn-morado">
                                        Iniciar Sesión
                                </Button>
                                </Form.Item>
                            </Form>

                        </Col>
                        <Col xs={22} md={20} lg={14} xl={14} className="carousel-images">
                            <Carousel autoplay>
                                <img className={"carousel-item"} src="/images/Opcion1.png"  alt="1" />
                                <img className="carousel-item" src="/images/Opcion2.png"    alt="2" />
                                <img className="carousel-item" src="/images/Opcion3.jpg"    alt="3" />
                            </Carousel>
                        </Col>
                    </Row>

                    <Spin spinning={this.state.isRecoveryLoading}>
                        <Modal
                            title="Olvidé mi contraseña"
                            className="modal-recovery"
                            visible={this.state.isRecovery}
                            onOk={() => this.recoveryPassword.current.submit()}
                            onCancel={() => this.setState({ isRecovery: false })}
                            maskClosable={!this.state.isRecoveryLoading}
                            okText="Enviar"
                            cancelText="Cancelar"
                            cancelButtonProps={{ className: 'btnCancel' }}
                            okButtonProps={{ className: 'btnOk recovery-button' }}
                        >
                            <Form
                                onFinish={this.handleRecoveryPassword}
                                layout={"vertical"}
                                className="pd-2"
                                ref={this.recoveryPassword}>
                                <Form.Item
                                    name="email"
                                    label="Escribe tu correo electrónico."
                                    rules={[{
                                        required: true,
                                        message: 'Por favor, ingresa tu correo electrónico.'
                                    }]}>
                                    <Input type="email" size="large" className="input-box" placeholder="Escribe tu correo electronico" />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </Spin>
                </Content>
            </Layout>
        )
    }

}
