import React, { Component } from 'react';
import { Button, Col, Modal, Radio, Row, Typography, Carousel, Input, Form, Tooltip, Switch } from 'antd'
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import MinusCircleOutlined from "@ant-design/icons/lib/icons/MinusCircleOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { ArrowLeftOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import '../../../../styles/Modals/ModalEncuesta.css';


const axios = require("axios").default;
const { Title, Paragraph, Text } = Typography;

function VianneyOptionButton(props) {

    /**
     * @const checked
     * @description Obtiene si RadioButton debe de estar Activado. En caso de que si, solamente debe de activarse.
     * */
    const { checked, value, icon, text } = props;
    // const checked = props.checked;
    // const value = props.value;

    /**
     * @const getColor
     * @description Obtenemos el color de fondo y de algunos elementos, si checked esta true es blanco, si esta fuera, es el rosa*/
    const getColor = () => (checked) ? 'white' : '#E1E4ED'

    /*
    EL SVG Lo saque de figma, y sustitui el atributo de el color de llenado "FILL", con el metodo getColor, para obtener el color correspondiente segun si esta checked o no este radio button
    * */
    return <Radio.Button value={value} className="button-select" style={{
        borderColor: getColor(),
        background: ((checked) ? 'linear-gradient(90deg, #9A4F91 0%, #76236C 102.04%)' : 'white')
    }}>
        {icon(getColor())}
        <Paragraph className="text-content" style={{ color: getColor() }}>{text}</Paragraph>
    </Radio.Button>
}


const form = {
    id: null,
    nombre: null,
    pregunta: null,
    respuestas: [
        {
        }, {

        }
    ],
    genero: 3,
    condicional: false,
    activada: false,
    texto: null,
    opcion_si: null,
    opcion_no: null,


}

/**
 * @class ModalFormDepartamento
 * @extends Component
 *
 * @description Modal de seleccionar el tipo de encuesta
 *
 * */
export default class ModalPregunta extends Component {


    carousel = React.createRef();

    /**
     * @state tipo
     * @description Almacena el tipo de encuesta
     * */
    state = {
        tipo: null,
        update: false,
        initSlide: 0,
        form: form,
        condicional: false,
        genero: 3
    };


    constructor(props) {
        super(props);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    componentDidMount() {
        // if (this.props.itemText?._id !== undefined) {
        //     this.getQuestion(this.props.itemText._id);
        //     this.carousel.current.goTo(1);
        // }

        if(this.props.itemText !== undefined){
            this.setQuestion(this.props.pregunta);
            this.carousel.current.goTo(1);
        } 
    }
    /**
     *
     * @memberof ModalPregunta
     *
     *
     * @function accept
     * @description Valida si ya esta selccionado el tipo de modal. Activamos el slider en caso de que sea de texto.
     * */
    accept = () => {
        if (this.state.tipo === null)
            Modal.error({
                title: "Debe seleccionar un tipo de encuesta",
            })
        else
            if (this.state.tipo === "texto") {
                if (this.props.itemText?._id !== undefined && this.props.nuevaPregunta == false && this.props.pregunta == null) {
                    this.getQuestion(this.props.itemText._id);
                }
                else if(this.props.pregunta!=null){
                    this.setQuestion(this.props.pregunta);
                }
                //Nos vamos al inico del slider
                this.carousel.current.goTo(1);
            } else {
                this.props.accept();
            }
    };



    /**
     *
     *
     * @memberof ModalPregunta
     */
    onChangeCondicional = (checked) => {
        this.setState({ condicional: checked });
    }


    /**
     *
     *
     * @memberof ModalPregunta
     */
    onChangeGenero = e => {
        this.setState({ genero: e.target.value });
    };


    /**
     *
     *
     * @param {*} condicional
     * @returns
     * @memberof ModalPregunta
     */
    renderCondicional(condicional) {
        if (condicional === true) {
            return <div>
                <Text className="label-question">Pregunta Condicional</Text>
                <Form.Item
                    name="texto"
                    rules={[{
                        required: true,
                        message: "El nombre de la pregunta condicional es requerido."
                    }]}
                >
                    <Input.TextArea className="form-input" />
                </Form.Item>
                <div className="space-content" style={{ display: 'flex', marginBottom: 8 }} align="start">
                    <Text className="label-question" style={{ width: '23%', padding: '10px 6px 0px 0px' }}>Opción Si :</Text>
                    <Form.Item
                        name="opcion_si"
                        rules={[{
                            required: true,
                            message: "La posible respuesta de la pregunta condicional es requerida."
                        }]}
                        className="input-answer"
                    >
                        <Input.TextArea className="form-input" placeholder="Posible Respuesta" />
                    </Form.Item>
                </div>
                <div className="space-content" style={{ display: 'flex', marginBottom: 8 }} align="start">
                    <Text className="label-question" style={{ width: '23%', padding: '10px 6px 0px 0px' }}>Opción No :</Text>
                    <Form.Item
                        name="opcion_no"
                        rules={[{
                            required: true,
                            message: "La posible respuesta de la pregunta condicional es requerida."
                        }]}
                        className="input-answer"
                    >
                        <Input.TextArea className="form-input" placeholder="Posible Respuesta" />
                    </Form.Item>
                </div>
            </div>
        }
    }


    /**
     *
     * @memberof ModalPregunta
     *
     * @method getQuestion
     * @description obtemeos la pregunta y la ponemos en el formulario
     *
     * @param id
     * @description ID de la pregunta a obtener
     */
    getQuestion = (id, index) => {
        axios.get("/preguntas/id", {
            params: { id }
        })
            .then(data => this.setQuestion(data.data))
            .catch(res => {
                console.log("no obtenida", res);
            })
    };


    /**
     *
     * @memberof ModalPregunta
     *
     * @method setQuestion
     * @description Declaramos la pregunta en el
     *
     * @param item
     * @description datos de la pregunta
     */
    setQuestion = async (item) => {
        await this.setState({
            form: {
                _id: (item._id != undefined) ? item._id : null,
                numero: item.numero,
                tipo: item.tipo,
                nombre: item.nombre,
                pregunta: item.pregunta,
                respuestas: item.respuestas,
                activada: item.condicional.activada,
                texto: item.condicional.texto,
                opcion_si: item.condicional.opcion_si,
                opcion_no: item.condicional.opcion_no,
                genero: item.genero
            },
            condicional: item.condicional.activada
        })

        await this.formQuestion.current.resetFields();
        await this.carousel.current.goTo(1);
    }


    /**
     * @methodOf ModalPregunta
     *
     * @method onFinish
     * @description Reiniciamos el slider y enviamos los valores
     *
     * @param values
     * @description Declaramos los valores en el sistema
     * */
    onFinish = async (values) => {
        try{
            await this.carousel.current.goTo(1);
            this.props.onFinish(this.formatValues(values))
        }catch(e){
            console.log(e)
        }
    }



    /**
     *
     *
     * @param {*} values
     * @returns {}
     * @memberof ModalPregunta
     * @description Formatea los valores para mostrarlos en la lista de preguntas y enviarlos al WS.
     */
    formatValues(values) {
        let pregunta = {
            _id: values._id,
            nombre: values.nombre,
            pregunta: values.pregunta,
            respuestas: values.respuestas,
            condicional: {
                activada: values.activada,
                texto: values.texto,
                opcion_si: values.opcion_si,
                opcion_no: values.opcion_no,
            },
            genero: values.genero
        };

        return pregunta;
    }


    /**
     * @param formQuestion
     * @description Referencia para las referencias
     * */
    formQuestion = React.createRef();

    render() {
        const buttonType = (this.props.link && this.state.tipo !== null) ?
            <Link to={this.props.linkTo + '/' + this.state.tipo}>Continuar</Link> : 'Continuar';
        const { tipo, initSlide, form } = this.state;
        const { accept, carousel, onFinish, formQuestion } = this;

        return (
            <Carousel
                dots={false}
                infinite={false}
                touchMove={false}
                ref={carousel}
                initialSlide={initSlide}
            >
                <div>
                    <Title level={3} className="modal-title">Tipo de Pregunta</Title>
                    <Paragraph className="modal-sub-title">¿Qué tipo de respuesta quieres que tu pregunta tenga? </Paragraph>
                    <Radio.Group onChange={(e) => this.setState({
                        tipo: e.target.value
                    })} defaultValue="a" className="buttons-selection-group">
                        <Row gutter={[24, 24]} className="buttons-selection">
                            <Col xs={{ span: 18 }} sm={12}>
                                <VianneyOptionButton
                                    icon={(color) => <svg className="image-content" width="127" height="74" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296.87 241.52">
                                        <path fill={color} d="M562.63,541.37h-110c-16.62,0-25.39-8.64-25.4-25.17q0-83,0-166c0-16.36,8.67-25.08,24.94-25.09q110.22,0,220.45,0c16.41,0,25.08,8.61,25.09,24.88q0,83.24,0,166.47c0,16.16-8.84,24.88-25.13,24.88Zm-.53-17.66h110c7.29,0,8.41-1.09,8.41-8.36q0-82,0-164c0-7.62-1.13-8.76-8.58-8.76H453c-7.16,0-8.38,1.21-8.38,8.43q0,82.23,0,164.46c0,7,1.15,8.19,8.05,8.19Z" transform="translate(-427.23 -325.11)" />
                                        <path fill={color} d="M458.28,545.74h14.79c.66,0,1.66-.15,1.93.2,3.94,5.06,9.48,3.18,14.4,3.19q103.71.15,207.43.07c9.71,0,10-.32,10-10.22V380c0-3.31.53-6.77-3.14-8.84a2.85,2.85,0,0,1-.86-2.14c-.07-5,0-9.95,0-14.94,11.72.6,20.2,8.77,21,19.84.14,2,.13,4,.13,6,0,53.48-.36,107,.22,160.45.19,18.06-11.91,26.31-26,26.23-72-.41-144-.18-215.93-.2-11.2,0-19.44-5.36-22.6-14.7A52.65,52.65,0,0,1,458.28,545.74Z" transform="translate(-427.23 -325.11)" />
                                        <path fill={color} d="M602.74,383.27c10.55,10.61,20,20.17,29.56,29.7,8.82,8.84,17.73,17.59,26.45,26.53,1.37,1.4,2.72,3.59,2.74,5.42.19,19.15.11,38.3.09,57.45a18.9,18.9,0,0,1-.38,2.63H463.64c0-8.78,0-17.51.08-26.23,0-.8,1-1.66,1.69-2.35,14.08-14.18,28.17-28.33,42.73-42.95L530,456.7Z" transform="translate(-427.23 -325.11)" />
                                        <path fill={color} d="M516.88,387.88a26.7,26.7,0,1,1-53.39.4c-.08-15.28,11.51-26.95,26.73-26.92A26.24,26.24,0,0,1,516.88,387.88Z" transform="translate(-427.23 -325.11)" />
                                    </svg>
                                    }
                                    value={"imagen"}
                                    text={'IMAGEN'}
                                    checked={tipo === 'imagen'}
                                />

                            </Col>
                            <Col xs={{ span: 18 }} sm={12}>
                                <VianneyOptionButton
                                    //SI el tipo de encuesta es GENERICA, checked es TRUE; por lo que esta opcion debe de decalrarse
                                    icon={(color) => <svg className="image-content" width="127" height="74" viewBox="0 0 127 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M127 62.4846C126.106 63.8611 124.751 64.002 123.256 64C105.042 63.9722 86.8307 63.9722 68.6169 63.9682C64.4777 63.9663 60.3386 63.9861 56.1994 63.9663C55.649 63.9643 55.0608 63.8849 54.5581 63.6787C53.4393 63.2185 52.8293 61.9868 53.0419 60.8424C53.2804 59.5611 54.3157 58.6646 55.7225 58.6388C57.8408 58.5991 59.961 58.6249 62.0813 58.6249C74.0695 58.6229 86.0557 58.6209 98.0439 58.6229C105.527 58.6249 113.011 58.6328 120.494 58.6408C121.921 58.6428 121.899 58.6467 121.899 57.2405C121.895 44.2174 121.901 31.1962 121.903 18.1731C121.903 18.074 121.905 17.9748 121.907 17.8756C121.921 16.3583 121.921 16.3603 120.413 16.3603C103.359 16.3603 86.3041 16.3583 69.2507 16.3603C65.046 16.3603 60.8393 16.3821 56.6346 16.3762C56.0444 16.3762 55.4284 16.3623 54.8681 16.1996C53.6122 15.8386 52.8531 14.6089 53.026 13.3733C53.2108 12.0424 54.262 11.0685 55.7007 11.0368C57.9521 10.9892 60.2034 11.015 62.4548 11.015C72.0903 11.015 81.7258 11.015 91.3632 11.017C100.868 11.0189 110.37 11.0249 119.874 11.0289C121.166 11.0289 122.459 11.0705 123.747 11.007C125.116 10.9396 126.215 11.3502 126.998 12.5006C127 29.1632 127 45.8239 127 62.4846Z" fill={color} />
                                        <path d="M33.8932 58.6218C24.7614 58.6357 15.6297 58.6278 6.49987 58.6635C5.593 58.6675 5.42149 58.3282 5.42346 57.499C5.44317 44.1776 5.44317 30.8562 5.41557 17.5368C5.4136 16.654 5.61075 16.3227 6.55113 16.3385C9.96568 16.3921 13.3842 16.3604 16.8007 16.3623C22.5159 16.3643 28.2331 16.3703 33.9484 16.3723C35.4072 16.3723 36.4482 15.7255 36.8405 14.5789C37.4891 12.6764 36.0933 11.0199 33.8596 11.0199C23.8407 11.0199 13.8218 11.0278 3.80293 11C2.34208 10.9961 1.06458 11.2282 0.187288 12.6347V12.4066C0.128144 12.4919 0.0650578 12.5752 0 12.6585C0 29.1045 0 45.5504 0 61.9943C0.0118287 62.0142 0.0256288 62.032 0.0354861 62.0519C0.096601 62.1788 0.153773 62.3078 0.208974 62.4387V62.3732C1.04093 63.6627 2.18831 64.002 3.57029 64C13.6878 63.9821 23.8053 63.9901 33.9208 63.9921C35.7641 63.9921 37.01 62.8752 36.9785 61.2405C36.9469 59.6574 35.699 58.6198 33.8932 58.6218Z" fill={color} />
                                        <path d="M47.7094 5.27878C47.7094 26.478 47.7094 47.5089 47.7094 68.6883C48.5275 68.6883 49.3154 68.6804 50.1033 68.6903C51.8339 68.7101 52.9817 69.7545 52.9997 71.3122C53.0158 72.8778 51.9003 73.9658 50.1556 73.9797C46.7406 74.0074 43.3276 74.0054 39.9126 73.9817C38.188 73.9698 37.0121 72.8719 37.0001 71.3558C36.988 69.7961 38.2041 68.7041 39.9689 68.6883C42.3186 68.6665 42.3105 68.6665 42.3105 66.3617C42.3145 46.516 42.3206 26.6703 42.3266 6.82458C42.3266 6.59271 42.3045 6.36084 42.3246 6.13096C42.3809 5.50471 42.1517 5.21933 41.4683 5.27284C40.8372 5.32238 40.196 5.30653 39.5608 5.27284C38.1197 5.19753 37.0463 4.10556 37.0061 2.71038C36.9659 1.31321 37.979 0.0706264 39.4221 0.0468449C43.1708 -0.0165726 46.9195 -0.0165726 50.6681 0.0527903C52.0591 0.0785536 53.0178 1.28943 52.9997 2.67867C52.9817 4.08376 51.9244 5.20546 50.4933 5.27284C49.5968 5.31446 48.6983 5.27878 47.7094 5.27878Z" fill={color} />
                                        <path d="M20.777 31.3854C19.0782 31.3854 17.4648 31.4015 15.8514 31.3794C14.4781 31.3614 13.5315 30.7104 13.1524 29.5828C12.5729 27.8602 13.7041 26.0676 15.5597 26.0456C20.8484 25.9815 26.1392 25.9915 31.428 26.0356C32.9323 26.0476 33.996 27.2513 34 28.6774C34.004 30.1416 33.0037 31.2632 31.4717 31.3514C30.0885 31.4315 28.6933 31.4255 27.3081 31.3634C26.4091 31.3213 26.1174 31.5957 26.1253 32.5371C26.169 37.6086 26.1571 42.6801 26.1313 47.7515C26.1273 48.3684 26.0539 49.0394 25.8058 49.5902C25.3137 50.6818 24.0098 51.2106 22.8766 50.9222C21.6562 50.6117 20.8822 49.6543 20.8683 48.2803C20.8365 45.0776 20.8484 41.8729 20.8465 38.6702C20.8445 36.6011 20.8544 34.5321 20.8504 32.463C20.8484 32.1405 20.8068 31.8161 20.777 31.3854Z" fill={color} />
                                    </svg>}
                                    value={"texto"}
                                    text={'TEXTO'}
                                    checked={tipo === 'texto'}
                                />
                            </Col>
                        </Row>
                    </Radio.Group>
                    <button onClick={accept} type="primary" className="continue">
                        {buttonType}
                    </button>
                </div>
                <div>
                    <Form
                        onFinish={onFinish}
                        initialValues={form}
                        ref={formQuestion}
                    >
                        <Form.Item
                            name="_id"
                            noStyle={true}
                        >
                            <Input type="hidden" />
                        </Form.Item>
                        <Title level={3} className="modal-title">
                            <Button className="back-button" type="text"><ArrowLeftOutlined style={{ fontSize: '1.5em' }} /></Button>Crear Pregunta</Title>

                        <Paragraph className="label-question">Nombre de Pregunta</Paragraph>
                        <Form.Item
                            name="pregunta"
                            rules={[{
                                required: true,
                                message: "El nombre de la pregunta es requerido."
                            }]}
                        >
                            <Input className="form-input" />
                        </Form.Item>
                        <Paragraph className="label-question">Pregunta</Paragraph>
                        <Form.Item
                            name="nombre"
                            rules={[{
                                required: true,
                                message: "El nombre es requerido."
                            }]}
                        >
                            <Input.TextArea className="form-input" />
                        </Form.Item>

                        <Text className="label-question">Género</Text>
                        <Tooltip title="Más Información">
                            <Typography.Link href="#API"> <QuestionCircleOutlined /></Typography.Link>
                        </Tooltip>
                        <Form.Item
                            name="genero"
                            rules={
                                [{
                                    required: true,
                                    message: "Selecione un Género",
                                }]}
                        >
                            <Radio.Group className="roles-users-group ml-1 radio-btns" onChange={this.onChangeGenero} defaultValue={this.state.genero}>
                                <Radio value={0}>Masculino</Radio>
                                <Radio value={1}>Femenino</Radio>
                                <Radio value={2}>Ambos</Radio>
                                <Radio value={3}>No Aplica</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Text className="label-question">Condicional</Text>
                        <Tooltip title="Más Información">
                            <Typography.Link href="#API"> <QuestionCircleOutlined /></Typography.Link>
                        </Tooltip>
                        <Form.Item name="activada">
                            <Switch checked={this.state.condicional} onChange={this.onChangeCondicional} >Activar</Switch>
                        </Form.Item>
                        {this.renderCondicional(this.state.condicional)}
                        {/* RESPUESTAS DE LA PREGUNTA */}
                        <Paragraph className="label-question">Posibles Respuestas</Paragraph>
                        <Form.List name="respuestas">
                            {(fields, { add, remove }) => {
                                return (
                                    <div>
                                        {fields.map(field => (
                                            <div className="space-content" key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="start">
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, '_id']}
                                                    fieldKey={[field.fieldKey, '_id']}
                                                    noStyle
                                                >
                                                    <Input type="hidden" />
                                                </Form.Item>

                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'descripcion']}
                                                    fieldKey={[field.fieldKey, 'descripcion']}
                                                    rules={[{ required: true, message: 'Ingresa la posible respuesta' }]}
                                                    className="input-answer"
                                                >
                                                    <Input placeholder="Respuesta" />
                                                </Form.Item>
                                                {(field.key > 1) ? <MinusCircleOutlined
                                                    className="input-answer-delete"
                                                    onClick={() => {
                                                        remove(field.name);
                                                    }}
                                                /> : null}
                                            </div>
                                        ))}

                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    add();
                                                }}
                                                block
                                            >
                                                <PlusOutlined /> Add field
                                                </Button>
                                        </Form.Item>
                                    </div>
                                );
                            }}
                        </Form.List>

                        <Form.Item>
                            <Button htmlType="submit" type="primary" className="continue">
                                Guardar
                                </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Carousel>
        )
    }
}

/**
 * @prop visible
 * @description Si el modal es visible o no
 *
 * @prop hideModal
 * @description Funcion para ocultar el modal. OBLIGATORIA
 *
 * @prop link
 * @description Si el boton de continuar es un link, debe de ser TRUE, si es un boton con una funcion, falso.
 *
 * @prop linkTo
 * @description Direccion a donde debe de ir elemento link. Por lo general, es encuesta nueva
 *
 * @prop accept
 * @description Si el boton de "Aceptar" no es un link, entonces debe tener alguna funcionalidad, este metodo es la funcionlaidad del boton de aceptar.
 * */
ModalPregunta.propTypes = {
    visible: PropTypes.bool,
    hideModal: PropTypes.func,
    link: PropTypes.bool,
    linkTo: PropTypes.string,

    accept: PropTypes.func,
    pregunta: PropTypes.object,
    onFinish: PropTypes.func
};

/*Valores por defecto de las propiedades (en caso de que no esten declaradas)*/
ModalPregunta.defaultProps = {
    link: false,
    linkTo: "/admin/encuestas/",
    accept: () => { },
    onFinish: () => { },
    pregunta: null,

}
